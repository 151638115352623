@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

:root {
  /* --tg-body-font-family: 'Inter', sans-serif;
  --tg-heading-font-family: 'Outfit', sans-serif;
   */
  --tg-body-font-family: 'PT Sans', sans-serif;
  --tg-heading-font-family: 'PT Sans', sans-serif;

  --tg-icon-font-family: "Font Awesome 5 Free";
  /* --tg-body-font-size: 18px;
  --tg-body-line-height: 1.5;
  --tg-heading-line-height: 1.2;
  --tg-body-color: #221f1f;
  --tg-heading-color: #000;
  --tg-theme-primary: #ffca08;
  --tg-theme-secondary: #191D88;
  --tg-color-dark-blue: #093f89;
  --tg-color-yellow-light: #FEF6E6;
  --tg-color-yellow-light-2: #FFFBF3;
  --tg-color-yellow-light-3: #F5EACD;
  --tg-color-yellow-light-4: #FDE3B0;
  --tg-color-white-default: #ffffff;
  --tg-color-gray-1: #E6EEFF;
  --tg-color-gray-2: #B8B9DA;
  --tg-color-gray-3: #ECF6FA;
  --tg-color-gray-4: #818298;
  --tg-color-gray-5: #E2E3F1;
  --tg-color-gray-6: #F7F7F8;
  --tg-color-dark: #1C1A4A;
  --tg-color-black-1: #06042E;
  --tg-color-black-2: #161439;
  --tg-border-1: #CFDDE2;
  --tg-border-2: #E6E7F3;
  --tg-border-3: #C5C6E4;
  --tg-border-4: #DBE3EB;
  --tg-border-5: #9597C8;
  --tg-border-6: #DADBEC;
  --tg-border-7: #E9E9F0;
  --tg-border-8: #E08B00;
  --tg-border-9: #D0D6E1; */
  --tg-fw-extra-bold: 800;
  --tg-fw-bold: 700;
  --tg-fw-semi-bold: 600;
  --tg-fw-medium: 500;
  --tg-fw-regular: 400;
  --tg-fw-light: 300;
}


:root {
  --global-font-size: 16px;
}

/* Apply font size globally */
body {
  font-size: var(--global-font-size) !important;
}

/* Ensure all elements inherit the global font size */
*, *::before, *::after {
  font-size: var(--global-font-size) !important;
}

/* Ensure headers also adapt */
h1, h2, h3, h4, h5, h6, p, span, button {
  font-size: var(--global-font-size) !important;
}

.bannerSUBTITLE {
  font-size: 1.25rem !important;
}
.mainH2TITLE {
  font-size: 3.75rem !important;
}

/*=============================
	Typography css start
===============================*/
body {
  font-family: var(--tg-body-font-family);
  font-size: var(--tg-body-font-size);
  font-weight: var(--tg-fw-regular);
  /* color: var(--tg-body-color); */
  line-height: var(--tg-body-line-height);
}

img,
.img {
  max-width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a,
button {
  /* color: var(--tg-theme-primary); */
  outline: none;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.btn:focus,
.button:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

a:hover,
button:hover {
  /* color: var(--tg-theme-primary); */
  text-decoration: none;
}

.product_front:hover{
    color:#e2f1ff;
}

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tg-heading-font-family);
  /* color: var(--tg-heading-color); */
  margin-top: 0px;
  font-weight: var(--tg-fw-bold);
  line-height: var(--tg-heading-line-height);
  text-transform: unset;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.list-wrap {
  margin: 0px;
  padding: 0px;
  font-size: 13px;
}
.list-wrap li {
  list-style: none;
}

p {
  font-family: var(--tg-body-font-family);
  font-size: var(--tg-body-font-size);
  line-height: var(--tg-body-line-height);
  font-weight: var(--tg-fw-regular);
  /* color: var(--tg-body-color); */
  margin-bottom: 15px;
}

hr {
  border-bottom: 1px solid #aeb3bf;
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}

label {
  /* color: var(--tg-heading-color); */
  cursor: pointer;
  font-size: var(--tg-body-font-size);
  font-weight: var(--tg-fw-regular);
}

input[type=color] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
  text-shadow: none;
}

::-moz-selection {
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
  text-shadow: none;
}

::selection {
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
  text-shadow: none;
}

/*=============================
    - Input Placeholder
===============================*/
input,
textarea {
  color: var(--tg-body-color);
}

*::-moz-placeholder {
  color: var(--tg-body-color);
  font-size: var(--tg-body-font-size);
  opacity: 1;
}

*::placeholder {
  color: var(--tg-body-color);
  font-size: var(--tg-body-font-size);
  opacity: 1;
}

/*=============================
    - Common Classes
===============================*/
.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

/*=============================
    - Bootstrap Custom
=============================*/
.container {
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  --bs-gutter-x: 30px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

.gx-0 {
  --bs-gutter-x: 0;
}

.gutter-24 {
  --bs-gutter-x: 24px;
}

.container {
  max-width: 1250px;
}
@media (max-width: 1500px) {
  .container {
    max-width: 1250px;
  }
}
@media (max-width: 1199.98px) {
  .container {
    max-width: 960px;
  }
}
@media (max-width: 991.98px) {
  .container {
    max-width: 720px;
  }
}
@media (max-width: 767.98px) {
  .container {
    max-width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 540px;
  }
}

.custom-container {
  max-width: 1760px;
}
@media (max-width: 1800px) {
  .custom-container {
    max-width: 1680px;
  }
}
@media (max-width: 1500px) {
  .custom-container {
    max-width: 100%;
  }
}
@media (max-width: 1199.98px) {
  .custom-container {
    max-width: 960px;
  }
}
@media (max-width: 991.98px) {
  .custom-container {
    max-width: 720px;
  }
}
@media (max-width: 767.98px) {
  .custom-container {
    max-width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-container {
    max-width: 540px;
  }
}

.custom-container-two {
  max-width: 1740px;
}
@media (max-width: 1800px) {
  .custom-container-two {
    max-width: 1540px;
  }
}
@media (max-width: 1500px) {
  .custom-container-two {
    max-width: 100%;
  }
}
@media (max-width: 1199.98px) {
  .custom-container-two {
    max-width: 960px;
  }
}
@media (max-width: 991.98px) {
  .custom-container-two {
    max-width: 720px;
  }
}
@media (max-width: 767.98px) {
  .custom-container-two {
    max-width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-container-two {
    max-width: 540px;
  }
}

.include-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/*=============================
	1. Button style
===============================*/
.tg-button-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.btn {
  user-select: none;
  -moz-user-select: none;
  background: #115227 none repeat scroll 0 0;
  border: medium none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  color: var(--tg-color-white-default);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  /*font-weight: 700;*/
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 0;
  padding: 10px 13px;
  text-align: center;
  /*text-transform: uppercase;*/
  touch-action: manipulation;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.btn::before {
  /*content: "";*/
  position: absolute;
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  width: 200%;
  height: 200%;
  top: 110%;
  left: 50%;
  background: var(--tg-theme-secondary);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  z-index: -1;
}
.btn::after {
  /*content: "\f10a";
  font-family: flaticon;*/
  font-weight: var(--tg-fw-bold);
  margin-left: 8px;
  font-size: 20px;
  line-height: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.btn:hover, .btn:focus-visible {
  color: var(--tg-color-white-default);
  background: #206cbf;
}
.btn:hover:before, .btn:focus-visible:before {
  top: -40%;
}
.btn-two {
  background: #00b9f1;
  font-size: 16px;
}
.btn-two:hover {
  background: var(--tg-theme-secondary);
}
.btn-two::before {
  background: var(--tg-theme-primary);
}
.btn.btn-login-social {
  background-color: var(--tg-color-white-default);
  width: 100%;
  color: var(--tg-color-body-color);
  justify-content: center;
  font-size: 16px;
  line-height: 28px;
  text-transform: capitalize;
}
.btn.btn-login-social img {
  margin-right: 15px;
}
.btn.btn-login-social:after {
  display: none;
}
.btn.btn-login-social:hover {
  color: var(--tg-color-white-default);
}
.btn.btn-login {
  width: 100%;
  border-radius: 11px;
  padding: 20px 26px;
  justify-content: center;
  font-size: 18px;
  line-height: 28px;
}
.btn.btn-login:hover {
  background-color: var(--tg-theme-secondary);
}
.btn.border-yellow-btn {
  border: 1px solid var(--tg-theme-primary);
  background: transparent;
  color: var(--tg-theme-primary);
  padding: 17px 24px;
}
.btn.border-yellow-btn::before {
  background-color: var(--tg-theme-primary);
}
.btn.border-yellow-btn:hover {
  color: var(--tg-color-white-default);
}

.border-btn {
  border: 1px solid var(--tg-theme-secondary);
  background: transparent;
  color: var(--tg-theme-secondary);
  padding: 17px 24px;
}
.border-btn::before {
  display: none;
}
.border-btn:hover {
  background: var(--tg-theme-secondary);
}

/*=============================
    00. Preloader
===============================*/
#preloader {
  background-color: var(--tg-color-white-default);
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  z-index: 9999;
}
#preloader .loader .loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border: 3px solid var(--tg-color-gray-1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
#preloader .loader .loader-container::before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 65px;
  height: 65px;
  border-top: 3px solid var(--tg-theme-primary);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  animation: loaderspin 1.8s infinite ease-in-out;
  -webkit-animation: loaderspin 1.8s infinite ease-in-out;
}
#preloader .loader .loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80px;
  text-align: center;
}
#preloader .loader .loader-icon img {
  animation: loaderpulse alternate 900ms infinite;
  width: 40px;
}




@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes loaderpulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
/*=============================
    - Background color
===============================*/
.grey-bg {
  background: var(--tg-color-gray-1);
}

.white-bg {
  background: var(--tg-color-white-default);
}

.black-bg {
  background: var(--tg-color-black-1);
}

/*=============================
	04. OffCanvas
===============================*/
.offCanvas__info {
  background: var(--tg-color-white-default) none repeat scroll 0 0;
  height: 100%;
  padding: 30px;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  width: 340px;
  z-index: 999;
  overflow-y: scroll;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}
.offCanvas__info.active {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.offCanvas__info::-webkit-scrollbar {
  width: 0px;
}
.offCanvas__close-icon {
  margin-top: -16px;
  text-align: right;
}
.offCanvas__close-icon button {
  background: transparent;
  border: 0 none;
  color: var(--tg-theme-primary);
  cursor: pointer;
  font-size: 20px;
  padding: 0;
}
.offCanvas__logo img {
  max-height: 34px;
}
.offCanvas__side-info {
  border-top: 1px solid var(--tg-theme-primary);
  padding-top: 25px;
}
.offCanvas__side-info .contact-list h4 {
  color: var(--tg-heading-color);
  font-weight: 700;
  font-size: 18px;
}
.offCanvas__side-info .contact-list p {
  color: var(--tg-body-color);
  margin: 0;
  margin-bottom: 2px;
  line-height: 26px;
}
.offCanvas__social-icon a {
  color: var(--tg-theme-primary);
  display: inline-block;
  margin-right: 20px;
  text-align: center;
}
.offCanvas__social-icon a:hover {
  color: var(--tg-theme-secondary);
}
.offCanvas__overly {
  position: fixed;
  background: #000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.offCanvas__overly.active {
  opacity: 0.7;
  visibility: visible;
}

/*=============================
	04. Search
===============================*/
.search__popup {
  padding-top: 70px;
  padding-bottom: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--tg-color-white-default);
  backdrop-filter: blur(10px);
  z-index: 99;
  -webkit-transform: translateY(calc(-100% - 80px));
  -moz-transform: translateY(calc(-100% - 80px));
  -ms-transform: translateY(calc(-100% - 80px));
  -o-transform: translateY(calc(-100% - 80px));
  transform: translateY(calc(-100% - 80px));
  -webkit-transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition-delay: 0.5s;
}
.search__popup.search-opened {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
  transition-delay: 0s;
  z-index: 99999999;
}
.search__popup.search-opened .search__input {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.3s;
}
.search__popup.search-opened .search__input::after {
  width: 100%;
  transition-delay: 0.5s;
}
.search__input {
  position: relative;
  height: 80px;
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  transition-delay: 0.5s;
  opacity: 0;
}
.search__input::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 0%;
  height: 1px;
  background-color: var(--tg-theme-primary);
  transition-delay: 0.3s;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.search__input input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-size: 24px;
  color: var(--tg-heading-color);
  border-bottom: 1px solid transparent;
  padding: 0;
  padding-right: 30px;
}
.search__input input::placeholder {
  font-size: 24px;
}
.search__input button {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 18px;
  color: var(--tg-theme-primary);
  border: none;
  padding: 0 0;
  background: transparent;
}
.search__close {
  position: absolute;
  top: 10%;
  right: 2%;
  z-index: 2;
}
.search-close-btn {
  margin: 0;
  padding: 0;
  border: none;
  color: var(--tg-theme-primary);
  cursor: pointer;
  background: transparent;
}
.search-popup-overlay {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  transition-delay: 0.5s;
  opacity: 0;
  visibility: hidden;
  background: #000d25;
}
.search-popup-overlay.search-popup-overlay-open {
  opacity: 0.55;
  visibility: visible;
  transition-delay: 0s;
}

.search-input-field ~ .search-focus-border {
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  width: 0;
  height: 1px;
  background-color: var(--tg-theme-primary);
  transition: all 0.5s;
}
.search-input-field:focus ~ .search-focus-border {
  width: 100%;
  left: 0;
  right: auto;
  transition: all 0.5s;
}

/*=========================
04. Breadcrumb
===========================*/
.breadcrumb__bg {
  background-size: cover;
  background-position: center;
  padding: 105px 0 110px;
  position: relative;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .breadcrumb__bg {
    padding: 85px 0 90px;
  }
}

@media (max-width: 991.98px) {
  .breadcrumb__content .title {
    font-size: 42px !important;
  }
}
@media (max-width: 767.98px) {
  .breadcrumb__content .title {
    font-size: 40px  !important;
  }
}
.breadcrumb__content .breadcrumb {
  margin-bottom: 0;
}
.breadcrumb__content .breadcrumb-item {
  font-size: 16px;
  color: var(--tg-theme-primary);
}
.breadcrumb__content .breadcrumb-item a {
  color: var(--tg-body-color);
}
.breadcrumb__content .breadcrumb-item a:hover {
  color: var(--tg-theme-primary);
}
.breadcrumb__shape img {
  position: absolute;
  z-index: -1;
}
.breadcrumb__shape img:nth-child(1) {
  left: 2%;
  top: 14%;
}
@media (max-width: 767.98px) {
  .breadcrumb__shape img:nth-child(1) {
    left: 10%;
  }
}
.breadcrumb__shape img:nth-child(2) {
  left: 8%;
  bottom: 20%;
  animation: rightToLeft 4s infinite linear;
}
@media (max-width: 767.98px) {
  .breadcrumb__shape img:nth-child(2) {
    left: 40%;
  }
}
.breadcrumb__shape img:nth-child(3) {
  left: 23%;
  bottom: 0;
}
.breadcrumb__shape img:nth-child(4) {
  right: 42%;
  top: 0;
}
@media (max-width: 767.98px) {
  .breadcrumb__shape img:nth-child(4) {
    display: none;
  }
}
.breadcrumb__shape img:nth-child(5) {
  right: 25%;
  top: 15%;
}
@media (max-width: 767.98px) {
  .breadcrumb__shape img:nth-child(5) {
    display: none;
  }
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 10px;
}
.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 10px;
  color: var(--tg-body-color);
  content: "\f054";
  font-family: var(--tg-icon-font-family);
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  margin-top: 6px;
}

/*=============================
    00. Scroll Top
===============================*/
.scroll__top {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: fixed;
  bottom: -10%;
  right: 50px;
  font-size: 16px;
  border-radius: 4px;
  z-index: 5;
  color: var(--tg-color-white-default);
  text-align: center;
  cursor: pointer;
  background: #115227;
  transition: 1s ease;
  border: none;
}
@media (max-width: 1199.98px) {
  .scroll__top {
    right: 25px;
    bottom: 25px;
  }
}
@media (max-width: 991.98px) {
  .scroll__top {
    right: 30px;
  }
}
@media (max-width: 767.98px) {
  .scroll__top {
    right: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }
}
.scroll__top.open {
  bottom: 30px;
}
@media (max-width: 767.98px) {
  .scroll__top.open {
    bottom: 15px;
  }
}
.scroll__top::after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}
.scroll__top:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
}

.width_img_com {
  width: 60%;
}


/* Make the swiper container smaller */
.team-swiper {
  width: 80%; /* Adjust the width to your preference */
  margin: 0 auto; /* Centers the swiper in the container */
}

/* Optional: Adjust the slides inside the swiper */
.swiper-slide {
  /*display: flex;*/
  justify-content: center;
  align-items: center;
}

/* Optional: Adjust the content inside each slide */
.team__item-three {
  width: 100%;
  max-width: 250px; /* Optional: Limit the width of each item in the swiper */
  margin: 0 auto;
}


/*=============================
	00. Section Title
===============================*/
.section-title .sub-title {
  display: block;
  text-transform: capitalize;
  font-size: 35px;
  font-weight: 600;
  line-height: normal;
  color: #153859;
  margin-bottom: 12px;
}
.section-title .title {
  margin-bottom: 0;
  font-size: 40px;
}
@media (max-width: 1199.98px) {
  .section-title .title {
    font-size: 36px;
  }
}
@media (max-width: 767.98px) {
  .section-title .title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title .title {
    font-size: 34px;
  }
}
@media (max-width: 991.98px) {
  .section-title .title br {
    display: none;
  }
}
.section-title p {
  margin-bottom: 0;
  margin-top: 20px;
}
@media (max-width: 767.98px) {
  .section-title p br {
    display: none;
  }
}
.section-title.white-title .title {
  color: #040404;
}

/*=============================
	02. Header
===============================*/
.transparent-header {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 9;
  height: auto;
}

.tg-header__top {
  background: var(--tg-color-dark-blue);
  padding: 12px 0;
}
@media (max-width: 767.98px) {
  .tg-header__top {
    display: none;
  }
}
.tg-header__top-info {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 25px;
}
@media (max-width: 1199.98px) {
  .tg-header__top-info {
    gap: 15px;
  }
}
@media (max-width: 991.98px) {
  .tg-header__top-info {
    justify-content: center;
    margin-bottom: 10px;
  }
}
.tg-header__top-info li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--tg-color-gray-1);
  font-size: 14px;
  font-weight: 500;
}
.tg-header__top-info li i {
  color: var(--tg-color-gray-2);
  font-size: 18px;
  line-height: 0;
}
.tg-header__top-info li a {
  color: var(--tg-color-gray-1);
}
.tg-header__top-info li a:hover {
  color: var(--tg-theme-primary);
}
.tg-header__top-info-three li {
  color: var(--tg-body-color);
}
.tg-header__top-info-three li i {
  color: var(--tg-color-gray-4);
}
.tg-header__top-info-three li a {
  color: var(--tg-body-color);
}
.tg-header__top-info-three li a:hover {
  color: var(--tg-theme-primary);
}
.tg-header__top-right {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
}
@media (max-width: 1199.98px) {
  .tg-header__top-right {
    gap: 15px;
  }
}
@media (max-width: 991.98px) {
  .tg-header__top-right {
    justify-content: center;
  }
}
.tg-header__top-right li a{
      font-size: 14px !important;
    font-style: italic;
}
.tg-header__top-right li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--tg-color-gray-1);
      font-size: 14px !important;
    font-style: italic;
  font-weight: 500;
  white-space: nowrap;
}
.tg-header__top-right li i {
  color: var(--tg-color-gray-2);
  font-size: 18px;
  line-height: 0;
}
.tg-header__top-right li a {
  color: var(--tg-color-gray-1);
}
.tg-header__top-right li a:hover {
  color: var(--tg-theme-primary);
}
.tg-header__top-right-three li {
  color: var(--tg-body-color);
}
.tg-header__top-right-three li i {
  color: var(--tg-color-gray-4);
}
.tg-header__top-right-three li a {
  color: var(--tg-body-color);
}
.tg-header__top-right-three li a:hover {
  color: var(--tg-theme-primary);
}
.tg-header__top-two {
  padding: 25px 15px 22px 15px;
  background: transparent;
}
@media (max-width: 1199.98px) {
  .tg-header__top-two {
    padding: 15px 20px;
  }
}
.tg-header__top-two .list-wrap li {
  color: var(--tg-color-dark-blue);
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}
.tg-header__top-two .list-wrap li i {
  color: #01b3f2;
  font-size: 15px;
  line-height: 0;
}
@media (max-width: 1199.98px) {
  .tg-header__top-two .list-wrap li i {
    font-size: 18px;
  }
}
.tg-header__top-two .list-wrap li a {
  color: var(--tg-color-dark-blue);
}
.tg-header__top-two .list-wrap li a:hover {
  color: var(--tg-theme-primary);
}
.tg-header__top-three {
  background: transparent;
  padding: 20px 0;
}
.tg-header__inner-wrap {
  display: flex;
  align-items: center;
}
.tg-header__logo-wrap {
  width: 15.5%;
  flex: 0 0 auto;
  /*text-align: center;*/
  padding: 13px 0 11px 0px;
}
@media (max-width: 1199.98px) {
  .tg-header__logo-wrap {
    display: none;
  }
}
.tg-header__right-side {
  flex-grow: 1;
}

@media (max-width: 991.98px) {
  .tg-header__area {
    padding: 25px 0;
  }
}
.tg-header__area-two {
  padding: 0 30px;
}
@media (max-width: 1199.98px) {
  .tg-header__area-two {
    padding: 0 20px;
  }
}
@media (max-width: 991.98px) {
  .tg-header__area-two {
    padding: 20px 20px;
  }
}
@media (max-width: 767.98px) {
  .tg-header__area-two {
    padding: 20px 15px;
  }
}
@media (max-width: 1199.98px) {
  .tg-header__area-two .logo.d-none {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center;
  }
}
.tg-header__area-two .tgmenu__nav {
  align-items: normal;
}
@media (max-width: 991.98px) {
  .tg-header__area-two .tgmenu__nav {
    align-items: center;
  }
}
.tg-header__area-two .tgmenu__navbar-wrap ul {
  margin: 0;
}
@media (max-width: 1199.98px) {
  .tg-header__area-two .tgmenu__navbar-wrap ul {
    margin: 0 0 0 auto;
  }
}
.tg-header__area-two .tgmenu__navbar-wrap ul li a {
  padding: 15px 0px;
}
.tg-header__area-two.sticky-menu .logo.d-none {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  align-items: center;
  margin-right: 100px;
}
@media (max-width: 1199.98px) {
  .tg-header__area-two.sticky-menu .logo.d-none {
    margin-right: 0;
  }
}
.tg-header__area-three {
  background: transparent;
  box-shadow: none;
}
.tg-header__area-three .tgmenu__navbar-wrap ul {
  margin: 0 auto;
}
.tg-header__area-four {
  background: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
@media (max-width: 991.98px) {
  .tg-header__area-four {
    padding: 0 0;
  }
}
@media (max-width: 767.98px) {
  .tg-header__area-four {
    background: var(--tg-color-white-default);
    padding: 20px 0;
    -webkit-box-shadow: 0px 5px 10px 0px #eef4f6;
    -moz-box-shadow: 0px 5px 10px 0px #eef4f6;
    -ms-box-shadow: 0px 5px 10px 0px #eef4f6;
    -o-box-shadow: 0px 5px 10px 0px #eef4f6;
    box-shadow: 0px 5px 10px 0px #eef4f6;
  }
}
.tg-header__area-four.sticky-menu .tgmenu__wrap {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.tg-header__area-four .tgmenu__wrap {
  background: var(--tg-color-white-default);
  -webkit-box-shadow: 0px 5px 10px 0px #eef4f6;
  -moz-box-shadow: 0px 5px 10px 0px #eef4f6;
  -ms-box-shadow: 0px 5px 10px 0px #eef4f6;
  -o-box-shadow: 0px 5px 10px 0px #eef4f6;
  box-shadow: 0px 5px 10px 0px #eef4f6;
  border: 1px solid #d7e4ea;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 0 20px;
}
@media (max-width: 991.98px) {
  .tg-header__area-four .tgmenu__wrap {
    padding: 15px 20px;
  }
}
@media (max-width: 767.98px) {
  .tg-header__area-four .tgmenu__wrap {
    padding: 0;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
}
.tg-header__area-four .tgmenu__navbar-wrap ul {
  margin: 0 auto;
}
.tg-header__area-four .tgmenu__navbar-wrap ul li a {
  padding: 26px 0px;
}
.tg-header__area-five {
  background: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.tg-header__area-five .tgmenu__navbar-wrap ul {
  margin: 0 auto;
}
.tg-header__area .mobile-nav-toggler {
  position: relative;
  float: right;
  cursor: pointer;
  line-height: 1;
  color: var(--tg-theme-secondary);
  display: none;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  background: var(--tg-color-white-default);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
@media (max-width: 991.98px) {
  .tg-header__area .mobile-nav-toggler {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}
.tg-header__area .mobile-nav-toggler svg {
  width: 18px;
}

.tgmenu__nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@media (max-width: 991.98px) {
  .tgmenu__nav {
    justify-content: space-between;
  }
}
.tgmenu__nav .logo img {
  max-height: 50px;
}
.tgmenu__navbar-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-grow: 1;
}
.tgmenu__navbar-wrap > ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 0;
  margin: 0 0 0 125px;
}
@media (max-width: 1199.98px) {
  .tgmenu__navbar-wrap > ul {
    margin: 0 0 0 100px;
  }
}
.tgmenu__navbar-wrap > ul > li {
  list-style: none;
  display: block;
  position: relative;
  margin-right: 30px;
}
.tgmenu__navbar-wrap > ul > li:last-child {
  margin-right: 0;
  padding-right: 0px !important;
}
.tgmenu__navbar-wrap > ul > li a {
  /* font-size: 16px !important; */
  font-weight: 500;
  text-transform: capitalize;
  color: #093f89;
  padding: 42px 0px;
  display: block;
  line-height: 1;
  position: relative;
  z-index: 1;
  /*font-style:italic;*/
}
.tgmenu__navbar-wrap > ul > li .sub-menu {
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 230px;
  border: 1px solid #f5f5f5;
  background: #fff;
  margin: 0;
  -webkit-transform: scale(1, 0);
  -moz-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  -o-transform: scale(1, 0);
  transform: scale(1, 0);
  transform-origin: 0 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
  -moz-box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
  box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  padding: 18px 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}
.tgmenu__navbar-wrap > ul > li .sub-menu .sub-menu {
  right: auto;
  left: 100%;
  top: 0;
}
.tgmenu__navbar-wrap > ul > li .sub-menu > li {
  margin-left: 0;
  text-align: left;
  display: block;
}
.tgmenu__navbar-wrap > ul > li .sub-menu > li a {
  padding: 8px 15px 8px 25px;
  line-height: 1.4;
  display: block;
  color: var(--tg-color-dark-blue);
  text-transform: capitalize;
}
.tgmenu__navbar-wrap > ul > li .sub-menu > li:hover > a, .tgmenu__navbar-wrap > ul > li .sub-menu > li.active > a {
  color:#feca08;
}
.tgmenu__navbar-wrap > ul > li .sub-menu > li:hover > a {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  -ms-transform: translateX(8px);
  -o-transform: translateX(8px);
  transform: translateX(8px);
}
.tgmenu__navbar-wrap > ul > li .sub-menu > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.tgmenu__navbar-wrap > ul > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.tgmenu__navbar-wrap > ul > li.active a, .tgmenu__navbar-wrap > ul > li:hover a {
  color: #feca08;
}
.tgmenu__main-menu li.menu-item-has-children .dropdown-btn {
  display: none;
}
.tgmenu__main-menu li.menu-item-has-children > a::after {
  font-family: FontAwesome;
  content: "\f078";
  /*font-weight: 700;*/
  color: var(--tg-color-dark-blue);
  margin-left: 8px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tgmenu__main-menu li.menu-item-has-children > a:hover {
  color: #feca08;
}
.tgmenu__main-menu li.menu-item-has-children:hover > a::after {
  color: #feca08;
}
.tgmenu__main-menu li.menu-item-has-children.active > a::after {
  color: #feca08;
}
@media (max-width: 991.98px) {
  .tgmenu__action {
    margin-right: 40px;
    margin-left: auto;
  }
}
.tgmenu__action ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.tgmenu__action ul li {
  position: relative;
  margin-left: 30px;
}
.tgmenu__action ul li a {
  color: var(--tg-theme-secondary);
  font-size: 14px;
}
.tgmenu__action ul li:first-child {
  margin-left: 0;
}
.tgmenu__action ul .header-search a {
  font-size: 20px;
  line-height: 0;
  color: var(--tg-theme-secondary);
}
.tgmenu__action ul .header-search a svg {
  width: 12px;
}
.tgmenu__action ul .header-search a:hover {
  color: var(--tg-theme-primary);
}
@media (max-width: 991.98px) {
  .tgmenu__action ul .offCanvas-menu {
    display: none;
  }
}
.tgmenu__action ul .offCanvas-menu .menu-tigger {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-color-white-default);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  font-size: 18px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
}
.tgmenu__action ul .offCanvas-menu .menu-tigger svg {
  width: 10px;
  height: 18px;
}
.tgmenu__action ul .offCanvas-menu .menu-tigger:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
}
@media (max-width: 1199.98px) {
  .tgmenu__action ul .header-btn {
    display: none;
  }
}
.tgmenu__action ul .header-btn .btn {
  color: var(--tg-color-white-default);
  font-size: 12px;
}
.tgmenu__action ul .header-btn .btn::after {
  display: none;
}
.tgmenu__action-two > .list-wrap {
  height: 100%;
  align-items: normal;
}
@media (max-width: 1199.98px) {
  .tgmenu__action-two > .list-wrap {
    margin-left: 40px;
  }
}
.tgmenu__action-two > .list-wrap li {
  margin-left: 0;
}
.tgmenu__action-two > .list-wrap li.header-search a {
  width: 85px;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--tg-color-gray-5);
  border-top: none;
  border-bottom: none;
}
@media (max-width: 991.98px) {
  .tgmenu__action-two > .list-wrap li.header-search a {
    width: auto;
    height: auto;
    border: none;
  }
}
.tgmenu__action-two > .list-wrap li.offCanvas-menu .menu-tigger {
  width: 85px;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  box-shadow: none;
  font-size: 24px;
  line-height: 0;
  border: 1px solid var(--tg-color-gray-5);
  border-top: none;
  border-bottom: none;
  margin-left: -1px;
}
.tgmenu__action-two > .list-wrap li.offCanvas-menu .menu-tigger:hover {
  background: transparent;
  color: var(--tg-theme-primary);
}
.tgmenu__action-two > .list-wrap li.header-btn {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
@media (max-width: 1199.98px) {
  .tgmenu__action-two > .list-wrap li.header-btn {
    display: none;
  }
}
.tgmenu__action-two > .list-wrap li.header-btn .btn {
  border-radius: 5px;
  gap: 8px;
}
.tgmenu__action-two > .list-wrap li:last-child {
  margin-left: 25px;
}
.tgmenu__action-three > .list-wrap li {
  margin-left: 20px;
}
.tgmenu__action-three > .list-wrap li.offCanvas-menu .menu-tigger {
  width: auto;
  height: auto;
  background: transparent;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  line-height: 0;
  display: block;
}
.tgmenu__action-three > .list-wrap li.offCanvas-menu .menu-tigger svg {
  width: 22px;
  height: 22px;
}
.tgmenu__action-three > .list-wrap li.offCanvas-menu .menu-tigger:hover {
  color: var(--tg-theme-primary);
}
.tgmenu__action-four .list-wrap li {
  margin-left: 20px;
}
.tgmenu__action-four .list-wrap li.header-btn .btn {
  padding: 13px 22px;
}
.tgmenu__action-four .list-wrap li.offCanvas-menu {
  margin-left: 40px;
  position: relative;
}
.tgmenu__action-four .list-wrap li.offCanvas-menu .menu-tigger {
  width: auto;
  height: auto;
  background: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.tgmenu__action-four .list-wrap li.offCanvas-menu .menu-tigger:hover {
  color: var(--tg-theme-primary);
}
.tgmenu__action-four .list-wrap li.offCanvas-menu::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 40px;
  background: #d7e4ea;
}
.tgmenu__action-five .list-wrap li {
  margin-left: 10px;
}
.tgmenu__action-five .list-wrap li.header-btn .btn {
  padding: 13px 24px;
}
.tgmenu__action-five .list-wrap li.header-btn-two .btn.border-btn {
  border-color: var(--tg-color-gray-2);
  color: var(--tg-body-color);
  padding: 12px 21px;
}
.tgmenu__action-five .list-wrap li.header-btn-two .btn.border-btn::after {
  display: none;
}
.tgmenu__action-five .list-wrap li.header-btn-two .btn.border-btn:hover {
  color: var(--tg-color-white-default);
  border-color: var(--tg-theme-secondary);
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.sticky-menu {
  -webkit-animation: fadeInDown 1000ms ease-in-out;
  animation: fadeInDown 1000ms ease-in-out;
}


.sticky-menu {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
  -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  border-radius: 0;
  padding: 20px 100px;
  z-index: 1000;
}
.sticky-menu ul li .sub-menu {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  -o-border-radius: 0 0 6px 6px;
  -ms-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}

#header-fixed-height.active-height {
  display: block;
  height: 90px;
}

.navigation li a {
  text-decoration: none;
}

/*=============================
	03. Mobile Menu
===============================*/
.tgmobile__search {
  padding: 0 20px 25px 25px;
}
.tgmobile__search form {
  position: relative;
}
.tgmobile__search input {
  display: block;
  width: 100%;
  border: none;
  padding: 10px 45px 10px 20px;
  font-size: 15px;
  height: 45px;
  background: var(--tg-color-gray-3);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.tgmobile__search input::placeholder {
  font-size: 15px;
  color: var(--tg-body-color);
}
.tgmobile__search button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  border: none;
  padding: 0;
  right: 20px;
  line-height: 1;
  background: transparent;
  color: var(--tg-heading-color);
}
.tgmobile__menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  z-index: 9999;
  border-radius: 0px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transform: translateX(101%);
  -moz-transform: translateX(101%);
  -ms-transform: translateX(101%);
  -o-transform: translateX(101%);
  transform: translateX(101%);
}
.tgmobile__menu .navbar-collapse {
  display: block !important;
}
.tgmobile__menu .nav-logo {
  position: relative;
  padding: 30px 25px;
  text-align: left;
}
.tgmobile__menu .nav-logo img {
  max-height: 34px;
}
.tgmobile__menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
  margin: 0;
  padding: 0;
}
.tgmobile__menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.tgmobile__menu .navigation li.active > a {
  color: var(--tg-theme-primary);
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn {
  position: absolute;
  right: 20px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: var(--tg-heading-color);
  background: var(--tg-color-gray-3);
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn .plus-line {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(0);
  -ms-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
  border-radius: 10px;
  width: 12px;
  height: 2px;
  background-color: var(--tg-color-black-1);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn .plus-line::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(0);
  -ms-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
  border-radius: 10px;
  width: 2px;
  height: 12px;
  background-color: var(--tg-color-black-1);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn.open {
  background-color: var(--tg-theme-primary);
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn.open .plus-line {
  background-color: var(--tg-color-white-default);
}
.tgmobile__menu .navigation li.menu-item-has-children .dropdown-btn.open .plus-line::after {
  display: none;
}
.tgmobile__menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 1.5;
  padding: 10px 60px 10px 25px;
  font-size: 16px;
  font-weight: 500;
  color: var(--tg-heading-color);
  text-transform: capitalize;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: none;
}
.tgmobile__menu .navigation li > a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  width: 2px;
  background: var(--tg-theme-primary);
  pointer-events: none;
}
.tgmobile__menu .navigation li ul li > a {
  margin-left: 20px;
}
.tgmobile__menu .navigation li ul li ul li a {
  margin-left: 40px;
}
.tgmobile__menu .navigation li ul li ul li ul li a {
  margin-left: 60px;
}
.tgmobile__menu .navigation li > ul {
  display: none;
}
.tgmobile__menu .navigation li > ul > li > ul {
  display: none;
}
.tgmobile__menu .navigation ul {
  padding: 0;
  margin: 0;
}
.tgmobile__menu .navigation ul li a {
  display: block;
}
.tgmobile__menu .navigation ul li ul li > a {
  font-size: 16px;
  margin-left: 20px;
  text-transform: capitalize;
}
.tgmobile__menu .navigation:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.tgmobile__menu .navigation > li > ul > li:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.tgmobile__menu .navigation > li.active > a::before {
  height: 100%;
}
.tgmobile__menu .close-btn {
  position: absolute;
  right: 15px;
  top: 28px;
  line-height: 30px;
  width: 35px;
  text-align: center;
  font-size: 20px;
  color: var(--tg-theme-primary);
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.tgmobile__menu .close-btn i[class^=flaticon-]:before {
  font-weight: var(--tg-fw-bold) !important;
}
.tgmobile__menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
}
.tgmobile__menu .tgmenu__action {
  padding: 0 0;
  margin: 0 0;
}
.tgmobile__menu .tgmenu__action > ul {
  margin: 0 0;
  padding: 30px 20px 0;
  justify-content: center;
  gap: 0 15px;
}
.tgmobile__menu .tgmenu__action > ul li {
  margin: 0 0;
}
.tgmobile__menu .tgmenu__action > ul .header-btn {
  display: block;
}
.tgmobile__menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--tg-color-white-default);
  padding: 0px 0px;
  z-index: 5;
  box-shadow: -9px 0 14px 0px rgba(0, 0, 0, 0.06);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}
.tgmobile__menu-bottom {
  padding: 30px 25px 25px;
  margin-top: auto;
}
.tgmobile__menu-bottom .contact-info .list-wrap li a {
  color: var(--tg-theme-secondary);
  display: inline-block;
  font-weight: 500;
  margin-bottom: 5px;
}
.tgmobile__menu-bottom .contact-info .list-wrap li a:hover {
  color: var(--tg-theme-primary);
}
.tgmobile__menu-bottom .social-links ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 0 0;
  gap: 15px;
}
.tgmobile__menu-bottom .social-links ul li {
  position: relative;
  display: inline-block;
}
.tgmobile__menu-bottom .social-links ul li a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 18px;
  color: var(--tg-theme-secondary);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.tgmobile__menu-bottom .social-links ul li a:hover {
  color: var(--tg-theme-primary);
}
.tgmobile__menu-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  display: none;
  color: var(--tg-color-white-default);
  margin-right: 30px;
  top: 15px;
}
@media (max-width: 991.98px) {
  .tgmobile__menu-outer .mobile-nav-toggler {
    display: block !important;
  }
}

.mobile-menu-visible {
  overflow: hidden;
}
.mobile-menu-visible .tgmobile__menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}
.mobile-menu-visible .tgmobile__menu-backdrop {
  opacity: 1;
  visibility: visible;
}
.mobile-menu-visible .tgmobile__menu .close-btn {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}

/*=============================
    04. Banner
===============================*/
.banner-bg {
  background-size: cover;
  background-position: center;
  min-height: 870px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  padding: 200px 0 100px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 1500px) {
  .banner-bg {
    min-height: 680px;
  }
}
@media (max-width: 767.98px) {
  .banner-bg {
    min-height: 640px;
    padding: 170px 0 80px;
  }
}
.banner__bg-two {
  min-height: 820px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  padding: 200px 0 100px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1500px) {
  .banner__bg-two {
    min-height: 660px;
  }
}
@media (max-width: 991.98px) {
  .banner__bg-two {
    min-height: 600px;
    padding: 150px 0 100px;
  }
}
@media (max-width: 767.98px) {
  .banner__bg-two {
    min-height: 580px;
    padding: 120px 0 90px;
  }
}
.banner__bg-three {
  background-size: cover;
  background-position: center;
  padding: 185px 0 0;
  min-height: 780px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 1500px) {
  .banner__bg-three {
    padding: 160px 0 0;
    min-height: 700px;
  }
}
@media (max-width: 991.98px) {
  .banner__bg-three {
    padding: 230px 0 0;
  }
}
@media (max-width: 767.98px) {
  .banner__bg-three {
    padding: 150px 0 0;
  }
}
.banner__bg-four {
  background-size: cover;
  background-position: center;
  padding: 170px 0 0;
  position: relative;
  z-index: 1;
}
@media (max-width: 1500px) {
  .banner__bg-four {
    padding: 110px 0 0;
  }
}
@media (max-width: 991.98px) {
  .banner__bg-four {
    padding: 140px 0 0;
  }
}
@media (max-width: 767.98px) {
  .banner__bg-four {
    padding: 110px 0 0;
  }
}
@media (max-width: 991.98px) {
  .banner-content {
    text-align: center;
    margin-bottom: -45px;
  }
}
@media (max-width: 767.98px) {
  .banner-content {
    margin-bottom: 0;
  }
}
.banner-content .sub-title {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  display: block;
  line-height: 1;
  color: var(--tg-theme-primary);
  margin-bottom: 15px;
}
.banner-content .title {
  font-size: 60px;
  margin-bottom: 20px;
  line-height: 1.1;
  text-transform: capitalize;
}
@media (max-width: 1199.98px) {
  .banner-content .title {
    font-size: 48px;
  }
}
@media (max-width: 767.98px) {
  .banner-content .title {
    font-size: 42px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-content .title {
    font-size: 48px;
  }
}
.banner-content p {
  margin-bottom: 30px;
  color: #383952;
  text-transform: capitalize;
  width: 90%;
}
@media (max-width: 1199.98px) {
  .banner-content p {
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .banner__content-two {
    text-align: center;
  }
}
.banner__content-two .title {
  font-size: 60px;
  text-transform: capitalize;
  line-height: 1.1;
  margin-bottom: 15px;
}
@media (max-width: 1199.98px) {
  .banner__content-two .title {
    font-size: 52px;
  }
}
@media (max-width: 767.98px) {
  .banner__content-two .title {
    font-size: 42px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner__content-two .title {
    font-size: 45px;
  }
}
.banner__content-two .title span {
  color: var(--tg-theme-primary);
}
.banner__content-two p {
  margin-bottom: 30px;
  width: 90%;
}
@media (max-width: 1199.98px) {
  .banner__content-two p {
    width: 100%;
  }
}
.banner__content-three {
  margin-top: 120px;
}
@media (max-width: 991.98px) {
  .banner__content-three {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 0;
  }
}
.banner__content-three .sub-title {
  font-size: 15px;
  color: var(--tg-theme-primary);
  font-weight: 600;
  display: block;
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 10px;
}
.banner__content-three .title {
  margin-bottom: 30px;
  font-size: 50px;
  text-transform: capitalize;
}
@media (max-width: 1199.98px) {
  .banner__content-three .title {
    font-size: 46px;
  }
}
@media (max-width: 767.98px) {
  .banner__content-three .title {
    font-size: 42px;
  }
}
.banner__content-four {
  position: relative;
}
@media (max-width: 991.98px) {
  .banner__content-four {
    text-align: center;
    margin-bottom: 50px;
  }
}
.banner__content-four .title {
  font-size: 60px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 15px;
}
@media (max-width: 1500px) {
  .banner__content-four .title {
    font-size: 56px;
  }
}
@media (max-width: 1199.98px) {
  .banner__content-four .title {
    font-size: 47px;
  }
}
@media (max-width: 767.98px) {
  .banner__content-four .title {
    font-size: 42px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner__content-four .title {
    font-size: 45px;
  }
}
.banner__content-four .title span {
  position: relative;
  display: inline-block;
}
@media (max-width: 767.98px) {
  .banner__content-four .title span {
    display: inline;
  }
}
.banner__content-four .title span::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  height: 8px;
  background: var(--tg-theme-primary);
  z-index: -1;
}
@media (max-width: 767.98px) {
  .banner__content-four .title span::before {
    display: none;
  }
}
.banner__content-four p {
  margin-bottom: 25px;
}
.banner__content-four .shape img {
  position: absolute;
  right: -6%;
  top: -6%;
}
@media (max-width: 1199.98px) {
  .banner__content-four .shape img {
    right: -8%;
    top: -15%;
  }
}
@media (max-width: 991.98px) {
  .banner__content-four .shape img {
    right: -2%;
    top: -14%;
    width: 60px;
  }
}
@media (max-width: 767.98px) {
  .banner__content-four .shape img {
    right: 3%;
    top: auto;
    bottom: 2%;
  }
}
.banner__img img {
  max-width: unset;
}
@media (max-width: 991.98px) {
  .banner__img img {
    max-width: 100%;
  }
}
.banner__img-two {
  position: relative;
}
.banner__img-two > img:nth-child(1) {
  max-width: unset;
  margin-left: 50px;
}
@media (max-width: 1199.98px) {
  .banner__img-two > img:nth-child(1) {
    margin-left: 0;
    max-width: 100%;
  }
}
.banner__img-two > img:nth-child(2) {
  position: absolute;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #D1D1D1;
  -webkit-box-shadow: 11px 10px 0px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 11px 10px 0px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 11px 10px 0px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 11px 10px 0px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 11px 10px 0px 0px rgba(0, 0, 0, 0.1);
  left: 10%;
  top: 20%;
}
@media (max-width: 1199.98px) {
  .banner__img-two > img:nth-child(2) {
    width: 200px;
    left: 2%;
  }
}
@media (max-width: 767.98px) {
  .banner__img-two > img:nth-child(2) {
    display: none;
  }
}
.banner__img-two .img__shape img {
  position: absolute;
  z-index: -1;
}
.banner__img-two .img__shape img:nth-child(1) {
  left: 37%;
  top: 10%;
  animation-duration: 4s;
}
@media (max-width: 767.98px) {
  .banner__img-two .img__shape img:nth-child(1) {
    left: 18%;
    top: 20%;
  }
}
.banner__img-two .img__shape img:nth-child(2) {
  left: 6%;
  bottom: 13%;
}
@media (max-width: 1199.98px) {
  .banner__img-two .img__shape img:nth-child(2) {
    left: 2%;
  }
}
@media (max-width: 767.98px) {
  .banner__img-two .img__shape img:nth-child(2) {
    width: 100px;
  }
}
.banner__img-two .img__shape img:nth-child(3) {
  left: 34%;
  top: -10%;
  z-index: -2;
}
@media (max-width: 767.98px) {
  .banner__img-two .img__shape img:nth-child(3) {
    display: none;
  }
}
.banner__img-two .img__shape img:nth-child(4) {
  right: -22%;
  top: 27%;
}
@media (max-width: 767.98px) {
  .banner__img-two .img__shape img:nth-child(4) {
    display: none;
  }
}
.banner-shape img {
  position: absolute;
  z-index: -1;
}
.banner-shape img:nth-child(1) {
  left: 35%;
  top: 36%;
  animation: rightToLeft 4s infinite linear;
}
@media (max-width: 1500px) {
  .banner-shape img:nth-child(1) {
    left: 32%;
    top: 31%;
  }
}
@media (max-width: 767.98px) {
  .banner-shape img:nth-child(1) {
    left: 32%;
    top: 20%;
  }
}
.banner-shape img:nth-child(2) {
  left: 6%;
  bottom: -8%;
}
.banner__shape-two img {
  position: absolute;
  z-index: -1;
  left: 5%;
  top: 12%;
  animation: heartbeat 4s infinite alternate;
}
@media (max-width: 1500px) {
  .banner__shape-two img {
    left: 1%;
    top: 4%;
  }
}
.banner-social {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  writing-mode: vertical-rl;
  gap: 45px;
  position: absolute;
  left: 3%;
  top: 39%;
}
@media (max-width: 1500px) {
  .banner-social {
    left: 0%;
    top: 35%;
  }
}
@media (max-width: 1199.98px) {
  .banner-social {
    display: none;
  }
}
.banner-social .title {
  margin-bottom: 0;
  font-size: 15px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--tg-body-color);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  position: relative;
}
.banner-social .title::before {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -31px;
  width: 2px;
  height: 20px;
  background: var(--tg-body-color);
}
.banner-social .list-wrap {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 17px;
}
.banner-social .list-wrap li a {
  color: var(--tg-heading-color);
  transform: rotate(-90deg);
  display: block;
}
.banner-social .list-wrap li a:hover {
  color: var(--tg-theme-primary);
}
.banner-social-two {
  left: auto;
  right: 2%;
  top: 36%;
}
@media (max-width: 1500px) {
  .banner-social-two {
    right: 1%;
  }
}
.banner-social-three {
  top: 37%;
}
.banner-scroll {
  position: absolute;
  right: 4%;
  bottom: 10%;
}
@media (max-width: 1500px) {
  .banner-scroll {
    right: 3%;
    bottom: 7%;
  }
}
@media (max-width: 991.98px) {
  .banner-scroll {
    display: none;
  }
}
.banner-scroll a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  flex-direction: row-reverse;
  font-size: 15px;
  font-weight: 700;
  color: var(--tg-heading-color);
  font-family: var(--tg-heading-font-family);
  gap: 10px;
}
.banner-scroll a span {
  width: 28px;
  height: 28px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--tg-heading-color);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-size: 14px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.banner-scroll a:hover {
  color: var(--tg-theme-primary);
}
.banner-scroll a:hover span {
  border-color: var(--tg-theme-primary);
}

/*=============================
    05. Slider
===============================*/
.slider__area {
  overflow: hidden;
}
.slider__single {
  position: relative;
  min-height: 670px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  /* background-color: var(--tg-color-white-default); */
  padding: 100px 0;
}
@media (max-width: 1500px) {
  .slider__single {
    min-height: 570px;
  }
}
@media (max-width: 991.98px) {
  .slider__single {
    min-height: 500px;
  }
}
.slider__single::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background: #175094;
  opacity: 0.7; */
  z-index: -1;
}
.slider__single::after {
  content: "";
  position: absolute;
  left: -10%;
  top: 0;
  width: 70%;
  height: 100%;
  /* opacity: 0.9;
  background: #192618;
  filter: blur(200px); */
  z-index: -1;
}
.slider__bg {
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  mix-blend-mode: luminosity;
  /* opacity: 0.7; */
}
.slider__content {
  position: relative;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .slider__content {
    text-align: center;
  }
}
.slider__content .sub-title {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  color: var(--tg-color-white-default);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  line-height: 1;
  padding: 7px 25px;
  margin-bottom: 15px;
}
@media (max-width: 767.98px) {
  .slider__content .sub-title {
    padding: 7px 15px;
  }
}
.slider__content .title {
  color: var(--tg-color-white-default);
  margin-bottom: 15px;
  font-size: 60px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: -0.3px;
}
@media (max-width: 1199.98px) {
  .slider__content .title {
    font-size: 45px;
  }
}
@media (max-width: 767.98px) {
  .slider__content .title {
    font-size: 42px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider__content .title {
    font-size: 45px;
  }
}
.slider__content p {
  color: var(--tg-color-white-default);
  margin-bottom: 30px;
  width: 90%;
  font-size: 18px;
}
@media (max-width: 1199.98px) {
  .slider__content p {
    width: 100%;
  }
}
.slider__shape img {
  position: absolute;
}
.slider__shape img:nth-child(1) {
  left: 0;
  top: 0;
}
@media (max-width: 991.98px) {
  .slider__shape img:nth-child(1) {
    width: 200px;
  }
}
.slider__shape img:nth-child(2) {
  right: 0;
  bottom: 0;
}

.slider__single.swiper-slide-active .slider__content .sub-title {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
}
.slider__single.swiper-slide-active .slider__content .title {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
}
.slider__single.swiper-slide-active .slider__content p {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
}
.slider__single.swiper-slide-active .slider__content .btn {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
}
.slider__single.swiper-slide-active .slider__shape img:nth-child(1) {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft2;
  animation-name: fadeInLeft2;
}
.slider__single.swiper-slide-active .slider__shape img:nth-child(2) {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
}

/*=============================
    21. Brand
===============================*/
.brand-area {
  padding: 56px 0;
  border-bottom: 1px solid #E6E7F3;
}
.brand__area-two {
  padding: 90px 0;
}
@media (max-width: 767.98px) {
  .brand__area-two {
    padding: 70px 0;
  }
}
.brand__area-four {
  border-top: 1px solid #E6E7F3;
  padding: 76px 0;
}
.brand__area-five {
  background: var(--tg-color-gray-3);
  padding: 90px 0;
}
.brand__area-six {
  background: var(--tg-color-gray-6);
  padding: 77px 0;
}
.brand__area-seven {
  padding: 0 0 120px;
  border-bottom: none;
}
@media (max-width: 767.98px) {
  .brand__area-seven {
    padding: 0 0 100px;
  }
}
.brand__area-eight {
  background: var(--tg-heading-color);
  padding: 55px 0;
}
.brand-item {
  min-height: 46px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.brand-item img {
  cursor: pointer;
}
.brand__content {
  text-align: center;
  margin-bottom: 50px;
}
.brand__content .title {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 800;
  text-transform: capitalize;
}
@media (max-width: 767.98px) {
  .brand__content .title {
    font-size: 32px;
  }
}
.brand__content .title span {
  display: block;
  font-size: 30px;
  font-weight: 500;
  margin-top: 10px;
}
@media (max-width: 767.98px) {
  .brand__content .title span {
    font-size: 26px;
  }
}

/*=============================
    06. About
===============================*/
.about-area {
  position: relative;
}
@media (max-width: 767.98px) {
  .about-area {
    padding: 100px 0;
  }
}
.about__bg {
  background-size: cover;
  background-position: center;
  padding: 15px 0;
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .about__bg {
    padding: 25px 0;
  }
}
.about__bg-two {
  background-size: cover;
  background-position: center;
  padding: 120px 0;
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .about__bg-two {
    padding: 100px 0;
  }
}
.about__area-four {
  padding: 120px 0;
}
@media (max-width: 991.98px) {
  .about__area-four {
    padding: 100px 0;
  }
}
.about__area-five {
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .about__area-five {
    padding: 100px 0;
  }
}
.about__area-six {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .about__area-six {
    padding: 100px 0;
  }
}
.about__area-seven {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .about__area-seven {
    padding: 100px 0;
  }
}
.about__area-eight {
  position: relative;
  padding: 0 0 120px;
}
@media (max-width: 767.98px) {
  .about__area-eight {
    padding: 0 0 100px;
  }
}
.about-img-wrap {
  position: relative;
  z-index: 1;
  padding-bottom: 30px;
}
@media (max-width: 991.98px) {
  .about-img-wrap {
    margin-bottom: 50px;
  }
}
.about-img-wrap .mask-img-wrap {
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  height: 472px;
  width: 417px;
  margin: 0 auto;
}
@media (max-width: 1199.98px) {
  .about-img-wrap .mask-img-wrap {
    height: 414px;
    width: 360px;
  }
}
@media (max-width: 767.98px) {
  .about-img-wrap .mask-img-wrap {
    height: 370px;
    width: 320px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-img-wrap .mask-img-wrap {
    height: 442px;
    width: 385px;
  }
}
.about-img-wrap .mask-img-wrap img {
  max-width: unset;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about-img-wrap .shape img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}
@media (max-width: 767.98px) {
  .about-img-wrap .shape img {
    display: none;
  }
}
.about__img-wrap-two {
  text-align: center;
  position: relative;
  padding: 30px 0;
}
@media (max-width: 991.98px) {
  .about__img-wrap-two {
    margin-bottom: 50px;
  }
}
@media (max-width: 767.98px) {
  .about__img-wrap-two {
    padding: 30px 15px 15px 0;
  }
}
.about__img-wrap-two > img {
  width: 70%;
}
.about__img-wrap-two .shape img {
  position: absolute;
  z-index: -1;
  left: 8%;
  bottom: -3%;
}
@media (max-width: 1199.98px) {
  .about__img-wrap-two .shape img {
    left: -2%;
  }
}
/*.about__img-wrap-two::before {
  content: "";
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 135px;
  height: 100%;
  background: var(--tg-theme-primary);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  z-index: -1;
}*/
@media (max-width: 1199.98px) {
  .about__img-wrap-two::before {
    right: 0;
  }
}
.about__img-wrap-three {
  position: relative;
}
@media (max-width: 991.98px) {
  .about__img-wrap-three {
    margin-bottom: 50px;
  }
}
.about__img-wrap-three > img:nth-child(1) {
  -webkit-border-radius: 10px 185px 10px 10px;
  -moz-border-radius: 10px 185px 10px 10px;
  -o-border-radius: 10px 185px 10px 10px;
  -ms-border-radius: 10px 185px 10px 10px;
  border-radius: 10px 185px 10px 10px;
}
@media (max-width: 767.98px) {
  .about__img-wrap-three > img:nth-child(1) {
    -webkit-border-radius: 10px 80px 10px 10px;
    -moz-border-radius: 10px 80px 10px 10px;
    -o-border-radius: 10px 80px 10px 10px;
    -ms-border-radius: 10px 80px 10px 10px;
    border-radius: 10px 80px 10px 10px;
  }
}
.about__img-wrap-three > img:nth-child(2) {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: -10%;
  bottom: 18%;
}
@media (max-width: 767.98px) {
  .about__img-wrap-three > img:nth-child(2) {
    display: none;
  }
}
.about__img-wrap-three .shape img {
  position: absolute;
  left: -20%;
  bottom: 20%;
  z-index: -1;
}
@media (max-width: 767.98px) {
  .about__img-wrap-three .shape img {
    display: none;
  }
}
.about__img-wrap-four {
  position: relative;
  padding-bottom: 125px;
}
@media (max-width: 991.98px) {
  .about__img-wrap-four {
    margin-bottom: 50px;
  }
}
.about__img-wrap-four > img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
.about__img-wrap-four > img:nth-child(2) {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border: 10px solid var(--tg-color-white-default);
  position: absolute;
  right: 35px;
  bottom: 0;
}
@media (max-width: 1199.98px) {
  .about__img-wrap-four > img:nth-child(2) {
    right: 0;
    width: 190px;
  }
}
@media (max-width: 991.98px) {
  .about__img-wrap-four > img:nth-child(2) {
    width: 230px;
  }
}
@media (max-width: 767.98px) {
  .about__img-wrap-four > img:nth-child(2) {
    display: none;
  }
}
.about__img-wrap-four .shape img {
  position: absolute;
  right: 13%;
  z-index: -1;
  top: 31%;
}
@media (max-width: 991.98px) {
  .about__img-wrap-four .shape img {
    right: 6%;
  }
}
@media (max-width: 767.98px) {
  .about__img-wrap-four .shape img {
    display: none;
  }
}
.about__img-wrap-five {
  position: relative;
  padding-bottom: 75px;
  margin-bottom: 25px;
}
.about__img-wrap-five img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  min-height: 250px;
  object-fit: cover;
}
.about__img-wrap-six {
  position: relative;
  padding-left: 70px;
}
@media (max-width: 1199.98px) {
  .about__img-wrap-six {
    padding-left: 50px;
    padding-right: 40px;
  }
}
@media (max-width: 991.98px) {
  .about__img-wrap-six {
    margin-bottom: 50px;
  }
}
@media (max-width: 767.98px) {
  .about__img-wrap-six {
    padding: 0;
    text-align: center;
  }
}
.about__img-wrap-six img:nth-child(1) {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
}
.about__img-wrap-six img:nth-child(2) {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 6%;
  bottom: 20%;
}
@media (max-width: 1199.98px) {
  .about__img-wrap-six img:nth-child(2) {
    right: 0%;
  }
}
@media (max-width: 767.98px) {
  .about__img-wrap-six img:nth-child(2) {
    display: none;
  }
}
.about__img-wrap-six .shape img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  right: 7%;
  bottom: -10%;
}
@media (max-width: 1199.98px) {
  .about__img-wrap-six .shape img {
    right: 0%;
  }
}
.about__img-wrap-seven {
  position: relative;
  text-align: right;
}
@media (max-width: 1199.98px) {
  .about__img-wrap-seven {
    padding-left: 40px;
  }
}
@media (max-width: 991.98px) {
  .about__img-wrap-seven {
    padding-left: 0px;
    margin-bottom: 50px;
  }
}
.about__img-wrap-seven > img {
  margin-left: auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
}
.about__img-wrap-seven .shape img {
  position: absolute;
  right: -5%;
  top: -8%;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .about__img-wrap-seven .shape img {
    right: 0;
  }
}
.about-list .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin: 0 -15px;
  gap: 30px 0;
  margin-bottom: 25px;
}
@media (max-width: 1199.98px) {
  .about-list .list-wrap {
    gap: 20px 0;
    flex-wrap: wrap;
  }
}
.about-list .list-wrap li {
  padding: 0 15px;
  width: 50%;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
@media (max-width: 1199.98px) {
  .about-list .list-wrap li {
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .about-list .list-wrap li {
    width: 50%;
  }
}
@media (max-width: 767.98px) {
  .about-list .list-wrap li {
    width: 100%;
  }
}
.about-list .list-wrap li .icon {
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-color-yellow-light);
  color: var(--tg-theme-primary);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  flex: 0 0 auto;
  font-size: 40px;
  line-height: 0;
}
.about-list .list-wrap li .icon i {
  transition: 0.3s linear;
}
.about-list .list-wrap li:hover .icon i {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.about-list .list-wrap li .content .title {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}
.about-list .list-wrap li .content p {
  margin-bottom: 0;
}
.about-content > p {
  margin-bottom: 30px;
  width: 93%;
  line-height: 1.62;
}
@media (max-width: 1199.98px) {
  .about-content > p {
    width: 100%;
  }
}
.about__content-two {
  width: 90%;
  color: var(--tg-color-dark-blue);
  font-size: 18px;
}
@media (max-width: 1199.98px) {
  .about__content-two {
    width: 100%;
  }
}
.about__content-two > p {
  margin-bottom: 35px;
}
.about__content-three {
  width: 95%;
  position: relative;
}
@media (max-width: 1199.98px) {
  .about__content-three {
    width: 100%;
  }
}
.about__content-three > p {
  margin-bottom: 30px;
  width: 95%;
}
@media (max-width: 1199.98px) {
  .about__content-three > p {
    width: 100%;
  }
}
.about__content-four {
  width: 94%;
}
@media (max-width: 1199.98px) {
  .about__content-four {
    width: 100%;
  }
}
.about__content-four p {
  margin-bottom: 30px;
}
.about__content-five .section-title .title span {
  font-weight: 400;
}
.about__content-five > p {
  margin-bottom: 0;
  gap: 10px;
  line-height: 1.75;
}
.about__content-five > p span {
  font-size: 60px;
  font-weight: 700;
  color: var(--tg-heading-color);
  font-family: var(--tg-heading-font-family);
  line-height: 0.8;
}
.about__content-six > p {
  margin-bottom: 25px;
  width: 85%;
}
@media (max-width: 1199.98px) {
  .about__content-six > p {
    width: 100%;
  }
}
.about__content-seven .section-title .title span {
  position: relative;
}
.about__content-seven .section-title .title span::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 6px;
  width: 0;
  height: 8px;
  background: var(--tg-theme-primary);
  z-index: -1;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.about__content-seven .section-title .title.animated span::before {
  width: 100%;
}
.about__content-seven > p {
  margin-bottom: 30px;
  width: 90%;
}
@media (max-width: 1199.98px) {
  .about__content-seven > p {
    width: 100%;
  }
}
.about__content-inner {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 50px;
}
@media (max-width: 767.98px) {
  .about__content-inner {
    flex-wrap: wrap;
  }
}
.about__content-inner-two {
  margin-bottom: 40px;
}
@media (max-width: 1199.98px) {
  .about__content-inner-two {
    gap: 20px;
  }
}
@media (max-width: 991.98px) {
  .about__content-inner-two {
    justify-content: flex-start;
    gap: 30px;
  }
}
.about__content-inner-three {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 35px;
  align-items: center;
  margin-bottom: 25px;
  flex-wrap: wrap;
}
.about__content-inner-four {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
  margin-bottom: 30px;
}
@media (max-width: 1199.98px) {
  .about__content-inner-four {
    flex-wrap: wrap;
  }
}
.about__content-inner-five {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
  margin-bottom: 60px;
}
@media (max-width: 1199.98px) {
  .about__content-inner-five {
    flex-wrap: wrap;
  }
}
.about__list-box .list-wrap li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  /*font-weight: 500;*/
  color: #221F1F;
  font-family: var(--tg-heading-font-family);
  gap: 12px;
  margin-bottom: 10px;
}
.about__list-box .list-wrap li i {
  margin-top: 4px;
  width: 20px;
  height: 20px;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: var(--tg-theme-primary);
  color: #FFF8F8;
  line-height: 0;
  font-size: 10px;
  -webkit-transform: translateY(3px);
  -moz-transform: translateY(3px);
  -ms-transform: translateY(3px);
  -o-transform: translateY(3px);
  transform: translateY(3px);
}
.about__list-box .list-wrap li:last-child {
  margin-bottom: 0;
}
.about__list-box-two {
  border-left: 1px solid #DBE3EB;
  padding-left: 30px;
}
@media (max-width: 1199.98px) {
  .about__list-box-two {
    border: none;
    padding: 0;
  }
}
.about__list-img {
  width: 184px;
  flex: 0 0 auto;
}
.about__list-img img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
.about__list-img-two {
  width: 248px;
  flex: 0 0 auto;
}
.about__list-img-two img {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #D1D1D1;
  box-shadow: 7px 7px 0px 0px rgba(0, 0, 0, 0.1);
}
.about__list-img-three {
  position: relative;
}
.about__list-img-three img {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
}
.about__list-img-three .play-btn {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-primary);
  color: var(--tg-color-black-1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.about__list-img-three .play-btn:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
}
.about__list-img-four img {
  -webkit-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  border: 0.8px solid #DADADA;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
}
.about-bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 767.98px) {
  .about-bottom {
    gap: 30px;
    flex-wrap: wrap;
  }
}
.about-bottom .author-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
}
.about-bottom .author-wrap .thumb {
  width: 60px;
  flex: 0 0 auto;
  border: 1px solid #B8B9DA;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}
.about-bottom .author-wrap .content img {
  margin-bottom: 5px;
}
.about-bottom .author-wrap .content .title {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
}
.about-bottom .author-wrap .content .title span {
  font-size: 16px;
  font-weight: 400;
  color: #6568AF;
}
.about-bottom-two {
  border-top: 1px solid var(--tg-border-4);
  padding-top: 30px;
}
@media (max-width: 1199.98px) {
  .about-bottom-two {
    gap: 20px;
  }
}
.about__phone {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
}
.about__phone .icon {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  background: var(--tg-color-gray-3);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-size: 25px;
  color: var(--tg-theme-secondary);
}
.about__phone .content span {
  display: block;
  line-height: 1;
  margin-bottom: 10px;
}
.about__phone .content a {
  font-size: 22px;
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-theme-secondary);
  font-family: var(--tg-heading-font-family);
  line-height: 1;
}
.about__phone .content a:hover {
  color: var(--tg-theme-primary);
}
.about__award-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
  position: absolute;
  bottom: 15px;
  left: 0;
}
@media (max-width: 767.98px) {
  .about__award-box {
    bottom: 0;
  }
}
.about__award-box .icon {
  width: 90px;
  height: 90px;
  align-items: center;
  justify-content: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  background: var(--tg-color-yellow-light);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  line-height: 0;
  font-size: 45px;
  color: var(--tg-theme-primary);
}
.about__award-box .content .title {
  margin-bottom: 12px;
  font-size: 50px;
  line-height: 0.8;
  font-weight: 800;
}
.about__award-box .content p {
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.4;
}
.about__award-box-two {
  -webkit-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  background: var(--tg-color-white-default);
  border: 0.8px solid #B4B4B4;
  padding: 17px 25px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
  text-align: left;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  left: -3%;
  bottom: 33%;
}
@media (max-width: 1199.98px) {
  .about__award-box-two {
    left: -17%;
  }
}
@media (max-width: 767.98px) {
  .about__award-box-two {
    display: none;
  }
}
.about__satisfied-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
}
.about__satisfied-box .icon {
  width: 90px;
  height: 90px;
  align-items: center;
  justify-content: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  background: var(--tg-color-yellow-light);
  color: var(--tg-theme-primary);
  line-height: 0;
  font-size: 45px;
}
.about__satisfied-box .content .title {
  font-size: 40px;
  align-items: center;
  line-height: 1;
  margin-bottom: 10px;
}
@media (max-width: 1199.98px) {
  .about__satisfied-box .content .title {
    font-size: 36px;
  }
}
@media (max-width: 767.98px) {
  .about__satisfied-box .content .title {
    font-size: 32px;
  }
}
.about__satisfied-box .content p {
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.4;
}
.about-shape-wrap img {
  position: absolute;
  z-index: -1;
}
.about-shape-wrap img:nth-child(1) {
  right: 10%;
  top: 30%;
}
@media (max-width: 1500px) {
  .about-shape-wrap img:nth-child(1) {
    right: 6%;
    top: 26%;
  }
}
@media (max-width: 1199.98px) {
  .about-shape-wrap img:nth-child(1) {
    right: 10%;
    top: 4%;
  }
}
.about-shape-wrap img:nth-child(2) {
  right: 6%;
  top: 36%;
  z-index: -2;
}
@media (max-width: 1500px) {
  .about-shape-wrap img:nth-child(2) {
    right: 2%;
    top: 34%;
  }
}
@media (max-width: 1199.98px) {
  .about-shape-wrap img:nth-child(2) {
    right: 4%;
    top: 11%;
  }
}
@media (max-width: 767.98px) {
  .about-shape-wrap img:nth-child(2) {
    display: none;
  }
}
.about-left-shape img {
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
}
.about__shape-wrap-two img {
  position: absolute;
  z-index: -1;
}
.about__shape-wrap-two img:nth-child(1) {
  left: 9%;
  top: 20%;
}
@media (max-width: 1500px) {
  .about__shape-wrap-two img:nth-child(1) {
    left: 8%;
    top: 0;
  }
}
@media (max-width: 991.98px) {
  .about__shape-wrap-two img:nth-child(1) {
    left: 15%;
  }
}
.about__shape-wrap-two img:nth-child(2) {
  left: 12%;
  top: 26%;
}
@media (max-width: 1500px) {
  .about__shape-wrap-two img:nth-child(2) {
    left: 2%;
    top: 10%;
  }
}
@media (max-width: 767.98px) {
  .about__shape-wrap-two img:nth-child(2) {
    display: none;
  }
}
.about__shape-wrap-two img:nth-child(3) {
  right: 0%;
  bottom: 0%;
}
.about__shape-wrap-three img {
  position: absolute;
  z-index: -1;
  right: -10%;
  top: -5%;
}
@media (max-width: 1199.98px) {
  .about__shape-wrap-three img {
    right: -3%;
    top: -10%;
  }
}
@media (max-width: 767.98px) {
  .about__shape-wrap-three img {
    top: -6%;
  }
}
.about__shape-wrap-four img {
  position: absolute;
  z-index: -1;
}
.about__shape-wrap-four img:nth-child(1) {
  right: 0;
  bottom: 0;
}
.about__shape-wrap-four img:nth-child(2) {
  right: 10%;
  bottom: 42%;
}
@media (max-width: 1500px) {
  .about__shape-wrap-four img:nth-child(2) {
    right: 6%;
    bottom: 52%;
  }
}
@media (max-width: 1199.98px) {
  .about__shape-wrap-four img:nth-child(2) {
    right: 2%;
    bottom: 32%;
  }
}
@media (max-width: 991.98px) {
  .about__shape-wrap-four img:nth-child(2) {
    right: 8%;
    bottom: 7%;
  }
}

.experience-year {
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-secondary);
  border: 4px solid var(--tg-color-white-default);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
}
.experience-year .icon {
  width: 80px;
  height: 80px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
  line-height: 0;
  font-size: 36px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.experience-year .content .circle {
  position: absolute;
  margin-bottom: 0;
  font-size: 15px;
  text-transform: uppercase;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: var(--tg-color-white-default);
}
.experience-year .content .circle span {
  position: absolute;
  top: -64px;
  display: inline-block;
  transform-origin: 0 64px;
}

.experience__box-two {
  position: absolute;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  left: 11px;
  bottom: 9%;
  padding: 60px 25px;
}
@media (max-width: 767.98px) {
  .experience__box-two {
    display: none;
  }
}
.experience__box-three {
  display: flex;
  align-items: center;
}
.experience__box-three .title {
  background: var(--tg-color-yellow-light);
  border: 1px solid #F4E7CC;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -o-border-radius: 16px;
  -ms-border-radius: 16px;
  border-radius: 16px;
  margin-bottom: 0;
  padding: 30px 28px;
}
@media (max-width: 1199.98px) {
  .experience__box-three .title {
    padding: 25px 25px;
  }
}
.experience__box-three .title span {
  background: linear-gradient(180deg, #FFCE4F 0%, #F79400 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 90px;
  font-weight: 800;
  line-height: 0.8;
  position: relative;
  font-family: var(--tg-heading-font-family);
}
@media (max-width: 1199.98px) {
  .experience__box-three .title span {
    font-size: 60px;
  }
}
@media (max-width: 991.98px) {
  .experience__box-three .title span {
    font-size: 80px;
  }
}
.experience__box-three p {
  margin-bottom: 0;
  color: var(--tg-theme-secondary);
  text-transform: uppercase;
  font-weight: 700;
  font-family: var(--tg-heading-font-family);
  margin-left: -15px;
}
@media (max-width: 1199.98px) {
  .experience__box-three p {
    font-size: 14px;
  }
}
.experience__box-three p span {
  display: block;
}
.experience__box-four {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  background: var(--tg-color-white-default);
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -o-border-radius: 40px;
  -ms-border-radius: 40px;
  border-radius: 40px;
  align-items: center;
  gap: 10px;
  position: absolute;
  left: -15%;
  bottom: 36%;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  padding: 22px 30px;
}
@media (max-width: 1199.98px) {
  .experience__box-four {
    left: -22%;
  }
}
@media (max-width: 767.98px) {
  .experience__box-four {
    display: none;
  }
}
.experience__box-four .title {
  margin-bottom: 0;
  background: linear-gradient(180deg, #FFCE4F 0%, #F79400 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 70px;
  font-weight: 900;
  line-height: 0.8;
}
.experience__box-four p {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-theme-secondary);
}
.experience__content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 20px;
  writing-mode: vertical-lr;
  position: relative;
}
@media (max-width: 1199.98px) {
  .experience__content {
    left: 42%;
  }
}
.experience__content .title {
  font-size: 50px;
  line-height: 0.8;
  font-weight: 800;
  margin-bottom: 0;
  color: var(--tg-color-white-default);
  position: relative;
}
.experience__content .title::before {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -9px;
  width: 34px;
  height: 2px;
  background: var(--tg-theme-primary);
}
.experience__content p {
  margin-bottom: 0;
  text-align: left;
  color: var(--tg-color-white-default);
}
.experience__shape {
  position: absolute;
  left: 30%;
  top: 0;
  width: 35%;
  height: 100%;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  z-index: -1;
}
@media (max-width: 1199.98px) {
  .experience__shape {
    left: 63%;
  }
}
.experience__shape svg {
  width: 100%;
  height: 100%;
  color:#191d88;
  overflow: visible;
}

/*=============================
	07. Services
===============================*/
.services-bg {
  background-size: cover;
  background-position: center;
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .services-bg {
    padding: 100px 0;
  }
}
.services__bg-three {
  background-size: cover;
  background-position: center;
  padding: 120px 0 90px;
}
@media (max-width: 767.98px) {
  .services__bg-three {
    padding: 100px 0 70px;
  }
}
.services__bg-four {
  background-size: cover;
  background-position: center;
  padding: 120px 0 90px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .services__bg-four {
    padding: 100px 0 70px;
  }
}
.services__bg-five {
  background-size: cover;
  background-position: center;
  padding: 120px 0 90px;
}
@media (max-width: 767.98px) {
  .services__bg-five {
    padding: 100px 0 70px;
  }
}
.services__bg-six {
  background-size: cover;
  background-position: center;
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .services__bg-six {
    padding: 100px 0;
  }
}
.services__bg-seven {
  background-size: cover;
  background-position: center;
  padding: 120px 0 90px;
  position: relative;
  z-index: 1;
}

.project_div_wrap{
  background: #17414f; 
  padding-bottom: 0px !important; 
  padding-top: 15px !important; 
  border-bottom: 70px solid #fff;
  height: 685px;
}

.proj_title {
    font-size: 18px;
    margin-top: 13px;
    display: block;
    color: var(--tg-color-dark-blue);
}

.card-comment p {
    color: var(--tg-color-dark-blue);
    margin-top:8px;
}

.review_div_wrap{
  border-top: 85px solid #fff; 
  background: #e2f1ff !important;
  margin-top: 160px; 
  padding-top: 60px !important;
}

.review_wrap{
  display:flex; gap:20px
}

@media (max-width: 767.98px) {
  .services__bg-seven {
    padding: 100px 0 70px;
    border-bottom:none;
  }
  .project_div_wrap {
    height: auto;
    padding-bottom: 35px !important;
  }
  .project_div_height {
    padding-bottom: 0px !important;
    padding-top: 30px !important;
  }
  .review_div_wrap{
    border-top: none; 
    margin-top: 0px; 
    padding-top: 30px !important;
  }
  .swiper-slide{
    width: 100% !important;
  }
  .review_wrap{
    display:block;
  }
  .top_slide_mT {
    margin-top: 20px !important;
  }

}
.services__area-two {
  background: #f7f7fa;
  padding: 50px 0 45px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .services__area-two {
    padding: 30px;
  }
}
.services-item-wrap .row {
  --bs-gutter-x: 24px;
}

.services-item {
  background: var(--tg-color-white-default);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 30px;
}
.services-item:hover {
  box-shadow: 0px 4px 30px 0px #CFDEE4;
}
.services-item:hover .services-content .icon {
  background: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
}
.services__item-two {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 60px;
  text-align: center;
}

.services_icon{
  font-size: 40px;
  border-radius: 50px;
  background: #fff;
  width: 100px;
  height: 100px;
  text-align: center;
  padding-top: 30px;
  color: #00b9f1;
}
.services_icon_upper{
  margin-top: -65px;
}
.services__item-two:hover .services__icon-two {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.services__item-three {
  background: var(--tg-color-white-default);
  padding: 40px 40px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  margin-bottom: 30px;
}
@media (max-width: 1199.98px) {
  .services__item-three {
    padding: 35px 30px;
  }
}
.services__item-three:hover .services__icon-three i {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.services__item-four {
  background: var(--tg-color-white-default);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 30px 80px 30px 30px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
}
@media (max-width: 1199.98px) {
  .services__item-four {
    padding: 30px 20px 30px 20px;
    gap: 25px;
  }
}
@media (max-width: 991.98px) {
  .services__item-four {
    flex-wrap: wrap;
    padding: 30px 30px;
  }
}
@media (max-width: 767.98px) {
  .services__item-four {
    padding: 30px 25px;
  }
}
.services__item-five {
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #E9E9F0;
  background: var(--tg-color-white-default);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  padding: 30px 30px 45px;
  text-align: center;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.services__item-five:hover {
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
}
.services__item-top {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 22px;
}
.padding_bottom_110{
  padding-bottom: 120px;
}
.services__item-top .title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0;
}
@media (max-width: 1199.98px) {
  .services__item-top .title {
    font-size: 20px;
  }
}
.services__item-wrap-two .row [class*=col-]:nth-child(odd) .services__item-five {
  margin-top: -90px;
}
@media (max-width: 991.98px) {
  .services__item-wrap-two .row [class*=col-]:nth-child(odd) .services__item-five {
    margin-top: 0;
  }
}
.services-thumb {
  -webkit-border-radius: 10px 10px 0 100px;
  -moz-border-radius: 10px 10px 0 100px;
  -o-border-radius: 10px 10px 0 100px;
  -ms-border-radius: 10px 10px 0 100px;
  border-radius: 10px 10px 0 100px;
  overflow: hidden;
  position: relative;
}
.services-thumb img {
  height: 200px;
  object-fit: cover;
  width: 100%;
}
.services__thumb-four {
  width: 337px;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .services__thumb-four {
    width: 300px;
  }
}
@media (max-width: 991.98px) {
  .services__thumb-four {
    width: 100%;
  }
}
.services__thumb-four img {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
  height: 380px;
  object-fit: cover;
  width: 100%;
}
@media (max-width: 1199.98px) {
  .services__thumb-four img {
    height: 340px;
  }
}
.services__icon-two {
  font-size: 50px;
  line-height: 0;
  color: #fff;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  margin-bottom: 20px;
  text-align: center;;
  width: 95px;
  height: 95px;
  border-radius: 54px;
  background-color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.services__icon-three {
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: var(--tg-color-gray-3);
  border: 1px solid var(--tg-color-gray-2);
  flex: 0 0 auto;
  line-height: 0;
  font-size: 42px;
  color: var(--tg-theme-secondary);
  position: relative;
}
.services__icon-three i {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.services__icon-three::before {
  content: "";
  position: absolute;
  right: -18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 3px;
  height: 40px;
  background: #DCE7EC;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.services__icon-five {
  position: relative;
  z-index: 1;
  color: var(--tg-theme-primary);
  font-size: 45px;
  line-height: 0;
  display: inline-block;
  margin-bottom: 50px;
}
.services__icon-shape .shape {
  position: absolute;
  z-index: -1;
}
.services__icon-shape .shape:nth-child(1) {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.services__icon-shape .shape:nth-child(1) svg {
  color: var(--tg-color-yellow-light);
}
.services__icon-shape .shape:nth-child(2) {
  left: -2px;
  bottom: -13px;
}
.services__icon-shape .shape:nth-child(2) svg {
  color: var(--tg-theme-primary);
}
.services__icon-shape .shape:nth-child(3) {
  left: 50px;
  top: -18px;
}
.services__icon-shape .shape:nth-child(3) svg {
  color: var(--tg-theme-primary);
}
.services-content {
  padding: 30px 30px 30px;
  position: relative;
}
.services-content .icon {
  width: 70px;
  height: 70px;
  background: var(--tg-color-gray-3);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  line-height: 0;
  color: var(--tg-theme-secondary);
  border: 3px solid var(--tg-color-white-default);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: -55px;
  z-index: 2;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.services-content .title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}
.services-content .title a:hover {
  color: var(--tg-theme-primary);
}
.services-content p {
  margin-bottom: 22px;
}
.services-content .btn {
  background: var(--tg-color-gray-3);
  color: var(--tg-theme-secondary);
  padding: 16px 20px;
}
.services-content .btn:hover {
  color: var(--tg-color-white-default);
}
.services__content-two .title {
  color: #fff;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 100;
}
.services__content-two p {
  margin-bottom: 25px;
  color: var(--tg-color-gray-2);
}
.services__content-two .btn {
  background: #2E328B;
  color: #9597C8;
  padding: 16px 20px;
}
.services__content-two .btn::before {
  background: var(--tg-color-white-default);
}
.services__content-two .btn::after {
  color: var(--tg-color-white-default);
}
.services__content-two .btn:hover {
  color: var(--tg-theme-secondary);
}
.services__content-two .btn:hover::after {
  color: var(--tg-theme-secondary);
}
.services__content-three p {
  margin-bottom: 25px;
}
.services__content-three .btn {
  background: var(--tg-color-yellow-light);
  color: var(--tg-theme-primary);
}
.services__content-three .btn:hover {
  color: var(--tg-color-white-default);
}
.services__content-four .title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}
@media (max-width: 1199.98px) {
  .services__content-four .title {
    font-size: 26px;
  }
}
.services__content-four p {
  margin-bottom: 20px;
}
.services__content-four .about__list-box {
  margin-bottom: 20px;
}
.services__content-four .about__list-box li i {
  font-size: 15px;
}
.services__content-four .btn {
  background: var(--tg-color-gray-3);
  color: var(--tg-theme-secondary);
}
.services__content-four .btn:hover {
  color: var(--tg-color-white-default);
}
.services__content-five .title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--tg-color-dark-blue);
}
.adeedd p, table, ul, li,a {
    color:#093f89;
        font-size: 18px;
}
.services__content-five p {
  margin-bottom: 25px;
}
.services__content-five .btn {
  background: var(--tg-color-gray-3);
  color: var(--tg-theme-secondary);
  padding: 16px 24px;
}
.services__content-five .btn:hover {
  color: #00b8f1;
}
.services-bottom-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}
@media (max-width: 767.98px) {
  .services-bottom-content {
    flex-wrap: wrap;
    text-align: center;
  }
}
.services-bottom-content p {
  margin-bottom: 0;
}
.services__tab-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 30px;
}
@media (max-width: 767.98px) {
  .services__tab-wrap {
    flex-wrap: wrap;
  }
}
.services__tab-wrap .nav-tabs {
  border-bottom: none;
  margin-bottom: 0;
  flex-direction: column;
  width: 330px;
}
@media (max-width: 1199.98px) {
  .services__tab-wrap .nav-tabs {
    width: 270px;
  }
}
@media (max-width: 767.98px) {
  .services__tab-wrap .nav-tabs {
    width: auto;
    justify-content: center;
    flex-direction: row;
  }
}
.services__tab-wrap .nav-item {
  margin-bottom: 5px;
}
.services__tab-wrap .nav-item:last-child {
  margin-bottom: 0;
}
.services__tab-wrap .nav-link {
  border: none;
  background: linear-gradient(90deg, #3B3D9A 13.19%, rgba(44, 46, 143, 0.54) 100%);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin: 0;
  gap: 11px;
  font-size: 20px;
  font-weight: 600;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-color-white-default);
  padding: 15px 55px 15px 25px;
  position: relative;
  width: 330px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 1199.98px) {
  .services__tab-wrap .nav-link {
    width: 270px;
    font-size: 18px;
    padding: 15px 45px 15px 20px;
  }
}
.services__tab-wrap .nav-link i {
  font-size: 40px;
  line-height: 0;
  color: var(--tg-theme-primary);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 1199.98px) {
  .services__tab-wrap .nav-link i {
    font-size: 35px;
  }
}
.services__tab-wrap .nav-link::after {
  content: "\f10a";
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: flaticon;
  font-weight: 500;
  font-size: 24px;
  line-height: 0;
}
.services__tab-wrap .nav-link.active {
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
}
.services__tab-wrap .nav-link.active i {
  color: var(--tg-color-white-default);
}
.services__shape-wrap img {
  position: absolute;
  z-index: -1;
}
.services__shape-wrap img:nth-child(1) {
  left: 0;
  bottom: 0;
}
@media (max-width: 991.98px) {
  .services__shape-wrap img:nth-child(1) {
    width: 200px;
  }
}
.services__shape-wrap img:nth-child(2) {
  right: 0;
  top: 0;
}
@media (max-width: 991.98px) {
  .services__shape-wrap img:nth-child(2) {
    width: 200px;
  }
}
.services__shape-wrap-two img {
  position: absolute;
  z-index: -1;
}
.services__shape-wrap-two img:nth-child(1) {
  left: 0;
  top: 0;
}
@media (max-width: 767.98px) {
  .services__shape-wrap-two img:nth-child(1) {
    width: 200px;
  }
}
.services__shape-wrap-two img:nth-child(2) {
  right: 0;
  bottom: 0;
}
@media (max-width: 767.98px) {
  .services__shape-wrap-two img:nth-child(2) {
    width: 200px;
  }
}
.services__details-area {
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .services__details-area {
    padding: 100px 0;
  }
}
.services__details-wrap .row .col-70 {
  width: 70.4%;
  flex: 0 0 auto;
}
@media (max-width: 991.98px) {
  .services__details-wrap .row .col-70 {
    width: 100%;
  }
}
.services__details-wrap .row .col-30 {
  width: 29.6%;
  flex: 0 0 auto;
}
@media (max-width: 991.98px) {
  .services__details-wrap .row .col-30 {
    width: 100%;
  }
}
.services__details-thumb {
  margin-bottom: 35px;
}
@media (max-width: 767.98px) {
  .services__details-thumb {
    margin-bottom: 25px;
  }
}
.services__details-thumb img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  width: 100%;
  min-height: 250px;
  object-fit: cover;
}
.services__details-thumb-two {
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .services__details-thumb-two {
    margin-bottom: 30px;
  }
}
.services__details-content > .title {
  margin-bottom: 22px;
  font-size: 40px;
  font-weight: 600;
  text-transform: capitalize;
}
@media (max-width: 1199.98px) {
  .services__details-content > .title {
    font-size: 36px;
  }
}
@media (max-width: 767.98px) {
  .services__details-content > .title {
    font-size: 32px;
  }
}
.services__details-content > p {
  margin-bottom: 20px;
}
.services__details-content > p.last-info {
  margin-bottom: 0;
}
.services__details-content .title-two {
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 600;
}
@media (max-width: 1199.98px) {
  .services__details-content .title-two {
    font-size: 32px;
  }
}
@media (max-width: 767.98px) {
  .services__details-content .title-two {
    font-size: 28px;
  }
}
.services__details-content-two > .title {
  font-size: 36px;
}
@media (max-width: 1199.98px) {
  .services__details-content-two > .title {
    font-size: 32px;
  }
}
@media (max-width: 767.98px) {
  .services__details-content-two > .title {
    font-size: 30px;
  }
}
.services__details-top {
  margin-bottom: 30px;
}
.services__details-top .row .col-49 {
  width: 48.9%;
  flex: 0 0 auto;
}
@media (max-width: 767.98px) {
  .services__details-top .row .col-49 {
    width: 100%;
  }
}
.services__details-top .row .col-51 {
  width: 51.1%;
  flex: 0 0 auto;
}
@media (max-width: 767.98px) {
  .services__details-top .row .col-51 {
    width: 100%;
  }
}
.services__details-top-content .title {
  margin-bottom: 15px;
  font-size: 40px;
}
@media (max-width: 1199.98px) {
  .services__details-top-content .title {
    font-size: 36px;
  }
}
@media (max-width: 767.98px) {
  .services__details-top-content .title {
    font-size: 32px;
  }
}
.services__details-top-content p {
  margin-bottom: 15px;
}
.services__details-list {
  margin: 35px 0 10px;
}
.services__details-list-two {
  margin: 25px 0 10px;
}
.services__details-list-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  -webkit-box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #E5E7E8;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 30px 30px;
  margin-bottom: 30px;
}
@media (max-width: 1199.98px) {
  .services__details-list-box {
    gap: 15px;
    padding: 30px 25px;
    flex-wrap: wrap;
  }
}
.services__details-list-box .icon {
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-primary);
  flex: 0 0 auto;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  color: var(--tg-color-white-default);
  font-size: 50px;
  line-height: 0;
}
.services__details-list-box .icon i {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.services__details-list-box .content .title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}
.services__details-list-box .content p {
  margin-bottom: 0;
}
.services__details-list-box:hover .icon i {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.services__details-list-box-two {
  -webkit-box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  background: #FFFBF4;
  border: 1px solid #FBD58A;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 30px 30px 30px;
  text-align: center;
  margin-bottom: 30px;
}
@media (max-width: 1199.98px) {
  .services__details-list-box-two {
    padding: 30px 18px 30px;
  }
}
@media (max-width: 767.98px) {
  .services__details-list-box-two {
    padding: 30px 30px 30px;
  }
}
.services__details-list-box-two .icon {
  width: 80px;
  height: 80px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
  line-height: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  margin: 0 auto 30px;
  font-size: 40px;
}
.services__details-list-box-two .icon i {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.services__details-list-box-two .content .title {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 600;
}
@media (max-width: 1199.98px) {
  .services__details-list-box-two .content .title {
    font-size: 20px;
  }
}
@media (max-width: 767.98px) {
  .services__details-list-box-two .content .title {
    font-size: 22px;
  }
}
.services__details-list-box-two .content p {
  margin-bottom: 0;
}
.services__details-list-box-two:hover .icon i {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.services__details-list-box-three {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 50px;
}
@media (max-width: 1199.98px) {
  .services__details-list-box-three {
    gap: 14px;
    margin-bottom: 30px;
  }
}
.services__details-list-box-three .icon {
  font-size: 60px;
  line-height: 0;
  color: var(--tg-theme-primary);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: rotateY(0) translateY(3px);
  -moz-transform: rotateY(0) translateY(3px);
  -ms-transform: rotateY(0) translateY(3px);
  -o-transform: rotateY(0) translateY(3px);
  transform: rotateY(0) translateY(3px);
}
.services__details-list-box-three .content .title {
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: 600;
}
.services__details-list-box-three .content p {
  margin-bottom: 0;
}
@media (max-width: 1199.98px) {
  .services__details-list-box-three .content p br {
    display: none;
  }
}
.services__details-list-box-three:last-child {
  margin-bottom: 0;
}
.services__details-list-box-three:hover .icon {
  -webkit-transform: rotateY(180deg) translateY(3px);
  -moz-transform: rotateY(180deg) translateY(3px);
  -ms-transform: rotateY(180deg) translateY(3px);
  -o-transform: rotateY(180deg) translateY(3px);
  transform: rotateY(180deg) translateY(3px);
}
.services__details-inner {
  margin: 35px 0 30px;
}
.services__details-inner .row .col-44 {
  width: 43.9%;
  flex: 0 0 auto;
}
@media (max-width: 767.98px) {
  .services__details-inner .row .col-44 {
    width: 100%;
  }
}
.services__details-inner .row .col-56 {
  width: 56.1%;
  flex: 0 0 auto;
}
@media (max-width: 767.98px) {
  .services__details-inner .row .col-56 {
    width: 100%;
  }
}
.services__details-inner-two {
  margin: 80px 0 45px;
}
@media (max-width: 1199.98px) {
  .services__details-inner-two {
    margin: 50px 0 40px;
  }
}
.services__details-inner-two .row .col-48 {
  width: 48.5%;
  flex: 0 0 auto;
}
@media (max-width: 767.98px) {
  .services__details-inner-two .row .col-48 {
    width: 100%;
  }
}
.services__details-inner-two .row .col-52 {
  width: 51.5%;
  flex: 0 0 auto;
}
@media (max-width: 767.98px) {
  .services__details-inner-two .row .col-52 {
    width: 100%;
  }
}
.services__details-inner-three {
  margin-bottom: 40px;
}
.services__details-inner-four {
  margin: 40px 0 35px;
}
.services__details-inner-four .services__details-inner-content-two p {
  margin-bottom: 25px;
}
.services__details-inner-five {
  margin: 0 0 55px;
}
.services__details-inner-five .services__details-inner-content-three {
  margin: 0 30px 0 0;
}
@media (max-width: 1199.98px) {
  .services__details-inner-five .services__details-inner-content-three {
    margin: 0 0 0 0;
  }
}
@media (max-width: 991.98px) {
  .services__details-inner-five .services__details-inner-content-three {
    margin: 0 0 30px 0;
  }
}
.services__details-inner-five .services__details-list-box {
  padding: 25px 25px;
  margin-bottom: 20px;
}
.services__details-inner-five .services__details-list-box:last-child {
  margin-bottom: 0;
}
.services__details-inner-six {
  margin: 30px 0 40px;
}
.services__details-inner-six .services__details-inner-content-three {
  margin: 0 60px 0 0;
}
@media (max-width: 1199.98px) {
  .services__details-inner-six .services__details-inner-content-three {
    margin: 0 0 0 0;
  }
}
@media (max-width: 991.98px) {
  .services__details-inner-six .services__details-inner-content-three {
    margin: 0 0 30px 0;
  }
}
.services__details-inner-img {
  position: relative;
}
@media (max-width: 767.98px) {
  .services__details-inner-img {
    margin-bottom: 30px;
  }
}
.services__details-inner-img img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  min-height: 230px;
  object-fit: cover;
  width: 100%;
}
.services__details-inner-img .play-btn {
  width: 62px;
  height: 62px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: var(--tg-color-white-default);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: var(--tg-theme-secondary);
  font-size: 18px;
}
.services__details-inner-img .play-btn:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
}
.services__details-inner-img-wrap {
  margin: 30px 0 0;
}
.services__details-inner-img-wrap img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  width: 100%;
}
.services__details-inner-content .title {
  margin-bottom: 15px;
  font-size: 24px;
  text-transform: capitalize;
}
@media (max-width: 1199.98px) {
  .services__details-inner-content .title br {
    display: none;
  }
}
.services__details-inner-content p {
  margin-bottom: 20px;
}
.services__details-inner-content-two .title {
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: 600;
}
@media (max-width: 1199.98px) {
  .services__details-inner-content-two .title {
    font-size: 32px;
  }
}
@media (max-width: 767.98px) {
  .services__details-inner-content-two .title {
    font-size: 28px;
  }
}
.services__details-inner-content-two p {
  margin-bottom: 0;
  width: 90%;
}
@media (max-width: 1199.98px) {
  .services__details-inner-content-two p {
    width: 100%;
  }
}
.services__details-inner-content-three {
  margin-left: 30px;
}
@media (max-width: 1199.98px) {
  .services__details-inner-content-three {
    margin-left: 0;
  }
}
@media (max-width: 767.98px) {
  .services__details-inner-content-three {
    margin-top: 30px;
  }
}
.services__details-inner-content-three .title {
  font-size: 30px;
  margin-bottom: 20px;
}
@media (max-width: 767.98px) {
  .services__details-inner-content-three .title {
    font-size: 28px;
  }
}
.services__details-inner-content-three p {
  margin-bottom: 0;
}
.services__details-inner-graph img {
  -webkit-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  border: 0.8px solid #DADADA;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
}
.services__sidebar {
  margin-right: 20px;
}
@media (max-width: 1199.98px) {
  .services__sidebar {
    margin-right: 0;
  }
}
@media (max-width: 991.98px) {
  .services__sidebar {
    margin-top: 100px;
  }
}

.sidebar__widget-two {
  border: none !important;
  padding: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  border-radius: 0 !important;
}
.sidebar__widget-three {
  border: none !important;
  background: var(--tg-color-gray-3);
}
.sidebar__cat-list-two .list-wrap li {
  margin-bottom: 10px;
}
.sidebar__cat-list-two .list-wrap li a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--tg-color-gray-3);
  border: 1px solid #E0EEF3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  padding: 16px 20px;
  font-size: 18px;
  font-weight: 500;
  color: var(--tg-body-color);
}
.sidebar__cat-list-two .list-wrap li a i {
  font-size: 24px;
  line-height: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.sidebar__cat-list-two .list-wrap li a:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
  border-color: var(--tg-theme-secondary);
}
.sidebar__cat-list-two .list-wrap li a:hover i {
  color: var(--tg-theme-primary);
}
.sidebar__cat-list-two .list-wrap li:last-child {
  margin-bottom: 0;
}
.sidebar__cat-list-three .list-wrap li a {
  background: var(--tg-color-white-default);
}
.sidebar__brochure p {
  margin-bottom: 15px;
}
.sidebar__brochure a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  background: var(--tg-color-gray-3);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 14px;
  font-weight: 500;
  color: var(--tg-heading-color);
  gap: 12px;
  margin-bottom: 10px;
}
.sidebar__brochure a i {
  font-size: 18px;
}
.sidebar__brochure a:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
}
.sidebar__brochure a:last-child {
  margin-bottom: 0;
}
.sidebar__brochure-two a {
  background: var(--tg-color-white-default);
}
.sidebar__contact {
  background: var(--tg-theme-secondary);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 50px 40px 55px;
  text-align: center;
}
@media (max-width: 1199.98px) {
  .sidebar__contact {
    padding: 40px 20px 40px;
  }
}
@media (max-width: 767.98px) {
  .sidebar__contact {
    padding: 40px 30px 40px;
  }
}
.sidebar__contact .title {
  color: var(--tg-color-white-default);
  margin-bottom: 30px;
  font-size: 24px;
}
.sidebar__contact .btn {
  gap: 5px;
  padding: 13px 24px;
}
.sidebar__contact .btn i {
  font-size: 24px;
  line-height: 0;
}
.sidebar__contact .btn::after {
  display: none;
}
.sidebar__contact .btn::before {
  background: var(--tg-color-gray-3);
}
.sidebar__contact .btn:hover {
  color: var(--tg-theme-secondary);
}
.sidebar__contact-two {
  background-size: cover;
  background-position: center;
  padding: 30px 40px 180px;
}
@media (max-width: 1199.98px) {
  .sidebar__contact-two {
    padding: 30px 20px 180px;
  }
}
@media (max-width: 991.98px) {
  .sidebar__contact-two {
    padding: 30px 20px 70px;
  }
}
.sidebar__contact-two .title {
  color: var(--tg-theme-secondary);
}
.sidebar__contact-two .btn {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}
.sidebar__contact-two .btn::before {
  background: var(--tg-theme-secondary);
}
.sidebar__contact-two .btn:hover {
  color: var(--tg-color-white-default);
}
.sidebar__form form .form-grp {
  margin-bottom: 8px;
}
.sidebar__form form .form-grp textarea, .sidebar__form form .form-grp input {
  width: 100%;
  border: 1px solid #E0E6F2;
  background: var(--tg-color-white-default);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  padding: 14px 15px;
  font-size: 14px;
  color: var(--tg-body-color);
  font-weight: 500;
  height: 50px;
  display: block;
}
.sidebar__form form .form-grp textarea::placeholder, .sidebar__form form .form-grp input::placeholder {
  font-size: 14px;
  color: #667594;
  font-weight: 500;
  text-transform: capitalize;
}
.sidebar__form form .form-grp textarea {
  min-height: 120px;
  max-height: 120px;
}
.sidebar__form form .btn {
  width: 100%;
  justify-content: center;
}
.sidebar__form form .btn::after {
  display: none;
}

.shine-animate {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.shine-animate::before {
  position: absolute;
  top: 0;
  left: -100%;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.shine-animate-item:hover .shine-animate::before {
  -webkit-animation: hoverShine 1.2s;
  animation: hoverShine 1.2s;
}

.section-more-btn {
  text-align: right;
}
@media (max-width: 991.98px) {
  .section-more-btn {
    text-align: left;
    margin-bottom: 50px;
  }
}
.section-more-btn .border-btn {
  border-color: var(--tg-color-white-default);
  color: var(--tg-color-white-default);
}
.section-more-btn .border-btn:hover {
  color: var(--tg-theme-secondary);
  background: var(--tg-color-white-default);
  border-color: var(--tg-color-white-default);
}

.about__list-box-three .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin: 0 -5px;
  flex-wrap: wrap;
  gap: 10px 0;
}
.about__list-box-three .list-wrap li {
  width: 50%;
  flex: 0 0 auto;
  padding: 0 5px;
  margin: 0;
}
@media (max-width: 767.98px) {
  .about__list-box-three .list-wrap li {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about__list-box-three .list-wrap li {
    width: 50%;
  }
}

/*=============================
    08. Choose
===============================*/
.choose-area {
  background: #171A7C;
  padding: 120px 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .choose-area {
    padding: 100px 0;
  }
}
.choose__area-two {
  position: relative;
  overflow: hidden;
  margin: 120px 0 0;
  padding: 85px 0 0;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .choose__area-two {
    padding: 0;
    margin: 0;
  }
}
.choose__area-three {
  position: relative;
  overflow: hidden;
  padding: 120px 0;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .choose__area-three {
    padding: 100px 0;
  }
}
.choose__area-four {
  background: #171A7C;
  padding: 120px 0;
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .choose__area-four {
    padding: 100px 0;
  }
}
.choose__area-five {
  padding: 0 0 120px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .choose__area-five {
    padding: 0 0 100px;
  }
}
.choose__bg {
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 46%;
  height: 90%;
  z-index: -2;
}
@media (max-width: 1199.98px) {
  .choose__bg {
    width: 55%;
  }
}
.choose-content > p {
  margin-bottom: 30px;
  color: var(--tg-color-gray-3);
  width: 85%;
}
@media (max-width: 1199.98px) {
  .choose-content > p {
    width: 100%;
  }
}
.choose__content-two {
  position: relative;
  padding: 100px 100px 120px 80px;
}
@media (max-width: 1199.98px) {
  .choose__content-two {
    padding: 80px 0 120px 40px;
  }
}
@media (max-width: 767.98px) {
  .choose__content-two {
    padding: 100px 0 100px 0;
  }
}
.choose__content-two::before {
  content: "";
  position: absolute;
  left: -24px;
  top: 0;
  width: 200%;
  height: 100%;
  background: var(--tg-color-gray-3);
  z-index: -2;
}
@media (max-width: 1199.98px) {
  .choose__content-two::before {
    left: 0;
  }
}
@media (max-width: 767.98px) {
  .choose__content-two::before {
    left: -50%;
  }
}
.choose__content-two > p {
  margin-bottom: 35px;
}
.choose__content-three > p {
  margin-bottom: 30px;
}
.choose__content-four {
  width: 90%;
}
@media (max-width: 1199.98px) {
  .choose__content-four {
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .choose__content-four {
    margin-bottom: 50px;
  }
}
.choose__content-four > p {
  margin-bottom: 0;
  color: var(--tg-color-gray-3);
}
.choose__content-five {
  position: relative;
  padding-right: 50px;
}
@media (max-width: 1199.98px) {
  .choose__content-five {
    padding-right: 0;
  }
}
.choose__content-five > p {
  margin-bottom: 35px;
}
.choose__content-five .shape img {
  position: absolute;
  z-index: -1;
  right: -20px;
  top: 0;
}
.choose-list .list-wrap li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 35px;
}
.choose-list .list-wrap li:last-child {
  margin-bottom: 0;
}
.choose-list .list-wrap li .icon {
  font-size: 60px;
  line-height: 0;
  color: var(--tg-theme-primary);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.choose-list .list-wrap li .content .title {
  color: var(--tg-color-white-default);
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 22px;
}
.choose-list .list-wrap li .content p {
  color: var(--tg-color-gray-3);
  margin-bottom: 0;
  width: 65%;
}
@media (max-width: 767.98px) {
  .choose-list .list-wrap li .content p {
    width: 100%;
  }
}
.choose-list .list-wrap li:hover .icon {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.choose__list .list-wrap li {
  margin-bottom: 30px;
}
.choose__list .list-wrap li:last-child {
  margin-bottom: 0;
}
.choose__list-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.choose__list-box:hover .choose__list-icon i {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.choose__list-box-two {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
@media (max-width: 1199.98px) {
  .choose__list-box-two {
    flex-wrap: wrap;
  }
}
@media (max-width: 991.98px) {
  .choose__list-box-two {
    flex-wrap: nowrap;
  }
}
.choose__list-box-two:hover .choose__list-icon-two {
  -webkit-transform: translateY(5px) rotateY(180deg);
  -moz-transform: translateY(5px) rotateY(180deg);
  -ms-transform: translateY(5px) rotateY(180deg);
  -o-transform: translateY(5px) rotateY(180deg);
  transform: translateY(5px) rotateY(180deg);
}
.choose__list-icon {
  width: 80px;
  height: 80px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-color-yellow-light);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  font-size: 50px;
  color: var(--tg-theme-primary);
  flex: 0 0 auto;
  line-height: 0;
}
.choose__list-icon-two {
  font-size: 60px;
  line-height: 0;
  color: var(--tg-theme-primary);
  -webkit-transform: translateY(5px) rotateY(0deg);
  -moz-transform: translateY(5px) rotateY(0deg);
  -ms-transform: translateY(5px) rotateY(0deg);
  -o-transform: translateY(5px) rotateY(0deg);
  transform: translateY(5px) rotateY(0deg);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.choose__list-icon i {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.choose__list-content .title {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
}
.choose__list-content p {
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .choose__list-content p br {
    display: none;
  }
}
.choose__list-content-two .title {
  color: var(--tg-color-white-default);
  margin-bottom: 5px;
  font-size: 22px;
}
.choose__list-content-two p {
  margin-bottom: 0;
  color: var(--tg-color-gray-3);
}
.choose__list-two .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  gap: 40px 0;
}
.choose__list-two .list-wrap li {
  width: 50%;
  padding: 0 10px;
}
@media (max-width: 767.98px) {
  .choose__list-two .list-wrap li {
    width: 100%;
  }
}
.choose-img-wrap {
  position: relative;
  padding-bottom: 45px;
}
@media (max-width: 991.98px) {
  .choose-img-wrap {
    text-align: center;
    margin-bottom: 50px;
  }
}
@media (max-width: 767.98px) {
  .choose-img-wrap {
    margin: 0;
  }
}
.choose-img-wrap img:nth-child(1) {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
@media (max-width: 767.98px) {
  .choose-img-wrap img:nth-child(1) {
    width: 100%;
  }
}
.choose-img-wrap img:nth-child(2) {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: -16%;
  bottom: 0;
}
@media (max-width: 1199.98px) {
  .choose-img-wrap img:nth-child(2) {
    left: -9%;
  }
}
@media (max-width: 991.98px) {
  .choose-img-wrap img:nth-child(2) {
    left: -2%;
  }
}
@media (max-width: 767.98px) {
  .choose-img-wrap img:nth-child(2) {
    display: none;
  }
}
.choose-img-wrap img:nth-child(3) {
  position: absolute;
  left: 10%;
  bottom: -45px;
  z-index: -1;
}
@media (max-width: 1199.98px) {
  .choose-img-wrap img:nth-child(3) {
    left: 30%;
  }
}
@media (max-width: 767.98px) {
  .choose-img-wrap img:nth-child(3) {
    left: 8%;
    bottom: -25px;
  }
}
.choose__img-wrap-three {
  position: relative;
  text-align: right;
}
@media (max-width: 991.98px) {
  .choose__img-wrap-three {
    margin-bottom: 50px;
  }
}
.choose__img-wrap-three .main-img {
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  border: 18px solid var(--tg-color-white-default);
  -webkit-box-shadow: 0px 56px 80px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 56px 80px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0px 56px 80px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0px 56px 80px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 56px 80px 0px rgba(0, 0, 0, 0.15);
}
@media (max-width: 1199.98px) {
  .choose__img-wrap-three .main-img {
    width: 400px;
    border-width: 10px;
  }
}
@media (max-width: 767.98px) {
  .choose__img-wrap-three .main-img {
    width: 100%;
    border-width: 5px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .choose__img-wrap-three .main-img {
    width: 500px;
    margin: 0 auto;
  }
}
.choose__img-wrap-three .main-img img {
  width: 100%;
}
.choose__img-wrap-three .main-img .play-btn {
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: var(--tg-theme-secondary);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: var(--tg-color-white-default);
  font-size: 25px;
}
.choose__img-wrap-three .main-img .play-btn:hover {
  background: var(--tg-theme-primary);
}
.choose__img-wrap-three > img {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #DADADA;
  position: absolute;
  left: 9%;
  top: 30%;
}
@media (max-width: 1199.98px) {
  .choose__img-wrap-three > img {
    left: 0;
  }
}
@media (max-width: 767.98px) {
  .choose__img-wrap-three > img {
    display: none;
  }
}
.choose__img-wrap-three .shape img {
  position: absolute;
  left: 3%;
  bottom: 18%;
  z-index: -1;
}
@media (max-width: 1199.98px) {
  .choose__img-wrap-three .shape img {
    display: none;
  }
}
.choose__img-wrap-five {
  position: relative;
  padding-bottom: 55px;
}
@media (max-width: 991.98px) {
  .choose__img-wrap-five {
    margin-bottom: 50px;
  }
}
@media (max-width: 767.98px) {
  .choose__img-wrap-five {
    text-align: center;
  }
}
.choose__img-wrap-five img:nth-child(1) {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
.choose__img-wrap-five img:nth-child(2) {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  border: 6px solid var(--tg-color-white-default);
  position: absolute;
  right: 10%;
  bottom: 0;
}
@media (max-width: 1199.98px) {
  .choose__img-wrap-five img:nth-child(2) {
    right: 0;
  }
}
@media (max-width: 767.98px) {
  .choose__img-wrap-five img:nth-child(2) {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .choose__img-wrap-five img:nth-child(2) {
    display: block;
  }
}
.choose__img-wrap-five img:nth-child(3) {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 17%;
  bottom: 8%;
}
.choose__img-wrap-five img:nth-child(4) {
  position: absolute;
  left: 13%;
  bottom: -4%;
  z-index: -1;
}
.choose__tab .nav-tabs {
  border-bottom: 1px solid #CEE2EA;
  gap: 30px;
  margin-bottom: 30px;
}
.choose__tab .nav-tabs .nav-item .nav-link {
  padding: 0 0 13px;
  background: transparent;
  color: #9597C8;
  border: none;
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  font-family: var(--tg-heading-font-family);
  text-transform: capitalize;
  position: relative;
}
@media (max-width: 767.98px) {
  .choose__tab .nav-tabs .nav-item .nav-link {
    font-size: 18px;
  }
}
.choose__tab .nav-tabs .nav-item .nav-link::before {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -1px;
  width: 50%;
  height: 3px;
  background: var(--tg-theme-primary);
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.choose__tab .nav-tabs .nav-item .nav-link.active {
  color: var(--tg-heading-color);
}
.choose__tab .nav-tabs .nav-item .nav-link.active::before {
  opacity: 1;
}
.choose__tab-content p {
  margin-bottom: 30px;
}
.choose__tab-content .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px 0;
}
.choose__tab-content .list-wrap li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  font-weight: 600;
  text-transform: capitalize;
  gap: 10px;
  width: 50%;
}
@media (max-width: 767.98px) {
  .choose__tab-content .list-wrap li {
    width: 100%;
  }
}
.choose__tab-content .list-wrap li i {
  width: 26px;
  height: 26px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-heading-color);
  border-radius: 50%;
  color: var(--tg-color-white-default);
  flex: 0 0 auto;
  font-size: 14px;
}
.choose__box {
  position: relative;
}
@media (max-width: 767.98px) {
  .choose__box {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .choose__box {
    margin-bottom: 0;
  }
}
.choose__box .icon {
  width: 80px;
  height: 80px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-color-yellow-light);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  font-size: 40px;
  color: var(--tg-theme-primary);
  line-height: 0;
  margin-bottom: 15px;
}
.choose__box .icon i {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.choose__box .content .title {
  font-size: 22px;
  margin-bottom: 12px;
}
.choose__box .content p {
  margin-bottom: 0;
}
.choose__box::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 100%;
  background: var(--tg-border-4);
}
@media (max-width: 1199.98px) {
  .choose__box::before {
    right: -15px;
  }
}
@media (max-width: 991.98px) {
  .choose__box::before {
    right: 0;
  }
}
@media (max-width: 767.98px) {
  .choose__box::before {
    display: none;
  }
}
.choose__box:hover .icon i {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.choose__box-wrap .row [class*=col-]:last-child .choose__box {
  margin-bottom: 0;
}
.choose__box-wrap .row [class*=col-]:last-child .choose__box::before {
  display: none;
}
.choose-shape-wrap img {
  position: absolute;
  z-index: -1;
}
.choose-shape-wrap img:nth-child(1) {
  left: 0;
  bottom: 0;
}
@media (max-width: 1199.98px) {
  .choose-shape-wrap img:nth-child(1) {
    width: 300px;
  }
}
@media (max-width: 767.98px) {
  .choose-shape-wrap img:nth-child(1) {
    width: 230px;
  }
}
.choose-shape-wrap img:nth-child(2) {
  right: 0;
  top: 0;
}
@media (max-width: 1199.98px) {
  .choose-shape-wrap img:nth-child(2) {
    width: 400px;
  }
}
.choose__shape-wrap-two img {
  position: absolute;
  z-index: -1;
}
.choose__shape-wrap-two img:nth-child(1) {
  right: 13%;
  bottom: 30%;
}
@media (max-width: 991.98px) {
  .choose__shape-wrap-two img:nth-child(1) {
    display: none;
  }
}
.choose__shape-wrap-two img:nth-child(2) {
  right: 0;
  bottom: 0;
  z-index: -2;
}
.choose__shape-wrap-three img {
  position: absolute;
  z-index: -1;
}
.choose__shape-wrap-three img:nth-child(1) {
  right: 14%;
  top: 25%;
}
@media (max-width: 767.98px) {
  .choose__shape-wrap-three img:nth-child(1) {
    right: 26%;
    top: 13%;
  }
}
.choose__shape-wrap-three img:nth-child(2) {
  right: 0;
  top: 16%;
  z-index: -2;
}
@media (max-width: 1199.98px) {
  .choose__shape-wrap-three img:nth-child(2) {
    display: none;
  }
}
.choose__shape-wrap-four img {
  position: absolute;
  z-index: -1;
}
.choose__shape-wrap-four img:nth-child(1) {
  left: 0;
  bottom: 0;
}
.choose__shape-wrap-four img:nth-child(2) {
  right: 0;
  top: 0;
}

.operating__box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  background: var(--tg-theme-secondary);
  width: 370px;
  padding: 35px 35px;
  gap: 14px;
  position: absolute;
  left: -394px;
  bottom: 0;
}
@media (max-width: 1199.98px) {
  .operating__box {
    left: -330px;
    width: 330px;
    padding: 35px 18px;
  }
}
@media (max-width: 991.98px) {
  .operating__box {
    display: none;
  }
}
.operating__box .icon {
  font-size: 53px;
  line-height: 0;
  color: var(--tg-color-white-default);
}
.operating__box .content p {
  margin-bottom: 0;
  color: var(--tg-color-white-default);
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: var(--tg-heading-font-family);
  line-height: 1.2;
}

.choose__box:hover .icon svg {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/*=============================
	09. Project
===============================*/
.project-area {
  padding: 120px 0 120px;
  position: relative;
}
@media (max-width: 767.98px) {
  .project-area {
    padding: 100px 0;
  }
}
.project__area-two {
  background: var(--tg-color-yellow-light-2);
  padding: 120px 0 90px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .project__area-two {
    padding: 100px 0 70px;
  }
}
.project__area-three {
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .project__area-three {
    padding: 100px 0;
  }
}
.project-item {
  border: 1px solid var(--tg-border-1);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  background: var(--tg-color-white-default);
}
.project-item-wrap .row {
  --bs-gutter-x: 24px;
}
.project-item:hover .project-thumb::before {
  height: 100%;
}
.project__item-two {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}
.project__item-two:hover .project__thumb-two::before {
  height: 100%;
}
.project__item-two:hover .project__content-two {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}
.project__item-three {
  position: relative;
  padding: 0 105px;
}
@media (max-width: 1199.98px) {
  .project__item-three {
    padding: 0 80px;
  }
}
@media (max-width: 991.98px) {
  .project__item-three {
    padding: 0 50px;
  }
}
@media (max-width: 767.98px) {
  .project__item-three {
    padding: 0;
  }
}
.project__item-four {
  position: relative;
}
.project__item-four:hover .project__thumb-four img {
  filter: grayscale(0);
}
.project__item-four:hover .project__content-four .right-arrow {
  opacity: 1;
}
.project-thumb {
  -webkit-border-radius: 10px 10px 0 120px;
  -moz-border-radius: 10px 10px 0 120px;
  -o-border-radius: 10px 10px 0 120px;
  -ms-border-radius: 10px 10px 0 120px;
  border-radius: 10px 10px 0 120px;
  overflow: hidden;
  position: relative;
  margin: -1px -1px 0;
}
.project-thumb img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
@media (max-width: 1500px) {
  .project-thumb img {
    height: 330px;
  }
}
.project-thumb::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  background: #AF7400;
  opacity: 0.6;
  pointer-events: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.project__thumb-two {
  position: relative;
  overflow: hidden;
}
.project__thumb-two img {
  border-radius: 15px;
  height: 300px;
  object-fit: cover;
  width: 100%;
}
.project__thumb-two::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0%;
  background: var(--tg-color-dark-blue);
  opacity: 0.9;
  border-radius: 15px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.project__thumb-three {
  width: 782px;
  margin-left: auto;
}
@media (max-width: 1199.98px) {
  .project__thumb-three {
    width: 580px;
  }
}
@media (max-width: 991.98px) {
  .project__thumb-three {
    width: 450px;
  }
}
@media (max-width: 767.98px) {
  .project__thumb-three {
    display: none;
  }
}
.project__thumb-three img {
  height: 478px;
  width: 100%;
  object-fit: cover;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
}
@media (max-width: 1199.98px) {
  .project__thumb-three img {
    height: 430px;
  }
}
.project__thumb-four {
  position: relative;
  overflow: hidden;
}
.project__thumb-four img {
  width: 100%;
  height: 570px;
  object-fit: cover;
  filter: grayscale(1);
}
@media (max-width: 1199.98px) {
  .project__thumb-four img {
    height: 480px;
  }
}
.project__thumb-four::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(11, 12, 57, 0) 0%, rgba(0, 1, 38, 0.9) 100%);
  pointer-events: none;
}
.project-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px;
  gap: 10px;
}
@media (max-width: 1500px) {
  .project-content {
    padding: 30px 20px;
  }
}
@media (max-width: 1199.98px) {
  .project-content {
    padding: 30px 30px;
  }
}
@media (max-width: 991.98px) {
  .project-content {
    padding: 30px 20px;
  }
}
.project-content .title {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 1500px) {
  .project-content .title {
    font-size: 22px;
  }
}
.project-content span {
  display: block;
  line-height: 1;
  font-size: 15px;
  font-weight: 500;
  color: var(--tg-theme-primary);
}
.project-content-bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 40px;
}
@media (max-width: 767.98px) {
  .project-content-bottom {
    gap: 30px;
    flex-wrap: wrap;
    text-align: center;
  }
}
.project-content-bottom p {
  margin-bottom: 0;
  font-weight: 500;
  color: var(--tg-heading-color);
}
.project__content-two {
  text-align: center;
  position: absolute;
  left: 30px;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 30px 0;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  margin-top: 100px;
  opacity: 0;
  visibility: hidden;
}
.project__content-two .title {
  font-size: 24px;
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-color-white-default);
  margin-bottom: 5px;
}
.project__content-two span {
  display: block;
  font-size: 15px;
  font-weight: var(--tg-fw-medium);
  color: var(--tg-theme-primary);
  margin-bottom: 15px;
}
.project__content-three {
  width: 392px;
  background: var(--tg-theme-secondary);
  border-radius: 15px;
  padding: 60px 60px;
  position: absolute;
  left: 105px;
  top: 50%;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 1199.98px) {
  .project__content-three {
    width: 360px;
    padding: 40px 40px;
    left: 80px;
  }
}
@media (max-width: 991.98px) {
  .project__content-three {
    left: 50px;
  }
}
@media (max-width: 767.98px) {
  .project__content-three {
    width: 100%;
    position: unset;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
.project__content-three span {
  font-weight: 500;
  color: var(--tg-theme-primary);
  display: block;
  margin-bottom: 5px;
}
.project__content-three .title {
  font-size: 24px;
  margin-bottom: 18px;
  color: var(--tg-color-white-default);
}
.project__content-three p {
  margin-bottom: 25px;
  color: var(--tg-color-gray-3);
}
.project__content-three .btn {
  background: var(--tg-color-white-default);
  color: var(--tg-heading-color);
}
.project__content-three .btn:hover {
  color: var(--tg-color-white-default);
}
.project__content-four {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  position: absolute;
  left: 50px;
  right: 50px;
  bottom: 50px;
}
@media (max-width: 1500px) {
  .project__content-four {
    left: 25px;
    right: 25px;
    bottom: 30px;
  }
}
.project__content-four .left-content .title {
  margin-bottom: 10px;
  font-size: 30px;
  color: var(--tg-color-white-default);
}
@media (max-width: 1500px) {
  .project__content-four .left-content .title {
    font-size: 24px;
  }
}
.project__content-four .left-content span {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: var(--tg-theme-primary);
  line-height: 1;
}
.project__content-four .right-arrow {
  width: 50px;
  height: 50px;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
  line-height: 0;
  font-size: 32px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.project__content-four .right-arrow:hover {
  background: var(--tg-theme-primary);
}
.project-shape-wrap img {
  position: absolute;
  z-index: -1;
}
.project-shape-wrap img:nth-child(1) {
  left: 0;
  bottom: 10%;
}
.project-shape-wrap img:nth-child(2) {
  right: 2%;
  top: 22%;
}
@media (max-width: 1199.98px) {
  .project-shape-wrap img:nth-child(2) {
    top: 16%;
  }
}
@media (max-width: 767.98px) {
  .project-shape-wrap img:nth-child(2) {
    top: 10.7%;
    width: 80px;
    right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-shape-wrap img:nth-child(2) {
    top: 9.7%;
    width: 80px;
    right: 2%;
  }
}
.project__shape-wrap-two img {
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
}
@media (max-width: 767.98px) {
  .project__shape-wrap-two img {
    width: 250px;
  }
}
.project__shape-wrap-three img {
  position: absolute;
  z-index: -1;
}
.project__shape-wrap-three img:nth-child(1) {
  right: 0;
  top: 0;
}
@media (max-width: 991.98px) {
  .project__shape-wrap-three img:nth-child(1) {
    width: 200px;
  }
}
.project__shape-wrap-three img:nth-child(2) {
  left: 20%;
  bottom: 13%;
}
@media (max-width: 1199.98px) {
  .project__shape-wrap-three img:nth-child(2) {
    left: 6%;
    bottom: 12%;
  }
}
@media (max-width: 767.98px) {
  .project__nav-wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
}
.project__nav-wrap .project-button-prev {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-color-white-default);
  line-height: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  z-index: 3;
  font-size: 28px;
  color: var(--tg-theme-secondary);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 991.98px) {
  .project__nav-wrap .project-button-prev {
    width: 50px;
    height: 50px;
    font-size: 25px;
  }
}
@media (max-width: 767.98px) {
  .project__nav-wrap .project-button-prev {
    position: unset;
    -webkit-transform: translateY(0) rotate(180deg);
    -moz-transform: translateY(0) rotate(180deg);
    -ms-transform: translateY(0) rotate(180deg);
    -o-transform: translateY(0) rotate(180deg);
    transform: translateY(0) rotate(180deg);
  }
}
.project__nav-wrap .project-button-prev:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
}
.project__nav-wrap .project-button-next {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-color-white-default);
  line-height: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  z-index: 3;
  font-size: 28px;
  color: var(--tg-theme-secondary);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 991.98px) {
  .project__nav-wrap .project-button-next {
    width: 50px;
    height: 50px;
    font-size: 25px;
  }
}
@media (max-width: 767.98px) {
  .project__nav-wrap .project-button-next {
    position: unset;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
.project__nav-wrap .project-button-next:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
}
.project__details-area {
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .project__details-area {
    padding: 100px 0;
  }
}
.project__details-top .row .col-70 {
  width: 70.4%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .project__details-top .row .col-70 {
    width: 64.4%;
  }
}
@media (max-width: 991.98px) {
  .project__details-top .row .col-70 {
    width: 100%;
  }
}
.project__details-top .row .col-30 {
  width: 29.6%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .project__details-top .row .col-30 {
    width: 35.6%;
  }
}
@media (max-width: 991.98px) {
  .project__details-top .row .col-30 {
    width: 100%;
  }
}
.project__details-thumb {
  height: 100%;
  padding-bottom: 30px;
}
.project__details-thumb img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: 250px;
}
.project__details-info {
  background: var(--tg-heading-color);
  padding: 25px 30px 30px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-left: 20px;
}
@media (max-width: 1199.98px) {
  .project__details-info {
    margin-left: 0;
    padding: 25px 20px 30px;
  }
}
@media (max-width: 991.98px) {
  .project__details-info {
    padding: 25px 30px 30px;
  }
}
.project__details-info .title {
  font-size: 22px;
  font-weight: 600;
  color: var(--tg-color-white-default);
  margin-bottom: 22px;
  position: relative;
  padding-bottom: 15px;
}
.project__details-info .title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 4px;
  background: var(--tg-theme-primary);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.project__details-info > .list-wrap > li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-color-white-default);
  border-bottom: 1px solid #292C7C;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.project__details-info > .list-wrap > li span {
  font-weight: 400;
  color: #AAACDF;
  min-width: 80px;
  margin-right: 10px;
}
.project__details-info > .list-wrap > li:last-child {
  border-bottom: none;
  margin: 0;
  padding: 0;
}
.project__details-info > .list-wrap > li .project-social {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
}
.project__details-info > .list-wrap > li .project-social li a {
  font-size: 18px;
  color: var(--tg-color-white-default);
}
.project__details-info > .list-wrap > li .project-social li a:hover {
  color: var(--tg-theme-primary);
}
.project__details-content > .title {
  font-size: 36px;
  margin-bottom: 20px;
}
@media (max-width: 767.98px) {
  .project__details-content > .title {
    font-size: 32px;
  }
}
.project__details-content > p {
  margin-bottom: 20px;
}
.project__details-inner {
  margin-top: 70px;
}
@media (max-width: 767.98px) {
  .project__details-inner {
    margin-top: 40px;
  }
}
.project__details-inner-content .title {
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .project__details-inner-content .title {
    font-size: 30px;
  }
}
.project__details-inner-content p {
  margin-bottom: 0;
}
.project__details-inner-content p.last-info {
  margin-bottom: 0;
}
.project__details-inner-content .content-inner {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
  margin: 30px 0;
}
.project__details-inner-content .content-inner .graph-img img {
  -webkit-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -7px 7px 0px 0px rgba(0, 0, 0, 0.1);
  border: 0.8px solid #DADADA;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
}
.project__details-inner-img {
  text-align: right;
}
@media (max-width: 991.98px) {
  .project__details-inner-img {
    text-align: center;
    margin-bottom: 30px;
  }
}
.project__details-inner-img img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.link-arrow a {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-color-gray-3);
  border: 1px solid var(--tg-border-1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: var(--tg-theme-secondary);
  font-size: 18px;
  line-height: 0;
  --arrow-hover-move-x: 110%;
  --arrow-hover-move-y: 100%;
}
.link-arrow a svg {
  width: 20px;
}
.link-arrow a svg path {
  transition: transform 0.38s cubic-bezier(0.37, 0.08, 0.02, 0.93), opacity 0.18s ease-out;
}
.link-arrow a svg path:nth-of-type(1) {
  -webkit-transform: translateX(0) translateY(0);
  -moz-transform: translateX(0) translateY(0);
  -ms-transform: translateX(0) translateY(0);
  -o-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
  opacity: 1;
  transition-delay: 0.15s, 0.15s;
}
.link-arrow a svg path:nth-of-type(2) {
  -webkit-transform: translateX(calc(-1 * var(--arrow-hover-move-x))) translateY(var(--arrow-hover-move-y));
  -moz-transform: translateX(calc(-1 * var(--arrow-hover-move-x))) translateY(var(--arrow-hover-move-y));
  -ms-transform: translateX(calc(-1 * var(--arrow-hover-move-x))) translateY(var(--arrow-hover-move-y));
  -o-transform: translateX(calc(-1 * var(--arrow-hover-move-x))) translateY(var(--arrow-hover-move-y));
  transform: translateX(calc(-1 * var(--arrow-hover-move-x))) translateY(var(--arrow-hover-move-y));
  opacity: 0.5;
  transition-delay: 0s, 0s;
}
.link-arrow a:hover {
  background: var(--tg-theme-secondary);
  border-color: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
}
.link-arrow a:hover svg path:nth-of-type(1) {
  -webkit-transform: translateX(var(--arrow-hover-move-x)) translateY(calc(-1 * var(--arrow-hover-move-y)));
  -moz-transform: translateX(var(--arrow-hover-move-x)) translateY(calc(-1 * var(--arrow-hover-move-y)));
  -ms-transform: translateX(var(--arrow-hover-move-x)) translateY(calc(-1 * var(--arrow-hover-move-y)));
  -o-transform: translateX(var(--arrow-hover-move-x)) translateY(calc(-1 * var(--arrow-hover-move-y)));
  transform: translateX(var(--arrow-hover-move-x)) translateY(calc(-1 * var(--arrow-hover-move-y)));
  opacity: 0;
  transition-delay: 0s, 0s;
}
.link-arrow a:hover svg path:nth-of-type(2) {
  -webkit-transform: translateX(0) translateY(0);
  -moz-transform: translateX(0) translateY(0);
  -ms-transform: translateX(0) translateY(0);
  -o-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
  opacity: 1;
  transition-delay: 0.15s, 0.15s;
}
.link-arrow-two a {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
  border: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  margin: 0 auto;
}
.link-arrow-two a:hover {
  color: var(--tg-color-white-default);
  background: var(--tg-theme-secondary);
}

/*=============================
	10. Pricing
===============================*/
.pricing__bg {
  padding: 120px 0 90px;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center;
}
@media (max-width: 767.98px) {
  .pricing__bg {
    padding: 100px 0 70px;
  }
}
.pricing__bg-two {
  padding: 255px 0 90px;
  margin-top: -135px;
}
@media (max-width: 767.98px) {
  .pricing__bg-two {
    padding: 235px 0 70px;
  }
}
.pricing__tab {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 40px;
}
.pricing__tab-btn {
  cursor: pointer;
  color: var(--tg-border-5);
  font-weight: 600;
  font-size: 18px;
  user-select: none;
  font-family: var(--tg-heading-font-family);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.pricing__tab-btn.monthly_tab_title {
  color: var(--tg-heading-color);
}
.pricing__tab-btn.monthly_tab_title.active {
  color: var(--tg-border-5);
}
.pricing__tab-btn.annual_tab_title.active {
  color: var(--tg-heading-color);
}
.pricing__tab-switcher {
  height: 30px;
  width: 60px;
  display: inline-block;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  position: relative;
  margin: 0 15px;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  cursor: pointer;
  background: var(--tg-theme-primary);
}
.pricing__tab-switcher::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  width: 26px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: var(--tg-color-white-default);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.pricing__tab-switcher.active:before {
  left: calc(100% - 28px);
}
.pricing__box {
  background: var(--tg-color-white-default);
  border: 1px solid #CFDDE2;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  padding: 50px 65px;
  margin-bottom: 30px;
}
@media (max-width: 1199.98px) {
  .pricing__box {
    padding: 40px 25px;
  }
}
@media (max-width: 991.98px) {
  .pricing__box {
    padding: 40px 30px;
  }
}
.pricing__box-two {
  text-align: center;
}
.pricing__box-two .pricing__price {
  margin-bottom: 20px;
}
.pricing__box-two .pricing__price .price {
  justify-content: center;
}
.pricing__box-two .pricing__list .list-wrap li {
  justify-content: center;
}
.pricing__head {
  margin-bottom: 20px;
}
.pricing__head .title {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  background: var(--tg-color-gray-3);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  padding: 12px 22px;
}
.pricing__price {
  margin-bottom: 40px;
}
.pricing__price .price {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0;
  font-size: 48px;
  align-items: flex-end;
  line-height: 1;
}
.pricing__price .price strong {
  font-weight: 400;
  font-size: 18px;
  margin: 4px 3px auto 0;
  color: var(--tg-border-5);
}
.pricing__price .price span {
  color: var(--tg-border-5);
  text-transform: lowercase;
  font-weight: 400;
  font-size: 18px;
  margin-left: 5px;
  margin-bottom: 7px;
  text-transform: capitalize;
}
.pricing__price .price.annual_price {
  display: none;
}
.pricing__price.change-subs-duration .monthly_price {
  display: none;
}
.pricing__price.change-subs-duration .annual_price {
  display: flex;
}
.pricing__content p {
  margin-bottom: 30px;
}
.pricing__list {
  margin-bottom: 30px;
}
.pricing__list .list-wrap li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  gap: 10px;
  margin-bottom: 15px;
}
.pricing__list .list-wrap li:last-child {
  margin-bottom: 0;
}
.pricing__shape-wrap img {
  position: absolute;
  z-index: -1;
}
.pricing__shape-wrap img:nth-child(1) {
  left: 0;
  top: 0;
}
@media (max-width: 767.98px) {
  .pricing__shape-wrap img:nth-child(1) {
    width: 200px;
  }
}
.pricing__shape-wrap img:nth-child(2) {
  right: 0;
  bottom: 0;
}
@media (max-width: 767.98px) {
  .pricing__shape-wrap img:nth-child(2) {
    width: 200px;
  }
}

/*=============================
	11. Request
===============================*/
.request-bg {
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 280px 0 220px;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 1500px) {
  .request-bg {
    padding: 200px 0 180px;
  }
}
@media (max-width: 1199.98px) {
  .request-bg {
    padding: 180px 0 160px;
  }
}
@media (max-width: 991.98px) {
  .request-bg {
    padding: 130px 0 130px;
  }
}
@media (max-width: 767.98px) {
  .request-bg {
    padding: 100px 0;
  }
}
.request-bg::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #0B0C39;
  opacity: 0.5;
  z-index: -1;
}
.request__area-two {
  padding: 45px 0;
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .request__area-two {
    padding: 30px 0;
  }
}
.request__area-three {
  padding: 120px 0;
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .request__area-three {
    padding: 100px 0;
  }
}
.request__bg-two {
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-attachment: fixed;
}
.request__bg-three {
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-attachment: fixed;
}
.request-content .title {
  font-size: 48px;
  font-weight: 800;
  color: var(--tg-color-white-default);
  margin-bottom: 30px;
}
@media (max-width: 1199.98px) {
  .request-content .title {
    font-size: 40px;
  }
}
@media (max-width: 767.98px) {
  .request-content .title {
    font-size: 36px;
  }
}
.request-content .btn {
  background: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
}
.request-content .btn::before {
  background: var(--tg-theme-primary);
}
.request-content .content-bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media (max-width: 767.98px) {
  .request-content .content-bottom {
    flex-wrap: wrap;
  }
}
.request-content .content-bottom .content-right {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
}
.request-content .content-bottom .content-right .icon {
  font-size: 36px;
  line-height: 0;
  color: var(--tg-theme-primary);
}
.request-content .content-bottom .content-right .content span {
  display: block;
  text-align: left;
  font-size: 14px;
  line-height: 0;
  color: var(--tg-color-white-default);
  margin-bottom: 8px;
}
.request-content .content-bottom .content-right .content a {
  color: var(--tg-color-white-default);
  font-size: 24px;
  font-weight: 600;
  font-family: var(--tg-heading-font-family);
}
.request-content .content-bottom .content-right .content a:hover {
  color: var(--tg-theme-primary);
}
.request__content-two {
  background: #405b79;
  padding: 60px 60px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
}
@media (max-width: 991.98px) {
  .request__content-two {
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .request__content-two {
    padding: 40px 35px;
  }
}
.request__content-two .title {
  color: var(--tg-color-white-default);
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 400;
  text-transform: capitalize;
}
@media (max-width: 767.98px) {
  .request__content-two .title {
    font-size: 32px;
  }
}
.request__content-two .title span {
  font-weight: 700;
}
.request__phone {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}
@media (max-width: 991.98px) {
  .request__phone {
    justify-content: center;
  }
}
.request__phone .icon {
  font-size: 36px;
  line-height: 0;
  color: var(--tg-theme-primary);
}
.request__phone .content span {
  line-height: 1;
  display: block;
  font-size: 14px;
  font-family: var(--tg-heading-font-family);
  color: #E0E0E8;
  text-align: left;
}
.request__phone .content a {
  color: var(--tg-color-white-default);
  font-size: 24px;
  font-weight: 600;
  font-family: var(--tg-heading-font-family);
  text-align: left;
}
@media (max-width: 767.98px) {
  .request__phone .content a {
    font-size: 22px;
  }
}
.request__phone .content a:hover {
  color: #E0E0E8;
}
.request-shape img {
  position: absolute;
  z-index: -1;
}
.request-shape img:nth-child(1) {
  left: 0;
  top: 0;
}
@media (max-width: 1199.98px) {
  .request-shape img:nth-child(1) {
    width: 300px;
  }
}
@media (max-width: 767.98px) {
  .request-shape img:nth-child(1) {
    width: 210px;
  }
}
.request-shape img:nth-child(2) {
  right: 0;
  bottom: 0;
}
@media (max-width: 1199.98px) {
  .request-shape img:nth-child(2) {
    width: 300px;
  }
}
@media (max-width: 767.98px) {
  .request-shape img:nth-child(2) {
    width: 190px;
  }
}
.request__shape-two img {
  position: absolute;
  z-index: -1;
}
.request__shape-two img:nth-child(1) {
  left: 0;
  top: 0;
}
@media (max-width: 991.98px) {
  .request__shape-two img:nth-child(1) {
    width: 200px;
  }
}
.request__shape-two img:nth-child(2) {
  right: 0;
  bottom: 0;
}
@media (max-width: 991.98px) {
  .request__shape-two img:nth-child(2) {
    width: 200px;
  }
}

/*=============================
    12. Consulting
===============================*/
.consulting-area {
  position: relative;
  z-index: 2;
}
.consulting-inner-wrap {
  background: var(--tg-theme-secondary);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.consulting-content {
  padding: 70px 65px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 24px;
}
@media (max-width: 1199.98px) {
  .consulting-content {
    padding: 50px 30px;
    gap: 20px;
  }
}
@media (max-width: 767.98px) {
  .consulting-content {
    flex-wrap: wrap;
    text-align: center;
  }
}
.consulting-content .content-left {
  background: var(--tg-theme-primary);
  text-align: center;
  padding: 20px 23px;
}
@media (max-width: 767.98px) {
  .consulting-content .content-left {
    margin: 0 auto;
  }
}
.consulting-content .content-left .title {
  font-size: 40px;
  margin-bottom: 5px;
  color: var(--tg-color-white-default);
  line-height: 1;
}
.consulting-content .content-left span {
  font-size: 15px;
  font-weight: 500;
  display: block;
  line-height: 1.2;
  color: var(--tg-color-white-default);
  font-family: var(--tg-heading-font-family);
}
.consulting-content .content-right .title {
  margin-bottom: 15px;
  font-size: 30px;
  color: var(--tg-color-white-default);
}
.consulting-content .content-right p {
  margin-bottom: 0;
  color: var(--tg-color-white-default);
}
.consulting-img {
  width: 392px;
  flex: 0 0 auto;
}
@media (max-width: 991.98px) {
  .consulting-img {
    display: none;
  }
}
.consulting-img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  -webkit-border-radius: 0 15px 15px 0;
  -moz-border-radius: 0 15px 15px 0;
  -o-border-radius: 0 15px 15px 0;
  -ms-border-radius: 0 15px 15px 0;
  border-radius: 0 15px 15px 0;
}
.consulting-shape img {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.card-testimonials {
  background: #fff;
  padding:10px;
  border-radius: 5px;
  margin-bottom: 20px;

}

.top_slide_mT {
  margin-top: 90px;
}

.rates-review img {
  width: 20px !important;
}
/*.top_slide_2 {
  border: 1px solid red;
}

.top_slide_3 {
  border: 1px solid black;
}*/


/*=============================
    13. Testimonial
===============================*/
.testimonial-area {
  background: #FFFBF3;
  padding: 250px 0 0;
  position: relative;
  z-index: 1;
  margin-top: -130px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .testimonial-area {
    padding: 230px 0 0;
  }
}
.testimonial__area-two {
  background: #ecf6fa;
  padding: 40px 0 30px;
  position: relative;
  /*z-index: 1;
  overflow: hidden;*/
}
@media (max-width: 767.98px) {
  .testimonial__area-two {
    padding: 30px 0 20px;
  }
}
.testimonial__area-three {
  background: var(--tg-heading-color);
  padding: 120px 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .testimonial__area-three {
    padding: 100px 0;
  }
}
.testimonial__bg {
  background-size: cover;
  background-position: center;
  padding: 0 0 120px;
}
@media (max-width: 767.98px) {
  .testimonial__bg {
    padding: 0 0 100px;
  }
}
.testimonial__bg-two {
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .testimonial__bg-two {
    padding: 100px 0;
  }
}
.testimonial-img-wrap {
  position: relative;
  z-index: 1;
  text-align: center;
}
@media (max-width: 991.98px) {
  .testimonial-img-wrap {
    margin-top: 50px;
  }
}
.testimonial-img-wrap .img-shape img {
  position: absolute;
  z-index: -1;
}
.testimonial-img-wrap .img-shape img:nth-child(1) {
  left: 0;
  bottom: 0;
}
.testimonial-img-wrap .img-shape img:nth-child(2) {
  left: 10%;
  top: 18%;
}
@media (max-width: 1199.98px) {
  .testimonial-img-wrap .img-shape img:nth-child(2) {
    left: 5%;
  }
}
@media (max-width: 767.98px) {
  .testimonial-img-wrap .img-shape img:nth-child(2) {
    left: 3%;
    width: 70px;
    top: 10%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-img-wrap .img-shape img:nth-child(2) {
    left: 5%;
    width: 90px;
    top: 13%;
  }
}
.testimonial-img-wrap .img-shape img:nth-child(3) {
  left: 14%;
  bottom: 19%;
}
@media (max-width: 1199.98px) {
  .testimonial-img-wrap .img-shape img:nth-child(3) {
    left: 3%;
    bottom: 30%;
  }
}
@media (max-width: 767.98px) {
  .testimonial-img-wrap .img-shape img:nth-child(3) {
    display: none;
  }
}
.testimonial-img-wrap .img-shape img:nth-child(4) {
  right: 0%;
  bottom: 21%;
  animation-duration: 5s;
}
@media (max-width: 767.98px) {
  .testimonial-img-wrap .img-shape img:nth-child(4) {
    display: none;
  }
}
.testimonial__img-wrap-two {
  position: relative;
  text-align: center;
}
@media (max-width: 991.98px) {
  .testimonial__img-wrap-two {
    margin-bottom: 50px;
  }
}
.testimonial__img-shape-two img {
  position: absolute;
}
.testimonial__img-shape-two img:nth-child(1) {
  left: 0;
  top: 40%;
}
@media (max-width: 1199.98px) {
  .testimonial__img-shape-two img:nth-child(1) {
    left: -2%;
    width: 100px;
  }
}
@media (max-width: 767.98px) {
  .testimonial__img-shape-two img:nth-child(1) {
    display: none;
  }
}
.testimonial__img-shape-two img:nth-child(2) {
  left: 16%;
  bottom: 1%;
}
@media (max-width: 767.98px) {
  .testimonial__img-shape-two img:nth-child(2) {
    left: 9%;
    bottom: -3%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial__img-shape-two img:nth-child(2) {
    left: 16%;
    bottom: 1%;
  }
}
.testimonial__img-shape-two img:nth-child(3) {
  right: 13%;
  top: 32%;
}
@media (max-width: 1199.98px) {
  .testimonial__img-shape-two img:nth-child(3) {
    right: 9%;
  }
}
@media (max-width: 767.98px) {
  .testimonial__img-shape-two img:nth-child(3) {
    top: 28%;
    right: 3%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial__img-shape-two img:nth-child(3) {
    right: 10%;
  }
}
.testimonial-info {
  margin-bottom: 20px;
}
.testimonial-info .title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
}
.testimonial-info span {
  display: block;
  font-family: var(--tg-heading-font-family);
  line-height: 1;
}
.testimonial__info-two .title {
  margin-bottom: 5px;
  color: var(--tg-color-white-default);
  font-size: 20px;
}
.testimonial__info-two span {
  font-size: 15px;
  display: block;
  font-family: var(--tg-heading-font-family);
  color: #9597C8;
  margin-bottom: 12px;
}
.testimonial__info-three {
  position: relative;
  padding-left: 20px;
}
.testimonial__info-three::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 4px;
  height: 36px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background: var(--tg-theme-secondary);
}
.testimonial__info-three .title {
  margin-bottom: 8px;
  font-size: 22px;
  color: var(--tg-theme-secondary);
}
.testimonial__info-three span {
  display: block;
  line-height: 1;
}
.testimonial__item-two {
  background: #1F227B;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 40px 35px 70px;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .testimonial__item-two {
    padding: 40px 20px 70px;
  }
}
@media (max-width: 991.98px) {
  .testimonial__item-two {
    padding: 40px 25px 70px;
  }
}
.testimonial__item-two .testimonial__rating {
  justify-content: center;
}
.testimonial__item-two p {
  margin-bottom: 0;
  color: var(--tg-color-gray-2);
  text-transform: capitalize;
}
.testimonial__item-two .icon {
  position: absolute;
  right: 25px;
  bottom: -8px;
  z-index: -1;
}
.testimonial__item-three p {
  font-size: 20px;
  font-weight: 500;
  font-style: italic;
  margin-bottom: 40px;
  text-transform: capitalize;
}
.testimonial__item-four .testimonial-info .title {
  color: var(--tg-color-white-default);
}
.testimonial__item-four .testimonial-info span {
  color: var(--tg-border-5);
}
.testimonial__item-four .testimonial-content p {
  color: var(--tg-color-gray-2);
}
.testimonial__item-wrap {
  margin-left: 50px;
}
@media (max-width: 1199.98px) {
  .testimonial__item-wrap {
    margin-left: 0;
  }
}
.testimonial__rating {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 18px;
  color: var(--tg-theme-primary);
  margin-bottom: 20px;
}
.testimonial__rating i:last-child {
  color: #DED9CE;
}
.testimonial__rating-two {
  margin-bottom: 30px;
}
.testimonial__avatar {
  border: 2px solid var(--tg-color-white-default);
  width: 82px;
  margin: 0 auto 15px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}
.testimonial__avatar img {
  width: 100%;
  object-fit: cover;
}
.testimonial-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 1199.98px) {
  .testimonial-content {
    gap: 20px;
  }
}
.testimonial-content p {
  margin-bottom: 0;
  font-size: 17px;
  font-style: italic;
  line-height: 1.58;
  text-transform: capitalize;
}
.testimonial-content .icon {
  width: 80px;
  height: 80px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-primary);
  flex: 0 0 auto;
  -webkit-border-radius: 40px 0 0 0;
  -moz-border-radius: 40px 0 0 0;
  -o-border-radius: 40px 0 0 0;
  -ms-border-radius: 40px 0 0 0;
  border-radius: 40px 0 0 0;
  font-size: 40px;
  line-height: 0;
  color: var(--tg-color-white-default);
}
@media (max-width: 767.98px) {
  .testimonial-content .icon {
    display: none;
  }
}
.testimonial-nav .swiper-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 35px;
}
.testimonial-nav .swiper-wrapper button {
  border: none;
  background: transparent;
  padding: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  width: 65px;
}
.testimonial-nav .swiper-wrapper button img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  width: 100%;
  filter: grayscale(1);
}
.testimonial-nav .swiper-wrapper .swiper-slide {
  width: auto !important;
}
.testimonial-nav .swiper-slide-thumb-active button img {
  filter: grayscale(0);
}
.testimonial__nav-two {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}
.testimonial__nav-two .testimonial-button-prev {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: var(--tg-color-white-default);
  border: 1.5px solid #9597C8;
  color: var(--tg-theme-secondary);
  line-height: 0;
  font-size: 24px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.testimonial__nav-two .testimonial-button-prev:hover {
  background: var(--tg-theme-secondary);
  border-color: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
}
.testimonial__nav-two .testimonial-button-next {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: var(--tg-color-white-default);
  border: 1.5px solid #9597C8;
  color: var(--tg-theme-secondary);
  line-height: 0;
  font-size: 24px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.testimonial__nav-two .testimonial-button-next:hover {
  background: var(--tg-theme-secondary);
  border-color: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
}
.testimonial__nav-three .swiper-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 35px;
}
.testimonial__nav-three .swiper-wrapper button {
  border: none;
  background: transparent;
  padding: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  width: 65px;
}
.testimonial__nav-three .swiper-wrapper button img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  width: 100%;
  filter: grayscale(1);
}
.testimonial__nav-three .swiper-wrapper .swiper-slide {
  width: auto !important;
}
.testimonial__nav-three .swiper-slide-thumb-active button img {
  filter: grayscale(0);
}
.testimonial__nav-four {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
}
.testimonial__nav-four .testimonial-two-button-prev {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-color-gray-3);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-size: 24px;
  line-height: 0;
  color: var(--tg-theme-secondary);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.testimonial__nav-four .testimonial-two-button-prev:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
}
.testimonial__nav-four .testimonial-two-button-next {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-color-gray-3);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-size: 24px;
  line-height: 0;
  color: var(--tg-theme-secondary);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.testimonial__nav-four .testimonial-two-button-next:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
}
.testimonial__inner-top {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.testimonial__inner-top .icon {
  width: 80px;
  height: 80px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-primary);
  flex: 0 0 auto;
  -webkit-border-radius: 40px 0 0 0;
  -moz-border-radius: 40px 0 0 0;
  -o-border-radius: 40px 0 0 0;
  -ms-border-radius: 40px 0 0 0;
  border-radius: 40px 0 0 0;
  font-size: 40px;
  line-height: 0;
  color: var(--tg-color-white-default);
}
@media (max-width: 767.98px) {
  .testimonial__inner-top .icon {
    display: none;
  }
}
.testimonial__bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.testimonial__form {
  background: var(--tg-color-white-default);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  padding: 40px 45px 45px;
  width: 400px;
  margin: 0 auto;
}
@media (max-width: 1199.98px) {
  .testimonial__form {
    padding: 40px 40px 45px;
  }
}
@media (max-width: 991.98px) {
  .testimonial__form {
    margin-bottom: 50px;
  }
}
@media (max-width: 767.98px) {
  .testimonial__form {
    padding: 30px 25px 30px;
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial__form {
    padding: 40px;
  }
}
.testimonial__form .title {
  margin-bottom: 25px;
  font-size: 30px;
  text-transform: capitalize;
}
.testimonial__form .form-grp {
  margin-bottom: 10px;
}
.testimonial__form .form-grp input {
  width: 100%;
  background: transparent;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #D0D6E1;
  font-size: 14px;
  font-weight: 500;
  color: var(--tg-body-color);
  padding: 15px 20px;
  height: 50px;
}
.testimonial__form .form-grp input::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: #667594;
}
.testimonial__form .form-grp.select-grp {
  position: relative;
}
.testimonial__form .form-grp.select-grp select {
  background-color: transparent;
  border: 1px solid #D0D6E1;
  color: #667594;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 15px 40px 15px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  line-height: 1.2;
  height: 50px;
  cursor: pointer;
}
.testimonial__form .form-grp.select-grp::after {
  content: "\f106";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: flaticon;
  font-weight: 400;
  right: 10px;
  font-size: 24px;
  color: var(--tg-theme-secondary);
}
.testimonial-shape-wrap img {
  position: absolute;
  z-index: -1;
}
.testimonial-shape-wrap img:nth-child(1) {
  bottom: 0;
  right: 10%;
}
.testimonial-shape-wrap img:nth-child(2) {
  right: 0;
  top: 0;
}
.testimonial__shape-two img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.testimonial__shape-three img {
  position: absolute;
  z-index: -1;
  right: 15%;
  top: -15%;
  animation-duration: 30s;
}
@media (max-width: 1500px) {
  .testimonial__shape-three img {
    right: 1%;
  }
}
@media (max-width: 991.98px) {
  .testimonial__shape-three img {
    top: auto;
    bottom: -10%;
  }
}
@media (max-width: 767.98px) {
  .testimonial__shape-three img {
    bottom: 0%;
  }
}

.range-slider-wrap {
  margin-bottom: 20px;
}
.range-slider-wrap input {
  width: 100%;
  height: 15px;
  appearance: none;
  -webkit-appearance: none;
  background: #D9D9D9;
  outline: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.range-slider-wrap .range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: var(--tg-theme-primary);
  cursor: pointer;
  border: none;
  box-shadow: -807px 0 0 800px var(--tg-heading-color);
}
.range-top {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 20px;
}
.range-top p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-heading-color);
}
.range-top span {
  font-size: 16px;
  font-weight: 600;
  display: block;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-heading-color);
}
.range-top span strong {
  font-weight: 600;
}

/*=============================
	14. Features
===============================*/
.features__area-two {
  padding: 120px 0 90px;
}
@media (max-width: 767.98px) {
  .features__area-two {
    padding: 100px 0 70px;
  }
}
.features__item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 80px 50px;
  border: 1px solid var(--tg-border-2);
}
@media (max-width: 1800px) {
  .features__item {
    padding: 80px 30px;
  }
}
@media (max-width: 1500px) {
  .features__item {
    padding: 60px 20px;
  }
}
@media (max-width: 1199.98px) {
  .features__item {
    display: block;
  }
}
@media (max-width: 991.98px) {
  .features__item {
    padding: 40px 30px;
  }
}
@media (max-width: 767.98px) {
  .features__item {
    padding: 40px 25px;
  }
}
.features__item:hover .features__icon i {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.features__item-wrap {
  border-bottom: 1px solid var(--tg-border-2);
}
.features__item-wrap .row {
  --bs-gutter-x: 0px;
}
.features__item-two {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #E4ECEF;
  background: var(--tg-color-white-default);
  -webkit-box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  padding: 30px 30px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
@media (max-width: 1199.98px) {
  .features__item-two {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .features__item-two {
    flex-wrap: nowrap;
  }
}
.features__item-two:hover .features__icon-two i {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.features__icon {
  width: 80px;
  height: 80px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-color-gray-3);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  flex: 0 0 auto;
  font-size: 50px;
  line-height: 0;
  color: var(--tg-theme-secondary);
}
@media (max-width: 1199.98px) {
  .features__icon {
    margin-bottom: 20px;
  }
}
.features__icon i {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
}
.features__icon-two {
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  background: var(--tg-color-yellow-light);
  line-height: 0;
  color: var(--tg-theme-primary);
  font-size: 50px;
}
.features__icon-two i {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
}
.features__content .title {
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 1500px) {
  .features__content .title {
    font-size: 22px;
  }
}
.features__content p {
  margin-bottom: 0;
}
@media (max-width: 1500px) {
  .features__content p br {
    display: none;
  }
}
.features__content-two .title {
  margin-bottom: 10px;
  font-size: 20px;
  text-transform: capitalize;
}
.features__content-two p {
  margin-bottom: 0;
}

/*=============================
	15. Marquee
===============================*/
.marquee__area {
  background: var(--tg-color-yellow-light-2);
  overflow: hidden;
  padding: 0 0 120px;
}
@media (max-width: 767.98px) {
  .marquee__area {
    padding: 0 0 100px;
  }
}
.marquee__area-two {
  padding: 0 0 120px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .marquee__area-two {
    padding: 0 0 100px;
  }
}
.marquee__wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.marquee__wrap:hover .marquee__box {
  animation-play-state: paused;
}
.marquee__box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: fit-content;
  animation: marquee 50s linear infinite;
}
.marquee__box a {
  font-weight: 700;
  font-size: 60px;
  white-space: nowrap;
  line-height: 1;
  position: relative;
  margin-right: 20px;
  text-transform: uppercase;
  font-family: var(--tg-heading-font-family);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  color: var(--tg-theme-primary);
  letter-spacing: 1px;
}
@media (max-width: 1199.98px) {
  .marquee__box a {
    font-size: 55px;
  }
}
@media (max-width: 991.98px) {
  .marquee__box a {
    font-size: 50px;
  }
}
@media (max-width: 767.98px) {
  .marquee__box a {
    font-size: 40px;
  }
}
.marquee__box a::after {
  content: "\f621";
  font-size: 45px;
  font-weight: 700;
  line-height: 1;
  margin-left: 20px;
  font-family: var(--tg-icon-font-family);
  color: var(--tg-theme-primary);
}
@media (max-width: 1199.98px) {
  .marquee__box a::after {
    font-size: 40px;
  }
}
@media (max-width: 767.98px) {
  .marquee__box a::after {
    font-size: 30px;
  }
}

/*=============================
    16. Fact
===============================*/
.counter-area {
  background: #FFFBF3;
  padding: 110px 0 80px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .counter-area {
    padding: 100px 0 70px;
  }
}
.counter-area-two {
  padding: 50px 0 30px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .counter-area-two {
    padding: 30px;
  }
}
.counter-area-three {
  background: #F5FAFC;
  padding: 0 0 90px;
}
.counter-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  margin-left: 75px;
}
@media (max-width: 767.98px) {
  .counter-item {
    gap: 12px;
  }
}
.counter-item .icon {
  font-size: 72px;
  line-height: 0;
  color: var(--tg-theme-primary);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-item .icon {
    font-size: 60px;
  }
}
.counter-item .content .count {
  line-height: 0.8;
  margin-bottom: 5px;
  font-size: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font-weight: 800;
}
@media (max-width: 1199.98px) {
  .counter-item .content .count {
    font-size: 36px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-item .content .count {
    font-size: 34px;
  }
}
.counter-item .content p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-item .content p {
    font-size: 16px;
  }
}
.counter-item:hover .icon {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.counter-shape-wrap img {
  position: absolute;
  z-index: -1;
}
.counter-shape-wrap img:nth-child(1) {
  left: 0;
  top: 0;
}
.counter-shape-wrap img:nth-child(2) {
  right: 8%;
  top: 30%;
}
.counter-shape-wrap img:nth-child(3) {
  right: 0;
  bottom: 0;
  z-index: -2;
}
.counter-shape-two img {
  position: absolute;
  right: 8%;
  bottom: -20px;
}

/*=============================
    17. Team
===============================*/
@media (max-width: 767.98px) {
  .team-area {
    padding: 100px 0 70px;
  }
}
.team__area-two {
  position: relative;
  padding: 120px 0 90px;
}
@media (max-width: 767.98px) {
  .team__area-two {
    padding: 100px 0 70px;
  }
}
.team__area-three {
  padding: 120px 0 90px;
}
@media (max-width: 767.98px) {
  .team__area-three {
    padding: 100px 0 70px;
  }
}
.team__area-four {
  padding: 120px 0 90px;
}
@media (max-width: 767.98px) {
  .team__area-four {
    padding: 100px 0 70px;
  }
}
.team-item {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  border: 1px solid var(--tg-border-1);
  margin-bottom: 30px;
}
.team-item-wrap .row {
  --bs-gutter-x: 24px;
}
.team__item-two {
  margin-bottom: 30px;
}
.team__item-three {
  position: relative;
  margin-bottom: 30px;
}
.team__item-four {
  margin-bottom: 30px;
}
.team-thumb {
  -webkit-border-radius: 14px 14px 0 104px;
  -moz-border-radius: 14px 14px 0 104px;
  -o-border-radius: 14px 14px 0 104px;
  -ms-border-radius: 14px 14px 0 104px;
  border-radius: 14px 14px 0 104px;
  overflow: hidden;
  position: relative;
}
.team-thumb img {
  width: 100%;
  height: 292px;
  object-fit: cover;
}
@media (max-width: 767.98px) {
  .team-thumb img {
    height: auto;
  }
}
.team__thumb-two {
  margin-bottom: 15px;
}
.team__thumb-two img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  width: 100%;
  height: 295px;
  object-fit: cover;
}
@media (max-width: 1199.98px) {
  .team__thumb-two img {
    height: 230px;
  }
}
@media (max-width: 991.98px) {
  .team__thumb-two img {
    height: 295px;
  }
}
@media (max-width: 767.98px) {
  .team__thumb-two img {
    height: auto;
  }
}
.team__thumb-three img {
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -o-border-radius: 14px;
  -ms-border-radius: 14px;
  border-radius: 14px;
}
.team__thumb-four img {
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -o-border-radius: 14px;
  -ms-border-radius: 14px;
  border-radius: 14px;
  width: 100%;
}
.team-social {
  position: absolute;
  right: 22px;
  top: 22px;
  background: var(--tg-color-white-default);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}
.team-social .social-toggle-icon {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: #474AA0;
  font-size: 20px;
  line-height: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.team-social .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 0 0 15px;
  display: none;
  text-align: center;
}
.team-social .list-wrap a {
  font-size: 18px;
  color: #474AA0;
}
.team-social .list-wrap a:hover {
  color: var(--tg-theme-primary);
}
.team__social-two .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.team__social-two .list-wrap li a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid var(--tg-border-3);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-size: 18px;
  color: var(--tg-border-3);
}
.team__social-two .list-wrap li a:hover {
  color: var(--tg-color-white-default);
  background: var(--tg-theme-primary);
  border-color: var(--tg-theme-primary);
}
.team__social-three {
  top: auto;
  bottom: 30px;
  right: 30px;
  z-index: 1;
  background: var(--tg-theme-primary);
}
.team__social-three .social-toggle-icon {
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
}
.team__social-three .list-wrap {
  padding: 15px 0 0;
}
.team__social-three .list-wrap li a {
  color: var(--tg-color-white-default);
}
.team__social-three .list-wrap li a:hover {
  color: var(--tg-theme-secondary);
}
.team__social-four .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  gap: 15px;
}
.team__social-four .list-wrap li a {
  color: #9597C8;
}
.team__social-four .list-wrap li a:hover {
  color: var(--tg-theme-primary);
}
.team-content {
  padding: 15px 25px 25px;
}
.team-content .title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
}
.team-content span {
  display: block;
  line-height: 1;
  color: var(--tg-theme-primary);
}
.team__content-two {
  text-align: center;
}
.team__content-two .title {
  font-size: 24px;
  font-weight: var(--tg-fw-semi-bold);
  margin-bottom: 8px;
}
.team__content-two span {
  display: block;
  line-height: 1;
  color: var(--tg-theme-primary);
  margin-bottom: 20px;
}
.team__content-three {
  position: absolute;
  left: 30px;
  bottom: 30px;
  right: 30px;
  z-index: 1;
  text-align: center;
}
.team__content-three .title {
  margin-bottom: 5px;
  color: var(--tg-color-white-default);
  font-size: 24px;
  font-weight: 600;
}
.team__content-three span {
  display: block;
  color: var(--tg-color-gray-3);
}
/* Disable the content for the swiper navigation buttons */
.swiper-button-prev::after, 
.swiper-button-next::after {
  content: none; /* Disable the pseudo-element content */
}
.footer-widget1{
  background: #215d72;
padding: 20px;
border-radius: 5px;
font-size: 16px;
display: flex;
align-items: center; 
}
.footer_icon {
  font-size: 45px !important;
  margin-right: 20px;
  color: #e2f1ff;

}
.footer_social_icon li a{
    padding: 5px 10px;
    margin-top: 25px;
    display: block;
    font-size: 20px;
    text-align: center;
}
.fa-angle-right{
  font-size: 30px
}
.fa-angle-left{
  font-size: 30px
}
.swiper-button-prev, .swiper-button-next{
  top: 40% !important;
}
.team__content-four {
  background: var(--tg-color-white-default);
  -webkit-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.04);
  -ms-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.04);
  -o-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.04);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
  margin: 0 24px;
  padding: 20px 20px;
  margin-top: -65px;
  position: relative;
  z-index: 1;
}
.team__content-four .title {
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: 600;
}
.team__content-four span {
  display: block;
  color: var(--tg-theme-primary);
  line-height: 1;
  margin-bottom: 10px;
}
.team__shape img {
  position: absolute;
  z-index: -1;
}
.team__shape img:nth-child(1) {
  left: 5%;
  bottom: 15%;
}
@media (max-width: 1500px) {
  .team__shape img:nth-child(1) {
    left: -5%;
  }
}
@media (max-width: 1199.98px) {
  .team__shape img:nth-child(1) {
    display: none;
  }
}
.team__shape img:nth-child(2) {
  right: 6%;
  top: 12%;
}
@media (max-width: 991.98px) {
  .team__shape img:nth-child(2) {
    top: 5%;
    width: 90px;
  }
}
@media (max-width: 767.98px) {
  .team__shape img:nth-child(2) {
    top: 2%;
    width: 80px;
  }
}
.team__details-area {
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .team__details-area {
    padding: 100px 0;
  }
}
.team__details-inner .row .col-36 {
  width: 35.6%;
  flex: 0 0 auto;
}
@media (max-width: 991.98px) {
  .team__details-inner .row .col-36 {
    width: 60%;
  }
}
@media (max-width: 767.98px) {
  .team__details-inner .row .col-36 {
    width: 100%;
  }
}
.team__details-inner .row .col-64 {
  width: 64.4%;
  flex: 0 0 auto;
}
@media (max-width: 991.98px) {
  .team__details-inner .row .col-64 {
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .team__details-img {
    margin-bottom: 50px;
  }
}
.team__details-img img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
@media (max-width: 991.98px) {
  .team__details-img img {
    width: 100%;
  }
}
.team__details-content {
  margin-left: 20px;
}
@media (max-width: 1199.98px) {
  .team__details-content {
    margin: 0;
  }
}
.team__details-content .title {
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 600;
}
@media (max-width: 1199.98px) {
  .team__details-content .title {
    font-size: 36px;
  }
}
@media (max-width: 767.98px) {
  .team__details-content .title {
    font-size: 32px;
  }
}
.team__details-content .position {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  color: var(--tg-theme-primary);
  margin-bottom: 20px;
}
.team__details-content p {
  margin-bottom: 50px;
}
@media (max-width: 1199.98px) {
  .team__details-content p {
    margin-bottom: 25px;
  }
}
.team__details-info > .list-wrap > li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-theme-secondary);
  gap: 10px;
  margin-bottom: 15px;
}
.team__details-info > .list-wrap > li > a {
  font-family: var(--tg-heading-font-family);
  color: var(--tg-theme-secondary);
}
.team__details-info > .list-wrap > li > a:hover {
  color: var(--tg-theme-primary);
}
.team__details-info > .list-wrap > li > i {
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  background: var(--tg-color-gray-3);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: var(--tg-theme-secondary);
  font-size: 18px;
  flex: 0 0 auto;
}
.team__details-info > .list-wrap > li:last-child {
  margin-bottom: 0;
}
.team__details-social {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 15px;
  align-items: center;
}
.team__details-social li a {
  color: var(--tg-theme-secondary);
  font-size: 16px;
}
.team__details-social li a:hover {
  color: var(--tg-theme-primary);
}

@media (max-width: 991.98px) {
  .section-content {
    margin-bottom: 50px;
  }
}
.section-content p {
  margin-bottom: 0;
  font-weight: 500;
}

.team-item, .card-team-area-six {
  transition: 0.3s all;
}

.team-item:hover, .card-team-area-six:hover {
  transform: translateY(-3px);
  transition: 0.3s all;
}

.mt-10 {
  margin-top: 10px;
}

/*=============================
    18. Call-back
===============================*/
.call-back-area {
  background: var(--tg-heading-color);
  padding: 100px 0 85px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.call-back-area-two {
  background: transparent;
  padding: 0;
  z-index: 2;
}
.call-back-wrap {
  background: var(--tg-heading-color);
  padding: 90px 80px 75px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
}
@media (max-width: 1199.98px) {
  .call-back-wrap {
    padding: 50px 25px 50px;
  }
}
@media (max-width: 991.98px) {
  .call-back-content {
    text-align: center;
    margin-bottom: 30px;
  }
}
.call-back-content .shape {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
@media (max-width: 991.98px) {
  .call-back-content .shape {
    display: none;
  }
}
.call-back-content p {
  margin-bottom: 0;
  color: #D0D0DD;
  font-weight: 500;
  width: 90%;
}
@media (max-width: 1199.98px) {
  .call-back-content p {
    width: 100%;
  }
}
.call-back-form form .row {
  --bs-gutter-x: 15px;
}
.call-back-form form .form-grp {
  margin-bottom: 15px;
}
.call-back-form form .form-grp input {
  width: 100%;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  color: var(--tg-heading-color);
  background: var(--tg-color-white-default);
  font-size: 15px;
  font-weight: 500;
  padding: 15px 18px;
  height: 50px;
}
.call-back-form form .form-grp input::placeholder {
  font-size: 15px;
  font-weight: 500;
  color: #9597C8;
}
.call-back-form form .btn {
  width: 100%;
  justify-content: center;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  padding: 17px 24px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.call-back-form form .btn::before {
  background: var(--tg-color-gray-3);
}
.call-back-form form .btn::after {
  display: none;
}
.call-back-form form .btn:hover {
  color: var(--tg-heading-color);
}

/*=============================
    19. Blog
===============================*/
.blog__area {
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .blog__area {
    padding: 100px 0;
  }
}
.blog-post-bg {
  background-size: cover;
  background-position: center;
  padding: 120px 0 90px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .blog-post-bg {
    padding: 100px 0 70px;
  }
}
.blog__post-bg-two {
  background-size: cover;
  background-position: center;
  padding: 35px 0 25px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .blog__post-bg-two {
    padding: 30px 0 20px;
  }
}
.blog__post-area-three {
  padding: 270px 0 90px;
  margin-top: -150px;
}
@media (max-width: 767.98px) {
  .blog__post-area-three {
    padding: 250px 0 70px;
  }
}
.blog__post-area-four {
  padding: 120px 0 90px;
}
@media (max-width: 767.98px) {
  .blog__post-area-four {
    padding: 100px 0 70px;
  }
}
.blog__post-area-five {
  padding: 120px 0 90px;
  background: var(--tg-color-yellow-light-2);
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .blog__post-area-five {
    padding: 100px 0 70px;
  }
}
.blog-post-item {
  border: 1px solid var(--tg-border-1);
  background: var(--tg-color-white-default);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 30px;
}
@media (max-width: 767.98px) {
  .blog-post-item {
    padding: 20px 20px;
  }
}
.blog-post-thumb {
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}
.blog-post-thumb img {
  width: 100%;
  height: 248px;
  object-fit: cover;
}
.blog-post-thumb .post-tag {
  font-size: 14px;
  font-weight: 500;
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
  line-height: 1;
  display: block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
  left: 18px;
  top: 16px;
  z-index: 2;
  padding: 8px 10px;
}
.blog-post-thumb .post-tag:hover {
  background: var(--tg-theme-secondary);
}
.blog-post-content .title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  text-transform: capitalize;
}
@media (max-width: 767.98px) {
  .blog-post-content .title {
    font-size: 22px;
  }
}
.blog-post-content .blog-post-meta .list-wrap {
  justify-content: space-between;
}
.blog__post-two {
  border: 1px solid var(--tg-border-1);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 5px;
  background: var(--tg-color-white-default);
  margin-bottom: 30px;
}
.blog__post-four {
  background: var(--tg-color-white-default);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  border: 1px solid #EAE4D8;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
}
.blog__post-thumb-two {
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -o-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  margin: -1px;
}
.blog__post-thumb-two img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.blog__post-thumb-three {
  margin-bottom: 35px;
}
.blog__post-thumb-four {
  margin: -1px -1px 0 -1px;
}
.blog__post-thumb-four img {
  -webkit-border-radius: 15px 15px 0 0;
  -moz-border-radius: 15px 15px 0 0;
  -o-border-radius: 15px 15px 0 0;
  -ms-border-radius: 15px 15px 0 0;
  border-radius: 15px 15px 0 0;
  width: 100%;
  height: 270px;
  object-fit: cover;
}
.blog__post-content-two {
  padding: 20px 20px;
}
@media (max-width: 1199.98px) {
  .blog__post-content-two {
    padding: 30px 22px;
  }
}
@media (max-width: 991.98px) {
  .blog__post-content-two {
    padding: 30px 30px;
  }
}
@media (max-width: 767.98px) {
  .blog__post-content-two {
    padding: 25px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog__post-content-two {
    padding: 30px 30px;
  }
}
.blog__post-content-two .title {
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: normal;
  text-transform: capitalize;
}
@media (max-width: 767.98px) {
  .blog__post-content-two .title {
    font-size: 22px;
  }
}
.blog__post-content-two .blog-post-meta {
  margin-bottom: 10px;
}
.blog__post-content-two .blog-avatar {
  margin-bottom: 0;
}
.blog__post-content-three {
  position: relative;
}
.blog__post-content-three .post-tag {
  font-size: 14px;
  font-weight: 500;
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
  line-height: 1;
  display: block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
  left: 18px;
  top: -51px;
  z-index: 2;
  padding: 8px 10px;
}
.blog__post-content-three .post-tag:hover {
  background: var(--tg-theme-secondary);
}
.blog__post-content-three .btn {
  background: var(--tg-color-gray-3);
  color: var(--tg-theme-secondary);
  padding: 16px 24px;
}
.blog__post-content-three .btn:hover {
  color: var(--tg-color-white-default);
}
.blog__post-content-four {
  padding: 25px 30px 40px;
}
@media (max-width: 1199.98px) {
  .blog__post-content-four {
    padding: 25px 25px 30px;
  }
}
.blog__post-content-four .title {
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.blog__post-tag-two {
  font-size: 14px;
  font-weight: 500;
  color: var(--tg-body-color);
  border: 1px solid var(--tg-color-gray-2);
  display: inline-block;
  line-height: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  padding: 7px 10px;
}
.blog__post-tag-two:hover {
  border-color: var(--tg-theme-primary);
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
}
.blog__post-tag-three {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  color: #9798AB;
  line-height: 1;
  margin-bottom: 20px;
}
.blog-avatar {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
}
.blog-avatar .avatar-thumb {
  width: 36px;
  flex: 0 0 auto;
}
.blog-avatar .avatar-thumb img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  width: 100%;
  object-fit: cover;
}
.blog-avatar .avatar-content p {
  margin-bottom: 0;
  font-weight: 500;
  color: #9597C8;
}
.blog-avatar .avatar-content p a:hover {
  color: var(--tg-theme-secondary);
}
.blog-post-meta .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px 20px;
  flex-wrap: wrap;
}
.blog-post-meta .list-wrap li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 8px;
}
.blog-post-meta .list-wrap li i {
  font-size: 12px;
}
.blog-post-meta .list-wrap li span {
  font-size: 12px;
}
.blog-post-meta .btn {
  background: var(--tg-color-gray-3);
  color: var(--tg-theme-secondary);
  padding: 16px 20px;
}
.blog-post-meta .btn:hover {
  color: var(--tg-color-white-default);
}
.blog-post-meta-two .list-wrap li {
  color: #9798AB;
}
.blog-post-meta-two .list-wrap li i {
  color: var(--tg-theme-primary);
}
.blog-post-meta-two .list-wrap li a {
  color: #9798AB;
}
.blog-post-meta-two .list-wrap li a:hover {
  color: var(--tg-theme-primary);
}
.blog__inner-wrap .row .col-70 {
  width: 70.5%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .blog__inner-wrap .row .col-70 {
    width: 67.5%;
  }
}
@media (max-width: 991.98px) {
  .blog__inner-wrap .row .col-70 {
    width: 100%;
  }
}
.blog__inner-wrap .row .col-30 {
  width: 29.5%;
  flex: 0 0 auto;
}
@media (max-width: 1199.98px) {
  .blog__inner-wrap .row .col-30 {
    width: 32.5%;
  }
}
@media (max-width: 991.98px) {
  .blog__inner-wrap .row .col-30 {
    width: 100%;
  }
}
.blog__sidebar {
  margin-left: 20px;
}
@media (max-width: 1199.98px) {
  .blog__sidebar {
    margin-left: 0;
  }
}
@media (max-width: 991.98px) {
  .blog__sidebar {
    margin-top: 100px;
  }
}
.blog-shape-wrap img {
  position: absolute;
  z-index: -1;
}
.blog-shape-wrap img:nth-child(1) {
  left: 0;
  top: 0;
}
@media (max-width: 767.98px) {
  .blog-shape-wrap img:nth-child(1) {
    width: 200px;
  }
}
.blog-shape-wrap img:nth-child(2) {
  right: 0;
  bottom: 0;
}
@media (max-width: 767.98px) {
  .blog-shape-wrap img:nth-child(2) {
    width: 200px;
  }
}
.blog__details-area {
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .blog__details-area {
    padding: 100px 0;
  }
}
.blog__details-thumb {
  margin-bottom: 30px;
}
.blog__details-thumb img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  min-height: 250px;
  object-fit: cover;
}
.blog__details-content > .title {
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 600;
  text-transform: capitalize;
}
@media (max-width: 1199.98px) {
  .blog__details-content > .title {
    font-size: 32px;
  }
}
@media (max-width: 767.98px) {
  .blog__details-content > .title {
    font-size: 28px;
  }
}
.blog__details-content .blog-post-meta {
  margin-bottom: 30px;
}
.blog__details-content .blog-post-meta .blog-avatar {
  margin-bottom: 0;
}
.blog__details-content > p {
  margin-bottom: 20px;
}
.blog__details-content .title-two {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 600;
  text-transform: capitalize;
}
@media (max-width: 767.98px) {
  .blog__details-content .title-two {
    font-size: 26px;
  }
}
.blog__details-inner {
  margin: 35px 0;
}
.blog__details-inner .row .col-46 {
  width: 46%;
  flex: 0 0 auto;
}
@media (max-width: 767.98px) {
  .blog__details-inner .row .col-46 {
    width: 100%;
  }
}
.blog__details-inner .row .col-54 {
  width: 54%;
  flex: 0 0 auto;
}
@media (max-width: 767.98px) {
  .blog__details-inner .row .col-54 {
    width: 100%;
  }
}
.blog__details-inner-thumb {
  position: relative;
}
@media (max-width: 767.98px) {
  .blog__details-inner-thumb {
    margin-bottom: 30px;
  }
}
.blog__details-inner-thumb img {
  width: 100%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
.blog__details-inner-thumb .play-btn {
  width: 62px;
  height: 62px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: var(--tg-color-white-default);
  color: var(--tg-theme-secondary);
  font-size: 18px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.blog__details-inner-thumb .play-btn:hover {
  color: var(--tg-color-white-default);
  background: var(--tg-theme-primary);
}
.blog__details-inner-content .title {
  margin-bottom: 12px;
  font-size: 30px;
  font-weight: 600;
}
@media (max-width: 1199.98px) {
  .blog__details-inner-content .title {
    font-size: 26px;
  }
}
.blog__details-inner-content p {
  margin-bottom: 20px;
  width: 95%;
}
@media (max-width: 1199.98px) {
  .blog__details-inner-content p {
    width: 100%;
  }
}
.blog__details-bottom {
  border-top: 1px solid #E7E7E7;
  border-bottom: 1px solid #E7E7E7;
  padding: 20px 0;
  margin-top: 40px;
  margin-bottom: 70px;
}
.blog__details-bottom .post-tags {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.blog__details-bottom .post-tags .title {
  margin-bottom: 0;
  font-size: 14px;
}
.blog__details-bottom .post-tags .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  gap: 6px;
}
.blog__details-bottom .post-tags .list-wrap li a {
  background: var(--tg-color-gray-3);
  padding: 7px 17px;
  display: block;
  border-radius: 4px;
  color: var(--tg-heading-color);
  font-size: 15px;
  font-weight: 500;
}
.blog__details-bottom .post-tags .list-wrap li a:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
}
.blog__details-bottom .post-share {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  .blog__details-bottom .post-share {
    justify-content: flex-start;
    margin-top: 20px;
  }
}
.blog__details-bottom .post-share .title {
  margin-bottom: 0;
  font-size: 14px;
}
.blog__details-bottom .post-share .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
}
.blog__details-bottom .post-share .list-wrap li a {
  font-size: 20px;
  color: var(--tg-theme-secondary);
}
.blog__details-bottom .post-share .list-wrap li a:hover {
  color: var(--tg-theme-primary);
}
.blog__avatar-wrap {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  padding: 42px 40px;
  background: var(--tg-color-gray-3);
  gap: 30px;
}
@media (max-width: 1199.98px) {
  .blog__avatar-wrap {
    padding: 35px 25px;
  }
}
@media (max-width: 767.98px) {
  .blog__avatar-wrap {
    flex-wrap: wrap;
    gap: 20px;
    padding: 30px 25px;
  }
}
.blog__avatar-img {
  width: 115px;
  flex: 0 0 auto;
}
.blog__avatar-img img {
  width: 100%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.blog__avatar-info .designation {
  display: block;
  font-size: 15px;
  font-weight: 500;
  font-family: var(--tg-heading-font-family);
  line-height: 1;
  margin-bottom: 8px;
}
.blog__avatar-info .name {
  font-size: 20px;
  margin-bottom: 18px;
}
.blog__avatar-info p {
  margin-bottom: 0;
}

blockquote {
  margin: 50px 50px 60px;
  background: #F8FAFF;
  padding: 50px 100px 50px 50px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  blockquote {
    padding: 50px 100px 50px 40px;
    margin: 40px 0 50px;
  }
}
@media (max-width: 767.98px) {
  blockquote {
    padding: 40px 40px 40px 30px;
  }
}
blockquote::after {
  /* content: "\f10e"; */
  font-weight: 700;
  font-size: 52px;
  color: #E2E5F6;
  line-height: 1;
  /* font-family: var(--tg-icon-font-family); */
  position: absolute;
  right: 33px;
  top: 27px;
  z-index: -1;
}
@media (max-width: 767.98px) {
  blockquote::after {
    font-size: 40px;
    right: 20px;
    top: 15px;
  }
}
blockquote::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
  background: var(--tg-theme-primary);
}
blockquote p {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  font-family: var(--tg-heading-font-family);
  text-transform: capitalize;
  color: var(--tg-theme-secondary);
}
@media (max-width: 767.98px) {
  blockquote p {
    font-size: 18px;
  }
}

.play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid var(--tg-color-white-default);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
  -moz-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
  -ms-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
  -o-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
  transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
  animation: pulse-border 1500ms ease-out infinite;
}

.sidebar__widget {
  border: 1px solid var(--tg-border-6);
  padding: 26px 30px 30px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 40px;
}
@media (max-width: 1199.98px) {
  .sidebar__widget {
    padding: 26px 20px 30px;
  }
}
@media (max-width: 991.98px) {
  .sidebar__widget {
    padding: 26px 30px 30px;
  }
}
.sidebar__widget:last-child {
  margin-bottom: 0;
}
.sidebar__widget-search {
  border: none;
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}
.sidebar__search form {
  position: relative;
}
.sidebar__search form input {
  width: 100%;
  border: 1px solid var(--tg-border-6);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  font-size: 14px;
  color: var(--tg-theme-secondary);
  padding: 14px 60px 14px 20px;
  height: 50px;
}
.sidebar__search form input::placeholder {
  color: var(--tg-border-5);
  font-size: 14px;
}
.sidebar__search form button {
  border: none;
  position: absolute;
  right: 3px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-secondary);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.sidebar__search form button svg {
  width: 20px;
  color: var(--tg-color-white-default);
}
.sidebar__search form button:hover {
  background: var(--tg-theme-primary);
}
.sidebar__widget-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 22px;
  padding-bottom: 12px;
  position: relative;
}
.sidebar__widget-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background: var(--tg-theme-primary);
}
.sidebar__cat-list .list-wrap li {
  border-bottom: 1px solid var(--tg-border-6);
  padding-bottom: 17px;
  margin-bottom: 17px;
}
.sidebar__cat-list .list-wrap li a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: var(--tg-body-color);
  gap: 5px;
}
.sidebar__cat-list .list-wrap li a i {
  line-height: 0;
  font-size: 24px;
  color: var(--tg-border-5);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.sidebar__cat-list .list-wrap li a:hover {
  color: var(--tg-theme-primary);
}
.sidebar__cat-list .list-wrap li a:hover i {
  color: var(--tg-theme-primary);
}
.sidebar__cat-list .list-wrap li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.sidebar__post-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}
.sidebar__post-item:last-child {
  margin-bottom: 0;
}
.sidebar__post-thumb {
  width: 80px;
  flex: 0 0 auto;
}
.sidebar__post-thumb img {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.sidebar__post-content .title {
  font-size: 17px;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: capitalize;
}
.sidebar__post-content .date {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  color: #83849E;
  font-size: 15px;
  gap: 5px;
}
.sidebar__post-content .date i {
  line-height: 0;
}
.sidebar__tag-list .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
}
.sidebar__tag-list .list-wrap li a {
  font-size: 15px;
  background: var(--tg-color-gray-3);
  font-weight: 500;
  color: var(--tg-theme-secondary);
  padding: 7px 16px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  display: block;
}
.sidebar__tag-list .list-wrap li a:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
}

.comments-wrap-title {
  font-size: 24px;
  margin-bottom: 20px;
}
.comments-wrap .latest-comments {
  margin-bottom: 80px;
}
.comments-wrap .latest-comments .children {
  margin: 0 0 0 45px;
  padding: 0;
}
@media (max-width: 767.98px) {
  .comments-wrap .latest-comments .children {
    margin: 0;
  }
}
.comments-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #D6DCE9;
  padding-bottom: 30px;
  margin-bottom: 35px;
  gap: 25px;
}
@media (max-width: 767.98px) {
  .comments-box {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.comments-avatar {
  width: 115px;
  flex: 0 0 auto;
}
.comments-avatar img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.comments-text .avatar-name {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.comments-text .avatar-name .name {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}
.comments-text .avatar-name span {
  font-weight: 500;
  font-size: 15px;
  display: block;
  color: var(--tg-theme-primary);
}
.comments-text p {
  margin-bottom: 15px;
}
.comments-text .reply-btn {
  background: var(--tg-color-gray-3);
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  padding: 6px 17px;
  text-transform: uppercase;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  color: var(--tg-theme-secondary);
}
.comments-text .reply-btn:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
}

.comment-respond {
  border: 1px solid var(--tg-border-1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  padding: 35px 40px 50px;
  background: var(--tg-color-gray-3);
}
@media (max-width: 1199.98px) {
  .comment-respond {
    padding: 35px 25px 50px;
  }
}
.comment-reply-title {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .comment-reply-title {
    font-size: 26px;
  }
}
.comment-form .comment-notes {
  margin-bottom: 15px;
}
.comment-form .form-grp {
  margin-bottom: 15px;
}
.comment-form .form-grp textarea, .comment-form .form-grp input {
  width: 100%;
  border: 1px solid var(--tg-color-gray-1);
  background: var(--tg-color-white-default);
  font-weight: 400;
  font-size: 15px;
  color: var(--tg-body-color);
  padding: 12px 15px;
  height: 50px;
  display: block;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
}
.comment-form .form-grp textarea::placeholder, .comment-form .form-grp input::placeholder {
  font-weight: 400;
  font-size: 15px;
  color: var(--tg-color-gray-4);
}
.comment-form .form-grp textarea {
  min-height: 130px;
  max-height: 130px;
}
.comment-form .checkbox-grp {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin: 25px 0;
}
.comment-form .checkbox-grp input {
  width: 22px;
  margin-right: 9px;
  height: 22px;
  cursor: pointer;
}
.comment-form .checkbox-grp label {
  font-weight: 400;
  font-size: 14px;
  color: var(--tg-body-color);
  user-select: none;
}
.comment-form .btn::after {
  display: none;
}

.pagination-wrap .pagination {
  gap: 6px;
  justify-content: center;
  flex-wrap: wrap;
}
.pagination-wrap .pagination .page-link {
  margin-left: 0;
  padding: 0 0;
  color: var(--tg-body-color);
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--tg-border-1);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background: transparent;
}
.pagination-wrap .pagination .page-link:hover {
  color: var(--tg-color-white-default);
  background-color: var(--tg-theme-secondary);
  border-color: var(--tg-theme-secondary);
}
.pagination-wrap .pagination .page-link:focus {
  color: var(--tg-color-white-default);
  background-color: var(--tg-theme-secondary);
  border-color: var(--tg-theme-secondary);
  outline: 0;
  box-shadow: none;
}
.pagination-wrap .pagination .page-item.active .page-link {
  color: var(--tg-color-white-default);
  background-color: var(--tg-theme-secondary);
  border-color: var(--tg-theme-secondary);
}

/*=============================
    20. Contact
===============================*/
.contact__area {
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .contact__area {
    padding: 100px 0;
  }
}
.contact-map {
  width: 100%;
  height: 490px;
  margin-bottom: 70px;
}
@media (max-width: 767.98px) {
  .contact-map {
    height: 400px;
  }
}
.contact-map iframe {
  width: 100%;
  height: 100%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
@media (max-width: 991.98px) {
  .contact__content {
    margin-bottom: 50px;
  }
}
.contact__info .list-wrap li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 50px;
}
@media (max-width: 991.98px) {
  .contact__info .list-wrap li {
    margin-bottom: 30px;
  }
}
.contact__info .list-wrap li:last-child {
  margin-bottom: 0;
}
.contact__info .list-wrap li .icon {
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-color-gray-3);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  line-height: 0;
  color: #03b7f9;
  font-size: 30px;
  flex: 0 0 auto;
}
.contact__info .list-wrap li .content .title {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
}
.contact__info .list-wrap li .content p {
  margin-bottom: 0;
  font-weight: 500;
}
.contact__info .list-wrap li .content a {
  color: var(--tg-body-color);
  font-weight: 500;
}
.contact__info .list-wrap li .content a:hover {
  color: var(--tg-theme-primary);
}
.contact__form-wrap {
  background: var(--tg-color-gray-3);
  border: 1px solid var(--tg-border-1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  padding: 35px 40px 40px;
  margin-left: 0px;
}
@media (max-width: 1199.98px) {
  .contact__form-wrap {
    padding: 35px 20px 40px;
    margin-left: 0;
  }
}
.contact__form-wrap .title {
  margin-bottom: 5px;
  font-size: 30px;
  font-weight: 600;
}
@media (max-width: 991.98px) {
  .contact__form-wrap .title {
    font-size: 28px;
    margin-bottom: 8px;
  }
}
.contact__form-wrap p {
  margin-bottom: 15px;
}
.contact__form-wrap form .row {
  --bs-gutter-x: 10px;
}
.contact__form-wrap form .form-grp {
  margin-bottom: 10px;
}
.contact__form-wrap form .form-grp input {
  width: 100%;
  background: var(--tg-color-white-default);
  border: 1px solid var(--tg-color-gray-1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  padding: 14px 15px;
  font-size: 15px;
  color: var(--tg-theme-secondary);
  height: 50px;
}
.contact__form-wrap form .form-grp input::placeholder {
  font-size: 15px;
  color: var(--tg-body-color);
}
.contact__form-wrap form .form-grp textarea {
  width: 100%;
  background: var(--tg-color-white-default);
  border: 1px solid var(--tg-color-gray-1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  padding: 14px 15px;
  font-size: 15px;
  color: var(--tg-theme-secondary);
  display: block;
  min-height: 130px;
  max-height: 130px;
}
.contact__form-wrap form .form-grp textarea::placeholder {
  font-size: 15px;
  color: var(--tg-body-color);
}
.contact__form-wrap form .checkbox-grp {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  margin: 15px 0 25px;
}
.contact__form-wrap form .checkbox-grp input {
  width: 22px;
  margin-right: 9px;
  height: 22px;
  cursor: pointer;
}
.contact__form-wrap form .checkbox-grp label {
  font-weight: 400;
  font-size: 14px;
  color: var(--tg-body-color);
  user-select: none;
}
.contact__form-wrap form .btn::after {
  display: none;
}

.ajax-response.error, .ajax-response.success {
  margin: 20px 0 0 !important;
}
.ajax-response.error {
  color: red;
}
.ajax-response.success {
  color: green;
}

/*=============================
    22. 404 Error
===============================*/
.error-area {
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .error-area {
    padding: 100px 0;
  }
}
.error-content {
  text-align: center;
}
.error-content svg {
  width: 648px;
  color: var(--tg-theme-primary);
  margin-bottom: 45px;
}
@media (max-width: 1199.98px) {
  .error-content svg {
    width: 600px;
  }
}
@media (max-width: 991.98px) {
  .error-content svg {
    width: 500px;
  }
}
@media (max-width: 767.98px) {
  .error-content svg {
    width: 100%;
    margin-bottom: 30px;
  }
}
.error-content .title {
  margin-bottom: 10px;
  font-size: 48px;
}
@media (max-width: 1199.98px) {
  .error-content .title {
    font-size: 42px;
  }
}
@media (max-width: 991.98px) {
  .error-content .title {
    font-size: 40px;
  }
}
@media (max-width: 767.98px) {
  .error-content .title {
    font-size: 36px;
  }

  .bannerTITLE, .bannerSUBTITLE {
    font-size: 18px !important;
  }

  

  .banner-content {
    max-width: 90% !important;
    text-align: left !important;
  }
  .sticky-menu {
    padding: 10px !important;
  }
  .VacancyCardFront {
    width: 100% !important;
    display: block !important;
  }

  .VacancyFront {
    max-width: 100% !important;
  }

  .MuiTypography-h2 {
    font-size: 35px !important;
  }
}
.error-content .title p {
  margin-bottom: 35px;
}

/*=============================
    23. Footer
===============================*/
.footer-area {
  position: relative;
  overflow: hidden;
}
.footer__area-two {
  background: #003d52;
}
.width_100 {width: 100%;}
.footer__area-three {
  background: var(--tg-color-dark-blue);
}
.footer__area-four {
  background: var(--tg-color-dark-blue);
  position: relative;
  z-index: 1;
}
.footer-top {
  padding: 120px 0 70px;
}
@media (max-width: 767.98px) {
  .footer-top {
    padding: 100px 0 50px;
  }
}
.footer-top .row > *:nth-child(2) .footer-widget {
  margin-left: 50px;
}
@media (max-width: 991.98px) {
  .footer-top .row > *:nth-child(2) .footer-widget {
    margin-left: 0;
  }
}
.footer-top .row > *:nth-child(3) .footer-widget {
  margin-left: 70px;
}
@media (max-width: 991.98px) {
  .footer-top .row > *:nth-child(3) .footer-widget {
    margin-left: 0;
  }
}
.footer__top-two {
  padding: 45px 0 15px;
}
.footer__top-two .fw-title {
  color: var(--tg-color-white-default);
}
.footer__top-two .footer-link-list .list-wrap li a {
  font-weight: 700;
  color: var(--tg-color-gray-2);
  font-size: 16px;
}
.footer__top-two .row > *:nth-child(3) .footer-widget {
  margin-left: 15px;
}
@media (max-width: 991.98px) {
  .footer__top-two .row > *:nth-child(3) .footer-widget {
    margin-left: 0;
  }
}
.footer__top-three {
  padding: 110px 0 70px;
}
@media (max-width: 767.98px) {
  .footer__top-three {
    padding: 90px 0 40px;
  }
}
.footer__top-three .fw-title {
  color: var(--tg-color-white-default);
}
.footer__top-three .footer-link-list .list-wrap li a {
  color: var(--tg-color-gray-2);
}
.footer__top-three .footer-info-list .list-wrap li .icon, .footer__top-three .footer-info-list .list-wrap li p, .footer__top-three .footer-info-list .list-wrap li a {
  color: var(--tg-color-gray-2);
}
.footer-widget {
  margin-bottom: 40px;
  padding-left: 100px;
}
.footer-content p {
  margin-bottom: 25px;
}
.footer__content-two {
  width: 80%;
}
@media (max-width: 991.98px) {
  .footer__content-two {
    width: 100%;
  }
}
.footer__content-two > p {
  color: var(--tg-color-gray-2);
  margin-bottom: 15px;
  font-weight: 700;
}
.footer-social .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
}
.footer-social .list-wrap li a {
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EAF1F4;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  font-size: 20px;
  color: var(--tg-heading-color);
}
.footer-social .list-wrap li a:hover {
  background: var(--tg-theme-secondary);
  color: var(--tg-color-white-default);
}
.footer__social-two {
  flex-grow: 1;
}
.footer__social-two .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
@media (max-width: 991.98px) {
  .footer__social-two .list-wrap {
    justify-content: center;
  }
}
.footer__social-two .list-wrap li a {
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #232563;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-size: 20px;
  color: var(--tg-color-gray-2);
}
.footer__social-two .list-wrap li a:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
}
.footer__social-three .title {
  display: block;
  color: #7375A5;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.footer__social-three .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
}
.footer__social-three .list-wrap li a {
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #232563;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-size: 20px;
  color: var(--tg-color-gray-2);
}
.footer__social-three .list-wrap li a:hover {
  background: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
}
.footer-info-list .list-wrap li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}
.footer-info-list .list-wrap li .icon {
  font-size: 16px;
  line-height: 0;
}
.footer-info-list .list-wrap li a {
  color: var(--tg-body-color);
}
.footer-info-list .list-wrap li a:hover {
  color: var(--tg-theme-primary);
}
.footer-info-list .list-wrap li:last-child {
  margin-bottom: 0;
}
.footer-info-list .list-wrap li .content p {
  margin-bottom: 0;
}
.footer-link-list .list-wrap li {
  margin-bottom: 5px;
  list-style: disc;
margin-left: 10px;
}
.footer-link-list .list-wrap li:last-child {
  margin-bottom: 0;
}
.footer-link-list .list-wrap li a {
  color: var(--tg-body-color);
  position: relative;
}
.footer-link-list .list-wrap li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 1px;
  background: #f1f1f1;
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.footer-link-list .list-wrap li a:hover {
  color: #f1f1f1;
}
.footer-link-list .list-wrap li a:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.footer-instagram .list-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
}
.footer-instagram .list-wrap li a img {
  width: 75px;
  height: 75px;
/*  object-fit: cover;
  mix-blend-mode: luminosity;*/
  border-radius: 10px;
}
.footer-bottom {
  background: var(--tg-color-gray-3);
  border-top: 1px solid var(--tg-border-1);
  padding: 35px 0;
}
@media (max-width: 767.98px) {
  .footer-bottom {
    padding: 25px 0;
  }
}
.footer__bottom-two {
  /* border-top: 1px solid #272A68; */
  padding: 15px 0;
}
@media (max-width: 767.98px) {
  .footer__bottom-two {
    padding: 30px 0;
  }
}
.footer__bottom-three {
  border-top: 1px solid #272A68;
  padding: 40px 0;
}
@media (max-width: 767.98px) {
  .footer__bottom-three {
    padding: 25px 0;
  }
}
@media (max-width: 767.98px) {
  .footer__bottom-three .fw-logo {
    text-align: center;
    margin-bottom: 20px;
  }
}
.footer__bottom-three .copyright-text {
  text-align: right;
}
@media (max-width: 767.98px) {
  .footer__bottom-three .copyright-text {
    text-align: center;
  }
}
.footer__bottom-three .copyright-text p {
  color: #5F6196;
}
.footer__bottom-three .copyright-text p a {
  color: #5F6196;
}
.footer__bottom-four {
  border-top: 1px solid #272A68;
  padding: 65px 0;
}
@media (max-width: 991.98px) {
  .footer__bottom-four {
    padding: 25px 0;
  }
}
.footer__bottom-four .copyright-text {
  text-align: right;
}
@media (max-width: 991.98px) {
  .footer__bottom-four .copyright-text {
    text-align: center;
  }
}
.footer__bottom-four .copyright-text p {
  color: #5F6196;
}
.footer__bottom-four .copyright-text p a {
  color: #5F6196;
}
.footer-newsletter {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 991.98px) {
  .footer-newsletter {
    margin-bottom: 20px;
  }
}
@media (max-width: 767.98px) {
  .footer-newsletter {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.footer-newsletter .title {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}
@media (max-width: 1199.98px) {
  .footer-newsletter .title {
    font-size: 18px;
  }
}
.footer-newsletter form {
  position: relative;
  flex-grow: 1;
}
.footer-newsletter form input {
  width: 100%;
  border: 1px solid var(--tg-border-1);
  background: var(--tg-color-white-default);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  font-size: 14px;
  line-height: 0;
  color: var(--tg-theme-secondary);
  padding: 16px 140px 16px 22px;
  height: 50px;
}
.footer-newsletter form input::placeholder {
  font-size: 14px;
  text-transform: capitalize;
  color: #667594;
}
.footer-newsletter .btn {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  position: absolute;
  right: 0;
  top: 0;
}
.footer-newsletter .btn::after {
  display: none;
}
.footer__newsletter-two {
  padding: 50px 0 50px;
  border-bottom: 1px solid #272A68;
}
@media (max-width: 767.98px) {
  .footer__newsletter-two {
    padding: 60px 0 50px;
  }
}
.footer__newsletter-three {
  width: 90%;
}
@media (max-width: 767.98px) {
  .footer__newsletter-three {
    width: 100%;
  }
}
.footer__newsletter-three .title {
  margin-bottom: 20px;
  color: var(--tg-color-white-default);
  font-size: 24px;
  text-transform: capitalize;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .footer__newsletter-three .title br {
    display: none;
  }
}
.footer__newsletter-three form {
  margin-bottom: 25px;
}
.footer__newsletter-three form input {
  background: #212356;
  border: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
  color: var(--tg-color-white-default);
  padding: 15px 25px;
  height: 50px;
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.footer__newsletter-three form input::placeholder {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #595B81;
}
.footer__newsletter-three form .btn {
  width: 100%;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  justify-content: center;
}
.footer__newsletter-three form .btn::after {
  display: none;
}
.footer__newsletter-four {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 25px;
}
@media (max-width: 991.98px) {
  .footer__newsletter-four {
    justify-content: center;
    margin-bottom: 20px;
  }
}
@media (max-width: 767.98px) {
  .footer__newsletter-four {
    flex-wrap: wrap;
  }
}
.footer__newsletter-four .title {
  margin-bottom: 0;
  color: var(--tg-color-white-default);
  font-size: 24px;
}
.footer__newsletter-four form {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
}
@media (max-width: 767.98px) {
  .footer__newsletter-four form {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.footer__newsletter-four form input {
  flex-grow: 1;
  border: none;
  background: var(--tg-color-white-default);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
  color: var(--tg-theme-secondary);
  padding: 15px 25px;
  height: 50px;
}
.footer__newsletter-four form input::placeholder {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #667594;
}
.footer__newsletter-four form .btn {
  width: 127px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}
.footer__newsletter-four form .btn::after {
  display: none;
}
.footer__newsletter-inner {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
@media (max-width: 1199.98px) {
  .footer__newsletter-inner {
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
}
.footer__newsletter-inner .title {
  margin-bottom: 0;
  color: var(--tg-color-white-default);
  font-size: 24px;
  text-transform: capitalize;
  width: 13%;
  margin-right: 30px;
}
@media (max-width: 1199.98px) {
  .footer__newsletter-inner .title {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
}
.footer__newsletter-inner form {
  position: relative;
  width: 620px;
}
.footer__newsletter-inner form input {
  width: 100%;
  background: var(--tg-color-white-default);
  border: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: var(--tg-fw-bold);
  color: var(--tg-theme-secondary);
  padding: 20px 145px 20px 28px;
  height: 50px;
}
.footer__newsletter-inner form input::placeholder {
  font-size: 14px;
  font-weight: var(--tg-fw-bold);
  color: #667594;
  text-transform: capitalize;
}
.footer__newsletter-inner form .btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}
.footer__newsletter-inner form .btn::after {
  display: none;
}
.footer-shape img {
  position: absolute;
  z-index: -1;
}
.footer-shape img:nth-child(1) {
  left: 0;
  bottom: 0;
}
.footer-shape img:nth-child(2) {
  right: 0;
  top: 0;
}
.footer-shape img:nth-child(3) {
  right: 6%;
  top: 30%;
}
@media (max-width: 991.98px) {
  .footer-shape img:nth-child(3) {
    right: 11%;
    top: 24%;
  }
}
.footer-shape-two img {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
}
.fw-title {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
  position: relative;
  padding-bottom: 18px;
}
.fw-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background: #b3afaf;
}

.footer-info-two .list-wrap li .icon {
  color: var(--tg-color-gray-2);
}
.footer-info-two .list-wrap li .content a {
  font-weight: 700;
  color: var(--tg-color-gray-2);
}
.footer-info-two .list-wrap li .content a:hover {
  color: var(--tg-theme-primary);
}
.footer-info-two .list-wrap li .content p {
  font-weight: normal;
  color: var(--tg-color-gray-2);
}

@media (max-width: 991.98px) {
  .copyright-text {
    text-align: center;
  }
}
.copyright-text p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
}
.copyright-text p a {
  color: var(--tg-body-color);
}
.copyright-text p a:hover {
  color: var(--tg-theme-primary);
}
.copyright-text a {
  color: var(--tg-body-color);
  font-size: 14px;
  font-weight: 500;
}
.copyright-text a:hover {
  color: var(--tg-theme-primary);
}
.copyright-text-two p {
  margin-bottom: 0;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  color: #a4a4bd;
}
.copyright-text-two p a {
      color: #a4a4bd;
        font-size: 12px;
        font-weight: bold !important;
}
.copyright-text-two p a:hover {
  color: var(--tg-theme-primary);
}

/*=============================
    00. Keyframe Animation
===============================*/
@-webkit-keyframes hoverShine {
  100% {
    left: 125%;
  }
}
@keyframes hoverShine {
  100% {
    left: 125%;
  }
}
.alltuchtopdown {
  -webkit-animation: alltuchtopdown 1.5s ease-in-out 0s infinite alternate;
  animation: alltuchtopdown 1.5s ease-in-out 0s infinite alternate;
  animation-duration: 3s;
}

@keyframes alltuchtopdown {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    -webkit-transform: rotateX(0deg) translateY(-20px);
    -moz-transform: rotateX(0deg) translateY(-20px);
    -ms-transform: rotateX(0deg) translateY(-20px);
    -o-transform: rotateX(0deg) translateY(-20px);
    transform: rotateX(0deg) translateY(-20px);
  }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}
@-webkit-keyframes alltuchtopdown {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    -webkit-transform: rotateX(0deg) translateY(-20px);
    -moz-transform: rotateX(0deg) translateY(-20px);
    -ms-transform: rotateX(0deg) translateY(-20px);
    -o-transform: rotateX(0deg) translateY(-20px);
    transform: rotateX(0deg) translateY(-20px);
  }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}
.rightToLeft {
  -webkit-animation-name: rightToLeft;
  animation-name: rightToLeft;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes rightToLeft {
  0% {
    transform: translateX(-30px);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(-30px);
  }
}
.ribbonRotate {
  -webkit-animation-name: ribbonRotate;
  animation-name: ribbonRotate;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes ribbonRotate {
  0%, 100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  25%, 75% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  50% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes fadeInUp2 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.fadeInUp2 {
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeInLeft2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
.fadeInLeft2 {
  -webkit-animation-name: fadeInLeft2;
  animation-name: fadeInLeft2;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes marquee {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  to {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  to {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
@-webkit-keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.login__area-one {
  padding: 120px 0px 250px 0px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.box-form-login {
  background-color: var(--tg-color-gray-3);
  max-width: 579px;
  margin: auto;
  padding: 63px 61px;
  border: 1px solid var(--tg-border-1);
  border-radius: 8px;
}
.box-form-login .form-group {
  margin-bottom: 24px;
  position: relative;
}
.box-form-login .form-control {
  padding: 22px 26px;
}
.box-form-login .form-control::placeholder {
  color: var(--tg-border-5);
  font-size: 15px;
  font-weight: 400;
}
.box-form-login .form-control.account {
  background-repeat: no-repeat;
  background-position: right 26px center;
}
.box-form-login .form-control.email-address {
  background-repeat: no-repeat;
  background-position: right 26px center;
}
.box-form-login .view-password {
  background-repeat: no-repeat;
  background-position: center;
  height: 16px;
  width: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 26px;
  z-index: 12;
}

.form-login {
  position: relative;
}

.text-or {
  position: relative;
  text-align: center;
  margin: 34px 0px 48px 0px;
  border-top: 1px solid var(--tg-color-gray-2);
}
.text-or span {
  padding: 5px 11px;
  background-color: var(--tg-color-gray-3);
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  font-size: 16px;
  line-height: 28px;
  font-weight: bold;
  color: var(--tg-color-body-color);
  text-transform: capitalize;
  margin: auto;
  max-width: 41px;
  left: 0px;
  right: 0px;
}

.text-48-bold {
  font-size: 48px;
  line-height: 65px;
  font-weight: bold;
}

.box-login-with {
  margin-top: 35px;
}

.box-forgot-pass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.box-forgot-pass * {
  color: var(--tg-color-body-color);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.box-forgot-pass span {
  padding-left: 0px;
  overflow: hidden;
  display: block;
}

.cb-remember {
  float: left;
  margin: 4px 10px 0px 0px;
}

.form-login .link-bold {
  font-weight: bold;
}
.form-login .link-bold:hover {
  color: var(--tg-theme-secondary);
}

.header-border-bottom {
  border-bottom: 1px solid var(--tg-color-gray-2);
}

@media (max-width: 575px) {
  .box-form-login {
    padding: 23px 21px;
  }
  .box-form-login .form-control.account, .box-form-login .form-control.email-address {
    background-position: right 12px center;
  }
  .box-form-login .view-password {
    right: 14px;
  }
  .box-form-login .form-control {
    padding: 22px 40px 22px 16px;
  }
  .text-48-bold {
    font-size: 35px;
    line-height: 50px;
  }
}
@media (max-width: 375px) {
  .text-48-bold {
    font-size: 25px;
    line-height: 40px;
  }
}
.register__area-one {
  padding: 120px 0px 250px 0px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.forgot__area-one {
  padding: 120px 0px 250px 0px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.text-16-semibold {
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
}

.form-forgot .text-infos {
  padding: 0px 0px 0px 29px;
  background-repeat: no-repeat;
  background-position: left center;
  color: var(--tg-body-color);
  margin-bottom: 0px;
}
.form-forgot .alert-warning {
  padding: 15px 24px;
  margin-bottom: 24px;
  border: 0px;
}

.border-btn.btn-login {
  padding: 19px 26px;
}
.border-btn.btn-login svg {
  fill: var(--tg-body-color);
  width: 16px;
  margin-right: 8px;
}
.border-btn.btn-login:hover svg {
  fill: var(--tg-color-white-default);
}
.border-btn.btn-login::after {
  display: none;
}

.slider_baner_home6 .slider__bg {
  opacity: 1;
}
.slider_baner_home6 .banner__content-three {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  padding: 60px 60px 60px 80px;
  margin: 60px 0px 60px 60px;
}
.slider_baner_home6 .banner__content-three .title {
  margin-bottom: 10px;
}
.slider_baner_home6 .banner__content-three p {
  margin-bottom: 30px;
}
.slider_baner_home6 .slider__single {
  max-height: 693px;
  background-color: transparent;
}
.slider_baner_home6 .slider__single::before {
  display: none;
}

.text-stroke-2 {
  color: #FDBE39;
  text-shadow: -1px -1px 0 #E08B00, 1px -1px 0 #E08B00, -1px 1px 0 #E08B00, 1px 1px 0 #E08B00;
  font-size: 70px;
  font-weight: bold;
  line-height: 70px;
}

.text-25-years {
  padding: 20px 30px 20px 40px;
  display: flex;
  align-items: flex-start;
  width: fit-content;
  background-color: var(--tg-color-gray-3);
  border-radius: 50px 0px 50px 50px;
  position: absolute;
  top: 50%;
  left: -175px;
  z-index: 123;
  transform: translateY(-50%) rotate(-90deg);
}

.text-experience {
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0px;
  padding-left: 10px;
}

.slider__area {
  position: relative;
}

.box-button-slider-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 1234;
  padding-bottom: 50px;
}
.box-button-slider-bottom .testimonial__nav-four {
  justify-content: flex-end;
}

.services__item-six {
  border: 0px;
  box-shadow: none;
  display: flex;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 55px;
}
.services__item-six .services__icon-five {
  min-width: 78px;
  margin-right: 15px;
  margin-top: 18px;
}
.services__item-six .services__content-five {
  width: 100%;
  text-align: left;
}
.services__item-six .icon {
  transition: all 0.3s ease-out 0s;
}
.services__item-six:hover .icon {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  transition: all 0.3s ease-out 0s;
}

.services__item-wrap-two .row [class*=col-]:nth-child(odd) .services__item-six {
  margin-bottom: 30px;
  margin-top: 0px;
}

.all_services__area-six {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 112px 0px;
  text-align: center;
  color: var(--tg-color-white-default);
}
.all_services__area-six h2 {
  font-size: 48px;
  line-height: 63px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 15px;
}
.all_services__area-six p {
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 20px;
}

.card-team-area-six {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--tg-border-1);
  border-radius: 15px;
  background-color: var(--tg-color-white-default);
  overflow: hidden;
  margin-bottom: 30px;
}
.card-team-area-six .card-image {
  min-width: 265px;
  max-width: 265px;
  width: 100%;
  background-color: var(--tg-color-yellow-light);
  position: relative;
}
.card-team-area-six .card-image::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.card-team-area-six .card-image img {
  position: relative;
  z-index: 2;
  max-height: 305px;
  margin-top: 15px;
  margin-left: 10px;
}
.card-team-area-six .card-image .btn-share {
  background-color: var(--tg-color-white-default);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 14px;
  right: 17px;
  z-index: 4;
  text-align: center;
  line-height: 48px;
}
.card-team-area-six .card-image .btn-share img {
  margin: 0px;
}
.card-team-area-six .card-info {
  width: 100%;
  padding: 20px 20px 20px 30px;
}
.card-team-area-six .card-info .card-title {
  margin-bottom: 28px;
}
.card-team-area-six .card-info .card-title a {
  color: var(--tg-heading-color);
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
}
.card-team-area-six .card-info .card-dept {
  color: var(--tg-theme-primary);
  font-size: 16px;
  line-height: 16px;
  margin: 10px 0px;
}
.card-team-area-six .card-info .card-desc {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--tg-body-color);
  margin-bottom: 28px;
}
.card-team-area-six .card-info .card-link a {
  text-decoration: underline;
  color: var(--tg-theme-primary);
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
}

.box-need-help {
  position: relative;
}

.box-text-need-help {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 32px;
  background-color: var(--tg-heading-color);
  color: var(--tg-color-white-default);
  max-width: 297px;
}
.box-text-need-help h6 {
  color: var(--tg-theme-primary);
  margin-top: 15px;
  margin-bottom: 13px;
}
.box-text-need-help p {
  color: var(--tg-color-white-default);
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 0px;
}

.accordion-button {
  border-bottom: 0px;
  padding: 23px 54px 23px 34px;
  border: 0px;
  position: relative;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: var(--tg-color-white-default);
}
.accordion-button:hover {
  color: var(--tg-color-white-default);
}

.accordion-button.collapsed {
  border-bottom: 1px solid var(--tg-border-1);
  background-color: var(--tg-theme-primary);
}

.accordion-button:focus {
  border-color: var(--tg-border-1);
}

.accordion-collapse {
  border: 0px;
  background-color: var(--tg-theme-primary);
}
.accordion-collapse .accordion-body {
  padding: 0px;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 0px;
  padding-bottom: 25px;
  color: var(--tg-color-white-default);
}

.accordion-button:not(.collapsed) {
  background-color: var(--tg-theme-primary);
  color: var(--tg-color-white-default);
  box-shadow: none;
}

.accordion {
  position: relative;
}

.accordion-item {
  border: 0px;
  overflow: hidden;
  margin-bottom: 8px;
  border-radius: 16px !important;
}

.accordion-item:first-of-type {
  border-radius: 16px !important;
}

.accordion-button::after {
  transform: rotate(0deg);
  background-position: center;
  background-color: var(--tg-theme-primary);
  border-radius: 4px;
  width: 24px;
  height: 24px;
  top: 50%;
  margin-top: -12px;
  right: 20px;
  position: absolute;
  z-index: 12;
}

.accordion-button:not(.collapsed)::after {
  background-color: var(--tg-theme-primary);
}

.box-faq-right {
  padding-left: 20px;
}

.marketing_expert__area_six {
  padding: 125px 0px 0px 0px;
  background-color: var(--tg-color-gray-3);
  background-repeat: no-repeat;
  background-position: left center;
  margin-bottom: 130px;
}

.footer__content-two > .color-white {
  color: var(--tg-color-white-default);
}

.footer__content-two .mb-40 {
  margin-bottom: 40px;
}

.box-video {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}
.box-video::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
}
.box-video .bnt-play {
  position: absolute;
  top: 0px;
  z-index: 12;
  height: 114px;
  width: 114px;
  left: 0px;
  right: 0px;
  margin: auto;
  bottom: 0px;
}

.box-video-small {
  margin-top: 0px;
  position: relative;
  bottom: -130px;
}

.item-video-small {
  display: flex;
  background-color: var(--tg-heading-color);
  border-radius: 16px;
  overflow: hidden;
}

.video-small-left {
  width: 100%;
  padding: 46px;
}

.video-small-right {
  max-width: 392px;
  min-width: 392px;
}
.video-small-right .box-video {
  border-radius: 0px;
}
.video-small-right .box-video::before {
  display: none;
}
.video-small-right .bnt-play {
  height: 70px;
  width: 70px;
}

.video-small-left-inner {
  display: flex;
  align-items: center;
}
.video-small-left-inner .video-small-left-1 {
  border-radius: 17px;
  background-color: var(--tg-theme-primary);
  padding: 29px;
  min-width: 139px;
  margin-right: 20px;
  text-align: center;
}
.video-small-left-inner .video-small-left-1 * {
  color: var(--tg-color-white-default);
}
.video-small-left-inner .video-small-left-1 h3 {
  font-size: 48px;
  line-height: 48px;
}
.video-small-left-inner .video-small-left-1 p {
  margin-bottom: 0px;
}
.video-small-left-inner .video-small-left-2 * {
  color: var(--tg-color-white-default);
}
.video-small-left-inner .video-small-left-2 h3 {
  font-size: 30px;
  line-height: 34px;
  margin-bottom: 13px;
}

.footer__newsletter-six {
  position: relative;
  flex-wrap: wrap;
  gap: 10px;
}

.footer__top-two .color-white {
  color: var(--tg-color-white-default);
}

.text-subscribe {
  margin-bottom: 0px;
  width: 100%;
}

.footer__area-six .footer__content-two {
  max-width: 476px;
}

.footer__area-six .copyright-text-two p {
  text-align: left;
}

.text-stroke {
  color: var(--tg-color-dark-blue);
  text-shadow: 1px 1px 0 var(--tg-color-white-default), -1px -1px 0 var(--tg-color-white-default), 1px -1px 0 var(--tg-color-white-default), -1px 1px 0 var(--tg-color-white-default), 1px 1px 0 var(--tg-color-white-default);
  font-size: 60px;
  letter-spacing: 4px;
}

.partners__area-six {
  background-color: var(--tg-color-dark-blue);
  padding: 49px 0px;
}

.slider__partner {
  width: auto !important;
}
.slider__partner h4 {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.slider__partner h4 img {
  margin: 0px 25px;
}

.choose__area-six {
  padding: 124px 0px;
}
.choose__area-six .choose__img-wrap-five {
  text-align: center;
  position: relative;
}
.choose__area-six .choose__img-wrap-five .alltuchtopdown {
  z-index: 1;
  left: auto;
  right: 0%;
  bottom: 15%;
}
.choose__area-six .choose__img-wrap-five .shape-left {
  position: absolute;
  left: -10px;
  right: auto;
  top: 4%;
  box-shadow: none;
}
.choose__area-six .choose__img-wrap-five .shape-bottom-left {
  position: absolute;
  left: -10px;
  right: auto;
  bottom: -10px;
}
.choose__area-six .choose__img-wrap-five .shape-top-right {
  position: absolute;
  top: -40px;
  right: 30px;
  z-index: -1;
}

.our_team__area-six {
  padding: 124px 0px 0px 0px;
}

.faqs__area-six {
  padding: 124px 0px 124px 0px;
  background-repeat: no-repeat;
  background-position: left bottom -120px;
  position: relative;
}
.faqs__area-six .circle {
  background-repeat: no-repeat;
  background-position: center;
  height: 52px;
  width: 52px;
  position: absolute;
  left: 5%;
  top: 180px;
}

.marketing_expert__area_six .shape-top-right {
  position: absolute;
  top: -30px;
  right: -20px;
}

.services__item-style-2 .services__item-five {
  text-align: left;
  padding: 45px 30px 30px 30px;
}
.services__item-style-2 .services__item-five .services__icon-five {
  margin-left: 15px;
}
.services__item-style-2 .services__item-five .services__content-five p {
  min-height: 120px;
}

.footer-payments img {
  margin: 0px 5px 9px 0px;
}

.link-gray {
  color: var(--tg-body-color);
}

.services__area-home7 {
  background-color: var(--tg-color-gray-3);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto;
}

.text-60-bold, .banner-two-col .section-title .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 600;
}
.text-60-bold span, .banner-two-col .section-title .title span {
  font-weight: 300;
}

.item-author-more {
  display: inline-block;
  height: 64px;
  width: 64px;
  border: 1px solid var(--tg-theme-primary);
  border-radius: 50%;
  text-align: center;
  line-height: 65px;
  margin-left: -5px;
  background-color: var(--tg-color-white-default);
  font-size: 16px;
  font-weight: 600;
}

.box-authors {
  display: flex;
  align-items: center;
  position: relative;
}
.box-authors img {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  margin-right: -30px;
}

.box-img-author {
  position: relative;
}

.text-trusted-author {
  font-size: 24px;
  line-height: 32px;
  color: var(--tg-heading-color);
  font-weight: 600;
  padding-left: 20px;
  max-width: 268px;
}

.text-42-bold {
  font-size: 42px;
  line-height: 42px;
  font-weight: bold;
}

.text-need-help {
  font-size: 24px;
  line-height: 24px;
  color: var(--tg-color-white-default);
}

.text-phone {
  color: var(--tg-color-white-default);
}

.slide__home7 {
  position: relative;
}

.box-info-contact {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: var(--tg-heading-color);
  padding: 52px;
}

.brand__area-home7 {
  padding-top: 117px;
}

.main-img-why {
  position: relative;
}
.main-img-why .play-btn {
  background: var(--tg-color-white-default);
  width: 114px;
  height: 114px;
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 10px;
  right: 0px;
  bottom: 0px;
  z-index: 12;
  border-radius: 50%;
  margin: auto auto auto 150px;
}
.main-img-why .play-btn i {
  font-size: 43px;
  color: var(--tg-heading-color);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: 43px;
  width: 38px;
}

.whychoose__area-home7 {
  padding-top: 0px;
}

.icon-line {
  position: absolute;
  bottom: 15%;
  left: 3%;
  z-index: 1;
}

.icon-star {
  position: absolute;
  top: 30%;
  right: 10%;
  z-index: 1;
}

.icon-snow {
  position: absolute;
  top: 15%;
  left: 10%;
  z-index: 1;
}

.steps__area-seven {
  background-color: var(--tg-heading-color);
  padding: 118px 0px 78px 0px;
}
.steps__area-seven * {
  color: var(--tg-color-white-default);
}

.journey_area-seven {
  padding: 85px 0px 0px 0px;
  background-color: var(--tg-heading-color);
  margin-bottom: 200px;
  background-position: top center;
}
.journey_area-seven .section-title h2, .journey_area-seven .section-title p {
  color: var(--tg-color-white-default);
}
.journey_area-seven .section-title p {
  margin-bottom: 25px;
}

.box-counter-home7 {
  background-color: var(--tg-color-white-default);
  border-radius: 16px;
  -ms-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 60px 20px 30px 20px;
  bottom: -105px;
  display: inline-block;
  width: 100%;
  position: relative;
}
.box-counter-home7 .counter-item {
  gap: 13px;
}

.card-step .card-icon {
  height: 78px;
  width: 69px;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 78px;
  margin-bottom: 24px;
}
.card-step .card-icon * {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
.card-step .card-info h5, .card-step .card-info p {
  color: var(--tg-color-white-default);
  margin-bottom: 24px;
}
.card-step .card-info p {
  margin-bottom: 40px;
}
.card-step .card-info .link-readmore {
  color: var(--tg-color-white-default);
}
.card-step .card-info .link-readmore svg {
  margin-left: 5px;
}

.mw-460 {
  max-width: 460px;
}

.slider_testimonial_home7 {
  position: relative;
  padding-top: 90px;
}
.slider_testimonial_home7 .quote-review {
  position: absolute;
  top: 0px;
  right: 20px;
  z-index: 12;
}

.item-testimonial {
  max-width: 1100px;
  padding-left: 50px;
  padding-right: 50px;
  margin: auto;
  display: flex;
}
.item-testimonial .item-testimonial-left {
  min-width: 300px;
  padding: 0px 10px;
  text-align: center;
}
.item-testimonial .item-testimonial-left .author-testimonial img {
  height: 87px;
  width: 87px;
  border-radius: 50%;
  margin-bottom: 5px;
}
.item-testimonial .item-testimonial-left .author-testimonial .info-author-review .name-review {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 5px;
}
.item-testimonial .item-testimonial-left .author-testimonial .info-author-review .review-dept {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: var(--tg-theme-primary);
}
.item-testimonial .item-testimonial-left .author-testimonial .info-author-review .brand-review {
  margin-top: 32px;
  opacity: 0.5;
}
.item-testimonial .item-testimonial-right p {
  font-size: 24px;
  line-height: 30px;
  color: var(--tg-body-color);
  font-weight: 500;
}

.testimonials_area-seven {
  padding-bottom: 130px;
}
.testimonials_area-seven .testimonial__nav-four .testimonial-two-button-prev, .testimonials_area-seven .testimonial__nav-four .testimonial-two-button-next {
  position: absolute;
  top: 50%;
  z-index: 123;
  margin-top: -25px;
  background-color: var(--tg-color-white-default);
  border: 1px solid var(--tg-border-1);
}
.testimonials_area-seven .testimonial__nav-four .testimonial-two-button-prev:hover, .testimonials_area-seven .testimonial__nav-four .testimonial-two-button-next:hover {
  background-color: var(--tg-theme-primary);
}
.testimonials_area-seven .testimonial__nav-four .testimonial-two-button-prev {
  left: 0px;
}
.testimonials_area-seven .testimonial__nav-four .testimonial-two-button-next {
  right: 0px;
}
.testimonials_area-seven .swiper-pagination {
  bottom: 0px;
}
.testimonials_area-seven .swiper-container {
  padding-bottom: 40px;
}
.testimonials_area-seven .swiper-pagination-bullet {
  background-color: var(--tg-color-gray-2);
}
.testimonials_area-seven .swiper-pagination-bullet-active {
  background-color: var(--tg-heading-color);
}

.about__award-box-two-rel {
  position: relative;
}

.about__award-box-two2 {
  bottom: 115px;
}

.box-yellow-about {
  background-color: var(--tg-color-yellow-light);
  padding: 50px;
  border-radius: 32px;
}

@media (max-width: 1199px) {
  .banner-two-col .banner-col-1, .banner-two-col .banner-col-2 {
    width: 50%;
  }
}
@media (max-width: 992px) {
  .banner-two-col, .item-video-small {
    flex-wrap: wrap;
  }
  .video-small-right {
    max-width: 100%;
    min-width: 100%;
  }
  .banner-two-col .banner-col-1, .banner-two-col .banner-col-2 {
    width: 100%;
  }
  .item-testimonial {
    padding-left: 0px;
    padding-right: 0px;
  }
  .item-testimonial .item-testimonial-left {
    min-width: 190px;
  }
  .testimonials_area-seven .testimonial__nav-four .testimonial-two-button-next {
    right: -70px;
  }
  .testimonials_area-seven .testimonial__nav-four .testimonial-two-button-prev {
    left: -70px;
  }
}
@media (max-width: 768px) {
  .video-small-left {
    padding: 26px;
  }
}
@media (max-width: 575px) {
  .text-25-years {
    padding: 5px 15px 5px 24px;
    border-radius: 50px 0px 50px 50px;
    left: -135px;
  }
  .slider_baner_home6 .banner__content-three {
    padding: 30px 20px 30px 50px;
    margin: 0px 0px 100px 30px;
  }
  .slider_baner_home6 .banner__content-three .title {
    font-size: 30px;
  }
  .card-team-area-six {
    flex-wrap: wrap;
  }
  .card-team-area-six .card-image {
    min-width: 100%;
    max-width: 100%;
  }
  .video-small-left-inner {
    flex-wrap: wrap;
  }
  .video-small-left-inner .video-small-left-1 {
    margin-bottom: 30px;
  }
  .item-testimonial .item-testimonial-right p {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (max-width: 450px) {
  .item-testimonial {
    flex-wrap: wrap;
  }
  .item-testimonial-left {
    width: 100%;
    min-width: 100%;
    margin-bottom: 30px;
  }
}
.banner-two-col {
  background-color: var(--tg-color-gray-3);
}
.banner-two-col .banner-col-1 {
  padding: 80px 0;
  min-height: 646px;
}
.banner-two-col .banner-col-2 {
  width: 40%;
  position: absolute;
  top: 0;
  right: 0;
}
.banner-two-col .banner-col-2 .box-button-slider-bottom {
  bottom: 90px;
  left: auto;
  padding-bottom: 0px;
  right: 80px;
}

.services__item-five .icon {
  transition-duration: 0.3s;
}

.services__item-five:hover .icon {
  transform: rotateY(180deg);
  transition-duration: 0.3s;
}

.card-step .card-icon svg {
  transition-duration: 0.3s;
}

.card-step:hover .card-icon svg {
  transform: rotateY(180deg);
  transition-duration: 0.3s;
}

@media only screen and (max-width: 920px) {
  .banner-two-col .banner-col-2 {
    display: none;
  }
}
.card-services-type-01 {
  padding: 30px;
  background-color: var(--tg-color-white-default);
  border: 1px solid var(--tg-color-yellow-light-4);
  text-align: center;
  margin-bottom: 30px;
  border-radius: 15px;
  transition-duration: 0.2s;
}
.card-services-type-01:hover {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1019607843);
  transition-duration: 0.2s;
  transform: translateY(-2px);
}
.card-services-type-01 .card-icon {
  margin-bottom: 24px;
  height: 58px;
}
.card-services-type-01 .card-icon i, .card-services-type-01 .card-icon img, .card-services-type-01 .card-icon svg {
  transition: 0.3s linear;
}
.card-services-type-01 .card-icon:hover i, .card-services-type-01 .card-icon:hover img, .card-services-type-01 .card-icon:hover svg {
  transform: rotateY(180deg);
}
.card-services-type-01 .card-info h5 {
  margin-bottom: 24px;
  font-size: 22px;
  line-height: 35px;
}

.slider_baner_home8 {
  position: relative;
}
.slider_baner_home8 .slider__bg {
  min-height: 778px;
  z-index: 1;
  opacity: 1;
  mix-blend-mode: normal;
}
.slider_baner_home8 .slider__bg::before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--tg-heading-color);
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.8;
}
.slider_baner_home8 .container {
  position: relative;
  z-index: 2;
}
.slider_baner_home8 .slider__single {
  min-height: 778px;
  align-items: flex-start;
}
.slider_baner_home8 .banner__content-three {
  margin-top: 0px;
}
.slider_baner_home8 .banner__content-three p {
  margin-bottom: 45px;
  color: var(--tg-color-white-default);
}
.slider_baner_home8 .banner__conten
t-three .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: bold;
  margin-bottom: 45px;
  color: var(--tg-color-white-default);
}
.slider_baner_home8 .banner__content-three .title span {
  font-weight: 300;
}

.slider__area-home8 {
  position: relative;
  padding: 0px 170px;
}
.slider__area-home8 .box-form-quote {
  position: absolute;
  top: 100px;
  width: auto;
  right: 0px;
  z-index: 12;
}
.slider__area-home8 .box-form-quote .testimonial__form {
  margin: 0px 0px 0px auto;
}
.slider__area-home8 .testimonial__nav-four .testimonial-two-button-prev {
  position: absolute;
  top: 50%;
  left: 60px;
  margin-top: -25px;
}
.slider__area-home8 .box-button-slider-bottom {
  position: static;
  padding: 0px;
  margin: 0px;
}
.slider__area-home8 .box-button-slider-bottom .testimonial__nav-four {
  margin: 0px;
}
.slider__area-home8 .testimonial__nav-four .testimonial-two-button-next {
  position: absolute;
  top: 50%;
  right: 60px;
  margin-top: -25px;
}

.services__area-home8 {
  position: relative;
  z-index: 12;
  top: -130px;
}

.card-number-star {
  background-color: var(--tg-color-white-default);
  border-radius: 50px 0px 50px 0px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1725490196);
  display: flex;
  align-items: center;
  padding: 25px;
  max-width: 376px;
  position: absolute;
  bottom: -75px;
  left: 0px;
  right: 0px;
  margin: auto;
  z-index: 4;
}
.card-number-star .number {
  position: relative;
  margin-right: 20px;
}
.card-number-star .content .title {
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
}
.card-number-star .content p {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0px;
}

.about__img-wrap-home8 {
  text-align: left;
}
.about__img-wrap-home8 .img-main {
  position: relative;
  z-index: 2;
}
.about__img-wrap-home8.about__img-wrap-seven .shape img {
  right: 7%;
  top: -14%;
}

.team__area-home8 {
  padding-top: 10px;
}

.about__img-wrap-home8.about__img-wrap-four > img {
  border-radius: 0px 40px 0px 40px;
}
.about__img-wrap-home8.about__img-wrap-four > img:nth-child(2) {
  border: 0px;
  border-radius: 40px 0px 40px 0px;
}
.about__img-wrap-home8.about__img-wrap-four .shape img {
  right: auto;
  top: auto;
  left: 30px;
  bottom: -70px;
}

.marketing__area-home8 {
  background-color: var(--tg-color-gray-3);
  background-repeat: no-repeat;
  background-position: left top;
}

.about__img-wrap2-home8 {
  position: relative;
  display: inline-block;
}
.about__img-wrap2-home8 .shape {
  position: absolute;
  bottom: -40px;
  right: -40px;
  z-index: -1;
}

.about__img-wrap2-home8 .shape-2 {
  position: absolute;
  top: 15%;
  right: -35px;
  z-index: 12;
}

.about__img-wrap3-home8 {
  display: inline-block;
  position: relative;
}
.about__img-wrap3-home8 .btn-play {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: 123;
  height: 102px;
  width: 102px;
  margin: auto;
}
.about__img-wrap3-home8 img {
  border-radius: 0px 40px 0px 40px;
}

.card-testimonials {
  position: relative;
}
.card-testimonials .card-image {
  height: 65px;
  width: 65px;
  overflow: hidden;
  border-radius: 50%;
}
.card-testimonials .card-image img {
  display: block;
  border-radius: 50%;
  height: 65px;
  width: 65px;
  transition: 0.3s linear;
}
.card-testimonials .card-info {
  background-position: top right;
  background-repeat: no-repeat;
  padding: 10px 0px;
}
.card-testimonials .card-info .card-position {
  margin-bottom: 0px;
}

.card-testimonials .card-info .card-comment p {
  font-size: 13px;
  line-height: 30px;
}

.testimonials__area-home8 {
  position: relative;
  padding-top: 120px;
}

.box-button-slider-right .testimonial__nav-four {
  justify-content: flex-end;
}

.marketing__area-home8 {
  position: relative;
}
.marketing__area-home8 .shape {
  position: absolute;
  bottom: 24%;
  right: 5%;
  z-index: 0;
}
.marketing__area-home8 .shape-2 {
  position: absolute;
  bottom: 20%;
  right: 2%;
  z-index: 0;
}

@media (max-width: 1399px) {
  .marketing__area-home8 .shape {
    bottom: 2%;
  }
  .marketing__area-home8 .shape-2 {
    bottom: 4%;
  }
  .slider__area-home8 {
    padding: 0px 50px;
  }
}
@media (max-width: 1199px) {
  .slider__area-home8 {
    padding: 0px 0px;
  }
}
@media (max-width: 992px) {
  .footer__area-six .copyright-text-two p {
    text-align: center;
    margin-bottom: 25px;
  }
  .slider__area-home8 .box-form-quote {
    position: relative;
    top: auto;
    right: auto !important;
    display: table;
    margin: auto;
    width: 100%;
  }
  .slider_baner_home8 .slider__single {
    min-height: 558px;
  }
  .slider_baner_home8 .slider__bg {
    min-height: 558px;
  }
  .slider_baner_home8 .banner__content-three {
    margin-bottom: 0px;
  }
  .services__area-home8 {
    top: auto;
    padding-top: 50px;
    padding-bottom: 100px;
  }
  .slider__area-home8 .box-form-quote .testimonial__form {
    border: 1px solid var(--tg-border-9);
    margin-top: 30px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .about__img-wrap-home8 {
    text-align: center;
    padding-bottom: 0px;
  }
  .about__img-wrap2-home8 .text-25-years {
    left: -120px;
  }
  .slider_baner_home8 .banner__content-three .title {
    font-size: 40px;
    line-height: 50px;
  }
  .marketing__area-home8 .shape, .marketing__area-home8 .shape-2 {
    display: none;
  }
}
@media (max-width: 575px) {
  .about__img-wrap2-home8 .shape-2 {
    right: -15px;
  }
}

footer a {
  font-weight: normal !important; 
}

footer .content {
  margin-top: -2px;
 margin-left: 5px;
}

footer p {
  font-size: 25px !important;
  margin-bottom: 0px;
}

footer a {
  text-decoration: none;
}

.footerABT a {
  color:rgb(143 149 156) !important;
  display: block;
  line-height: 33px;
  font-size: 17px;
}

.footerABT a:hover {
  color:#ffca08 !important;
}

.active-lg{
  margin-right: -19px;
  background: #004b91;
  padding: 0px 4px;
  font-size: 10px !important;
  color: #fff !important;
  height: 20px;
  line-height: 20px;
  border-radius: 3px;
}

.breadcrumb__area {
  position: relative;
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); 
  z-index: 1;
}



.overlay_border_radius {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius:13px;
  background-color: rgba(0, 0, 0, 0.4); 
  z-index: 1; 
}

.breadcrumb__content {
  position: relative;
  z-index: 2; 
  color: white; 
}

.breadcrumb__content .breadcrumb-item a:hover{
  color:#c4d1dd !important;
}
.border_bottom_1 {
  border-bottom: 1px solid #fff;
  padding-bottom: 20px;
}

.breadcrumb__bg {
    background-size: cover;
    background-position: center;
    padding: 150px 0 100px !important;
}

.breadcrumb__module_bg {
  background-size: cover;
  background-position: center;
  padding: 300px 0 300px !important;
}

.breadcrumb__news_bg {
  background-size: cover;
  background-position: center;
  padding: 200px 0 200px !important;
}

.custom-swiper {
  max-width: 100%;
  position: relative;
  overflow: hidden;
}


.brand-box {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; 
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}
.brand-box:hover img {
  filter: grayscale(100%);
}
.brand-logo {
  width: 100%; 
  height: auto;
  object-fit: cover; 
  border-radius: 50%; 
}


@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 94% !important;
  }
}

.loader {
  width: 100%;
  height: 2px;
  background:
   linear-gradient(#093f89 0 0) 0/0% no-repeat
   #ddd;
  animation: l1 2s infinite linear;
}
@keyframes l1 {
    100% {background-size:100%}
}

.banner-content h3 {
  font-weight: bolder;
  font-size: 4em !important;
}

.breadcrumb_module_title {
  font-weight: bolder;
  font-size: 5em !important;
  color: #fcc909;
}

.breadcrumb_news_title{
  font-weight: bolder !important;
  font-size: 3em !important;
  color: #fcc909;
}

.breadcrumb__content .title {
  margin-bottom: 10px;
  font-size: 55px !important;
  font-weight: bolder;
  color: #fcc909;
}

.content-details {
  font-size: 18px !important;
}

.StroyInnerDate{
  font-size: 18px !important;
}

blockquote h3 {
  font-size: 1.5em !important;
  line-height: 1.6;
}

.swiper-pagination {
  position: absolute !important;  
  bottom: 0 !important; 
  display: flex;
  justify-content: center;
  gap: 10px;
  z-index: 9999999999 !important;  
  padding-bottom: 20px;  
}

.swiper-wrapper {
  overflow: visible !important;
}

.swiper-slide {
  overflow: visible !important;
}

.footer_input {
  padding: 15px !important;
}

.footerDown p, h6 {
  color: #fff !important;
}

html, body {
  height: 100%;
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

main {
  flex: 1; 
}

.loaderGIf{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8); 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.footerABT h6 {
  color: #7d9fce !important;
}

.FooterINFO p {
  line-height: 45px;
}

.steeing_commiittee_a {
  text-decoration: none;
  color: #093e88;
}

.steering_comittee_container {
  border-top: 1px solid #ffca08;
}

.steering_comittee_container_right {
  border-left: 1px solid #ffca08;
}

.border-left-class {
  border-left: 3px solid #ffca08;
    padding-left: 15px;
}

/* --About Top Area-- */
.about_top_boxed {
  position: relative;
  border-radius: 20px;
  padding: 40px 30px 20px 30px;
  transition: var(--transition);
}

/* .about_top_boxed:hover {
  transform: translateY(10px);
} */

.bg_one {
  background: #cae4f7;
}

.bg_two {
  background: #fdf0dd;
}

.bg_three {
  background: #d2f4f4;
}

.bg_four {
  background: #e0eafd;
}

.about_top_boxed_icon {
  text-align: center;
  margin: 0px auto 0 auto;
  margin-top: -45px;
  transition: var(--transition);
}

/* .about_top_boxed:hover .about_top_boxed_icon {
  transform: translateY(20px);
} */

.about_top_boxed_icon img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 0px -4px 16px rgb(0 0 0 / 6%), 4px 8px 16px rgb(0 0 0 / 10%);
  margin-top: -45px;
}

.about_top_boxed_text {
  padding-top: 20px;
}

.about_top_boxed_text h3 {
  font-size: 20px !important;
  font-weight: 500;
  padding: 7px 0;
  text-align: center;
}

.about_top_boxed_text a {
  font-size: 16px;
  font-weight: 500;
}

.about_top_boxed_vector {
  position: absolute;
  bottom: 15px;
  right: 20px;
}

.about_area_main_text {
  /* padding-top: 54px; */
}

.about_area_heading {
  position: relative;
  display: flex;
  align-items: center;
  left: -119px;
}

.about_area_heading h3 {
  color: var(--paragraph-color);
  font-weight: 400;
}

.about_area_heading_two h2 {
  line-height: 50px;
  font-weight: 500;
}

.color_big_heading {
  position: relative;
  z-index: 99;
}

.color_big_heading::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 20px;
  background: #fdc221;
  bottom: 4px;
  left: 0;
  z-index: -1;
}

.about_area_heading_two h3 {
  font-weight: 500;
  color: var(--main-color);
  padding-top: 15px;
}


.about_area_para h5 {
  line-height: 26px;
}

.about_area_para h3 {
  font-size: 20px !important;
}

.about_area_para h4 {
  font-size: 20px !important;
  margin-top:20px;
}

.about_area_para p {
  font-size: 17px !important;
  padding-top: 5px;
  line-height: 26px;
  margin-bottom: 5px;
}

.about_area_para ul li {
  color: #000;
  font-size: 17px !important;
}

.about_area_para ul {
  margin-top: 15px;
}

.about_vedio_area {
  padding-top: 15px;
}

.vedio_btn {
  margin-left: 35px;
  font-size: 18px;
  font-weight: 600;
  color: var(--black-color);
}

.vedio_btn i {
  height: 50px;
  width: 50px;
  border: 1px solid;
  text-align: center;
  line-height: 46px;
  border-radius: 50%;
  font-size: 24px;
  margin-right: 14px;
  color: var(--main-color);
}

.about_front_area{
  background: #f6f5f0;
}

.about_area_img img {
  border-radius: 4px;
}

.read_abt_btn {
  border: 1px solid #000;
  text-decoration: none;
  padding: 10px 15px;
  color: #000;
  border-radius: 3px;
  display: inline-block;
  transition: transform 0.3sease;
}
.read_abt_btn:hover {
  color: #fff;
  background: #000;
  transform: translateY(-5px);
  border: 1px solid #000;
}  

.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.marquee-content {
  display: flex;
  gap: 40px; /* Space between images */
  animation: marqueeScroll 20s linear infinite;
}

.marquee-item {
  display: inline-block;
}

.marquee-image {
  width: 100px; /* Adjust size */
  height: auto;
  object-fit: contain;
}

/* Keyframes for infinite scrolling */
@keyframes marqueeScroll {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.newsFrontTitle {
  font-weight: 100 !important;
  font-size: 1.5em !important;
}

.newsFeaturedTitle{
  font-weight: 100 !important;
  font-size: 28px !important;
}




/* Floating Accessibility Menu */
.accessibility-menu {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  /* background: white; */
  padding: 10px;
  border-radius: 10px 0 0 10px;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

/* Opened Menu */
/* .accessibility-menu.open {
  transform: translateY(-50%) translateX(-150px); 
} */

.accessibility-menu.open { 
  background-color: #fff;
}

/* Floating Toggle Button */
.toggle-button {
  background: #007bff;
  color: white;
  border: none;
  /* padding: 10px; */
  width: 45px;
  height: 45px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  transition: background 0.3s;
}

.toggle-button:hover {
  background: #0056b3;
}

/* Toolbar Content */
.toolbar-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

/* Buttons */
.toolbar-content button {
  margin: 5px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 16px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  transition: 0.3s;
}

.toolbar-content button:hover {
  background-color: #0056b3;
}

/* High Contrast Mode */
.high-contrast {
  background-color: black !important;
  color: yellow !important;
}




.high-contrast h2 {
  color: yellow !important;
}

.high-contrast #about_top_area  {
  background-color: black !important; /* Ensure it overrides the default */
  color: yellow !important;
}

.high-contrast .NewsFrontSection  {
  background-color: black !important; /* Ensure it overrides the default */
  color: yellow !important;
}

.high-contrast .read_abt_btn  {
  background-color: yellow !important; /* Ensure it overrides the default */
  /* color: yellow !important; */
}

.high-contrast .bannerTITLE{
  background-color: black !important; /* Ensure it overrides the default */
  color: yellow !important;
}

.high-contrast .bannerSUBTITLE{
  background-color: black !important; /* Ensure it overrides the default */
  color: yellow !important;
}

.high-contrast .NewsContrast{
  background-color: black !important; /* Ensure it overrides the default */
  color: yellow !important;
}


.high-contrast .tgmenu__navbar-wrap > ul > li a{
  color: yellow !important;
}


.high-contrast .VacancyFront1{
  background-color: black !important;
  color: yellow !important;
}

.high-contrast .sticky-menu{
  background-color: black !important;
  color: yellow !important;
}

.high-contrast .FooterINFO p{
color: yellow !important;
}

.high-contrast .footerABT a{
  color: yellow !important;
  }